import React, { useContext } from 'react'

import vector6 from "../images/Vector6.png"; 
import carbontime from "../images/carbon_time.png";
import desquoContext from '../../../picontext/DashBoard/desquoContext';
import { formateDate ,timeFormat12hr} from '../../../piservices/compCommon';
import { getDateString } from '../Analytics/Analytic'; 
import { useDispatch, useSelector } from 'react-redux';
import {Tooltip} from 'antd'
import LockBlack from '../../../Assets/LockBlack.svg'
import { setFollowUpDate } from '../../../Redux/Slices/lmsSlice';
const DateTime = () => {
    let dateobj = getDateString(new Date());
    const context = useContext(desquoContext);
    const dispatch = useDispatch()
    const { isnewlead, dateTime, setDateTime, getActive } = context;
    const userData = useSelector((state) => state.lmsSlice.userData);
    return (
        <div>
            <p className={` ${isnewlead ? " fw-bold" : " cl-grey1 mb-2"}`}>To follow up on   {!userData?.rolesAssigned?.quotation?.edit && <Tooltip title="Access Disabled by the Admin">
                  <span><img src={LockBlack} alt="LockImg"></img></span>
                </Tooltip>}</p>
            <div className="d-flex justify-content-between">
                <div className='w-50 px-2'>
                    <div type="button" className={`br-5 btn-light  border px-3 px-md-2 ${isnewlead ? "py-2 cl-grey1" : "py-2 py-md-1 cl-black"}   position-relative`} data-mdb-ripple-color="dark">
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="me-4 pfs-12 mv-pfs-14 text-nowrap">{(dateTime[getActive] && dateTime[getActive]?.updateDate) || "Select A Date"}</span>
                            <img src={vector6} alt="" width={14}
                                height={14} className="cursor-pointer" />
                        </div>
                        <input disabled={!userData?.rolesAssigned?.crm?.edit} type="date" id="birthday" min={dateobj} name="birthday" className='position-absolute form-control top-0 end-0 w-100 px-2 h-100 opacity-0' value={(dateTime[getActive] && dateTime[getActive]?.updateDate) || ""} onChange={(e) => {
                            setDateTime({ ...dateTime, [getActive]: { ...dateTime[getActive], updateDate: formateDate(e.target.value) } });
                            dispatch(setFollowUpDate({ ...dateTime, [getActive]: { ...dateTime[getActive], updateDate: formateDate(e.target.value) } }));
                        }} />
                    </div>
                </div>
                <div className='w-50 px-2'>
                    <div type="button" className={` br-5 btn-light border px-3 px-md-2 ${isnewlead ? "py-2 cl-grey1" : "py-2 py-md-1 cl-black"}   position-relative`} data-mdb-ripple-color="dark">
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="me-4 pfs-12 mv-pfs-14 text-nowrap">{(dateTime[getActive] && dateTime[getActive]?.updateTime) || "Select A Time"}</span>
                            <img src={carbontime} alt="" width={14}
                                height={14} className="cursor-pointer" />
                        </div>
                        <input type="time" id="birthday"  disabled={!userData?.rolesAssigned?.crm?.edit} name="birthday" className='position-absolute form-control top-0 end-0 w-100 px-2 h-100 opacity-0' value={(dateTime[getActive] && dateTime[getActive]?.updateTime) || ""} onChange={(e) => {
                            setDateTime({
                                ...dateTime, [getActive]: { ...dateTime[getActive], updateTime:  timeFormat12hr(e.target.value)  }
                            });
                            dispatch(setFollowUpDate({
                                ...dateTime, [getActive]: { ...dateTime[getActive], updateTime: timeFormat12hr(e.target.value) }
                            }));
                            // setDateTime({...dateTime ,});
                        }} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DateTime