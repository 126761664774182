import React, { useState } from 'react'
import DropdownComponent from '../DropdownComponent'
import styles from '../../styles/additionalInfo.module.css'
import data from "./Data";
import InputBoxComponent from '../InputBoxComponent';
import { useDispatch, useSelector } from 'react-redux'
import { setBalcony } from '../../../../../Redux/Slices/additionalDetailsSlice';
function Balcony() {
    const dispatch = useDispatch();
    const balcony = useSelector(state => state.additionalInfoSlice.balcony);

    const handleChange = (type, value) => {
        dispatch(setBalcony({ ...balcony, [type]: value }));
    };
    return (
        <>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Janitor Unit `}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={balcony}
                        type="janitorUnit"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Storage Unit `}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={balcony}
                        type="storageUnit"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Clothes Drying Wire `}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={balcony}
                        type="clothesDryingWire"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                >
                    <div
                        className={styles.filter_icon}

                    >{`Green wall`}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={balcony}
                        type="greenWall"
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Furniture`}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={balcony}
                        type="furniture"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Additional Info `}</div>
                    <InputBoxComponent type='additionalInfo' handleChange={handleChange} placeholder={"Any additional info"} />
                </div>
            </div>
        </>
    )
}

export default Balcony