import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  otpEmail: "",
  userType: "",
  isSuperAdmin: false,
  companyId: "",
  logoImgData:""
};

export const persistSlice = createSlice({
  name: "persistSlice",
  initialState,
  reducers: {
    saveOtpMail: (state, action) => {
      state.otpEmail = action.payload;
    },

    saveUserType: (state, action) => {
      state.userType = action.payload;
    },

    saveIsSuperAdmin: (state, action) => {
      state.isClient = action.payload;
    },

    saveCompanyId: (state, action) => {
      state.companyId = action.payload;
    },
    logoImg: (state, action) => {
      state.logoImgData = action.payload;
    },
  },
});

export const { saveOtpMail,logoImg, saveUserType, saveIsSuperAdmin, saveCompanyId } = persistSlice.actions;

export default persistSlice.reducer;
