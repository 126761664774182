import React, { useState } from 'react'
import DropdownComponent from '../DropdownComponent'
import styles from '../../styles/additionalInfo.module.css'
import InputBoxComponent from '../InputBoxComponent';
import data from "./Data";

import { useDispatch, useSelector } from 'react-redux'
import { setinformation } from '../../../../../Redux/Slices/additionalDetailsSlice';
function Information() {
    // const [information, setInformation] = useState({
    //     designerIntroduction: '',   // 0-no , 1-yes, 2-to be checked/informed, 3-others
    //     teamDecorations: '',       // 0-no , 1-yes, 2-to be checked/informed, 3-others
    //     companyUSP: '',           // 0-no , 1-yes, 2-to be checked/informed, 3-others
    //     projectTimeline: '',     // 0-no , 1-yes, 2-to be checked/informed, 3-others
    //     paymentProcess: '',     // 0-no , 1-yes, 2-to be checked/informed, 3-others
    //     warrantyClause: '',    // 0-no , 1-yes, 2-to be checked/informed, 3-others
    //     postSaleService: '',
    // },)
    const dispatch = useDispatch();
    const information = useSelector(state => state.additionalInfoSlice.information);

    const handleChange = (type, value) => {
        dispatch(setinformation({ ...information, [type]: value }));
    };
    return (
        <>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Designer Introduction `}</div>
                    <DropdownComponent
                        elementArr={data.INFORMATION.DROPDOWN_OPTIONS}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={information}
                        type="designerIntroduction"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Team Introduction `}</div>
                    <DropdownComponent
                        elementArr={data.INFORMATION.DROPDOWN_OPTIONS}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={information}
                        type="teamIntroduction"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Company USP `}</div>
                    <DropdownComponent
                        elementArr={data.INFORMATION.DROPDOWN_OPTIONS}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={information}
                        type="companyUSP"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                >
                    <div
                        className={styles.filter_icon}

                    >{`Project Timeline `}</div>
                    <DropdownComponent
                        elementArr={data.INFORMATION.DROPDOWN_OPTIONS}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={information}
                        type="projectTimeline"
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Payment Process `}</div>
                    <DropdownComponent
                        elementArr={data.INFORMATION.DROPDOWN_OPTIONS}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={information}
                        type="paymentProcess"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Warranty Clause `}</div>
                    <DropdownComponent
                        elementArr={data.INFORMATION.DROPDOWN_OPTIONS}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={information}
                        type="warrantyClause"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Post Sale Service `}</div>
                    <DropdownComponent
                        elementArr={data.INFORMATION.DROPDOWN_OPTIONS}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={information}
                        type="postSaleService"
                        handleChange={handleChange}
                    />
                </div>

            </div>
        </>
    )
}

export default Information