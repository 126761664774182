import React from 'react'
import styles from '../styles/additionalInfo.module.css'
import vector6 from "../../../Lms/images/Vector6.png";

function DateInputBox({ handleChange, selectedElement, placeholder, inputType, type }) {
    console.log('selectedElement', selectedElement)
    return (
        <>
            {/* <div><input placeholder={placeholder} value={''} type='datetime-local' className={styles.inputBoxStyle}/></div> */}
            <div type="button" className={`${styles.inputBoxStyle} br-5 btn-light  border px-3 px-md-2 position-relative`} data-mdb-ripple-color="dark">
                <div className="d-flex align-items-center justify-content-between">
                    <span className="me-4 pfs-12 mv-pfs-14">{selectedElement && selectedElement[type] == '' || selectedElement[type] == undefined ? placeholder : selectedElement[type]}</span>
                    <img src={vector6} alt="" width={14}
                        height={14} className="cursor-pointer" />
                </div>
                <input type={inputType} id="birthday" name="birthday" className='position-absolute form-control top-0 end-0 w-100 px-2 h-100 opacity-0' value={""} onChange={(e) => handleChange(type, e.target.value)} />
            </div>
        </>
    )
}

export default DateInputBox