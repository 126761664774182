import React, { useState } from "react";
import { valueToLeadStatus } from "../../piservices/compCommon";
import desquoContext from "./desquoContext";

const DesQuoState = (props) => {
  const leadStatusItem = {
    new: {
      label: "New",
      value: 1,
      notvisible: true,
    },
    interested: {
      label: "Active",
      value: 3,
      templates: {
        0: {
          active: true,
        },
      },
      // isNewOnly: true,
      nomail: true,
      addEmail: true,
      activeBgColor: "#65CA57",
      activeColor: "#000000",
    },
    followUp: {
      label: "Follow Up",
      value: 2,
      templates: {
        0: {
          active: true,
        },
        1: {},
        2: {},
      },
      addEmail: true,
      activeBgColor: "#FDAF06",
      activeColor: "#000000",
    },
    not_interested: {
      label: "On Hold",
      value: 5,
      // active: true,
      templates: {
        0: {
          active: true,
        },
      },
      // isNewOnly: true,
      activeBgColor: "#F80032",
      activeColor: "#ffffff",
      // notcallmail : true
    },
    no_response: {
      label: "Re Activation",
      value: 6,
      templates: {
        0: {
          active: true,
        },
      },
      activeBgColor:
        "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.7) 112.55%)",
      activeColor: "#ffffff",
    },
    meeting_schedule: {
      label: "Meeting Scheduled",
      value: 7,
      templates: {
        0: {
          active: true,
        },
      },
      addEmail: true,
      notvisible: true,
      activeBgColor: "#B200C1",
      activeColor: "#000000",
    },
    in_active: {
      label: "Inactive",
      value: 10,
      templates: {
        0: {
          active: true,
        },
      },
      addEmail: true,
      notvisible: true,
      activeBgColor: "#B200C1",
      activeColor: "#000000",
    },
  };

  let conleadStatusItem = leadStatusItem;
  delete conleadStatusItem?.new;
  const [leadstatus, setLeadstatus] = useState(conleadStatusItem);
  const [paData, setPaData] = useState();
  const [mailStatus, setMailStatus] = useState();
  const [activeLead, setActiveLead] = useState({});


  const getActive =
    leadstatus &&
    Object.keys(leadstatus)?.filter((f) => leadstatus[f].active)?.length > 0 &&
    Object.keys(leadstatus)?.filter((f) => leadstatus[f].active === true)[0];

  const activeTem =
    leadstatus &&
    leadstatus[getActive] &&
    Object.keys(leadstatus[getActive]?.templates)?.filter(
      (f) => leadstatus[getActive]?.templates[f]?.active
    )[0];

  const isnewlead =
    activeLead?.leadStatus === 1 || activeLead?.leadStatus === 0 ? true : false;

  const [dateTime, setDateTime] = useState({});
  const [leads, setLeads] = useState();
  const [leads2, setLeads2] = useState();
  const [mailForm, setMailForm] = useState();
  const [myLeadStatus, setMyLeadStatus] = useState();

  async function setActiveLeadState(e) {
    let z = leadStatusItem;
    const callStatus =
      e?.leadUpdates &&
      e?.leadUpdates[e?.leadUpdates?.length - 1] &&
      e?.leadUpdates[e?.leadUpdates?.length - 1]?.callLeadStatus
        ? e?.leadUpdates[e?.leadUpdates?.length - 1]?.callLeadStatus
        : e?.leadStatus;
    if (e?.leadStatus !== 1) {
      let allactive =
        leadstatus &&
        Object.keys(leadstatus)?.filter((f) => leadstatus[f].active)?.length >
          0 &&
        Object.keys(leadstatus)?.filter((f) => leadstatus[f].active);

      if (getActive && activeLead?.idx !== e?.idx) {
        // z[getActive].active = false;
        for (let i = 0; i < allactive.length; i++) {
          const el = allactive[i];
          // console.log(el)
          z[el].active = false;
        }
      }
      if (
        e?.leadStatus &&
        (isnewlead ? true : !z[valueToLeadStatus[callStatus]].isNewOnly)
      ) {
        // alert(callStatus)
        z[valueToLeadStatus[callStatus]].active = true;
      }

      if (!isnewlead && z[valueToLeadStatus[callStatus]]?.isNewOnly) {
        z[valueToLeadStatus[2]].active = true;
      }
      var updates = e?.leadUpdates;

      let result =
        updates &&
        (await updates.reduce(function (r, a) {
          // r[a.callLeadStatus] = r[a.callLeadStatus] || [];
          if (z[valueToLeadStatus[a.callLeadStatus]]?.dateTimeReq) {
            r[valueToLeadStatus[a.callLeadStatus]] = a;
          }
          return r;
        }, Object.create(null)));
      setLeadstatus(z);
      if (result) {
        setDateTime(result);
      } else {
        setDateTime({});
      }
      setMyLeadStatus(valueToLeadStatus[e?.leadStatus]);
    } else {
      z[valueToLeadStatus[2]].active = true;
      setLeadstatus(z);
      setDateTime({});
      setMyLeadStatus(valueToLeadStatus[2]);
    }
    setActiveLead(e);
    setPaData();
    setMailStatus();
  }

  const showAlert = (
    text,
    btnText = "Yes",
    cancelBtn = false,
    myFunc = () => {},
    myFunc2 = () => {}
  ) => {
    setPaData({
      text: text,
      onClick: () => {
        myFunc();
        setTimeout(() => {
          setPaData();
        }, 200);
      },
      onCloseClick: () => {
        myFunc2();
        setTimeout(() => {
          setPaData();
        }, 200);
      },
      cancelBtn: cancelBtn,
      btnText: btnText,
    });
  };
  return (
    <desquoContext.Provider
      value={{
        leadstatus,
        setLeadstatus,
        paData,
        setPaData,
        mailStatus,
        setMailStatus,
        getActive,
        isnewlead,
        dateTime,
        setDateTime,
        leads,
        setLeads,
        leads2,
        setLeads2,
        activeLead,
        setActiveLeadState,
        activeTem,
        mailForm,
        setMailForm,
        leadStatusItem,
        myLeadStatus,
        setMyLeadStatus,
        showAlert,
      }}
    >
      {props.children}
    </desquoContext.Provider>
  );
};

export default DesQuoState;
