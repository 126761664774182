
const CLIENT_DETAILS = {
    PURPOSE: ['Personal Purpose(B2C)', 'Business Purpose(B2B)'],
    PROPERTY_CATEGORY_SUBCATEGORY: {
        'Residential-New': ['1 BHK', '2 BHK', '3 BHK', '4 BHK', 'Villa', 'Villament', 'Penthouse', 'Independent House'],
        'Residential-Renovation': ['1 BHK', '2 BHK', '3 BHK', '4 BHK', 'Villa', 'Villament', 'Penthouse', 'Independent House'],
        'Architectural': ['New Construction', 'Renovation'],
        'Commercial': ['New Construction', 'Renovation'],
        'Hospitality': ['New Construction', 'Renovation'],
    },
    PROJECT_TYPE: ['Design Only Service(DOS)', 'Turn Key Project'],
    POSSSESSION_STATUS: ['Possession ready', 'Possession in a month', 'Possession in 3-6 month', 'Possession in 6-12 month','Possession unclear',"Possession underConstruction"],
    BUDGET: ["2-5 lakh","5-10 lakh", '10-15 lakh', '15-20 lakh', 'Above 25 lakh']
}

const MEETING_DETAILS = {
    MEETING_ATTENDIES: ['Prartana', 'Dinesh'],
    MEETING_TYPE: ['Physical Meeting', 'Online Meeting'],
    MEETING_PLACE: ['Clients Place', 'WeDeszine Office', 'Nearby Cafe', 'Google Meets', 'Others'],
}
const OTHER_DETAILS = {
    WHATSAPP_GROUP_CREATED: ['Yes', 'No'],
    SITE_VISITED: ['Yes', 'No'],
    STUDIO_VISITED: ['Yes', 'No'],
    FLOOR_PLAN_RECEIVED: ['Yes', 'No'],
}
const CLIENT_AND_PROPERTY_DETAILS = {
    TYPE_OF_PROPERTY: ['4BHK Apartment', 'KWS', 'Villa', 'Independent House', 'Penthouse', 'Renovation', 'Commercial', 'Terrace', 'Balcony', 'Terrace + Balcony', "Backyard",
        "Terrace + Backyard",
        "Premium Landscape",
        "AB+WD",
        "TBC"],
    BUSINESS_TYPE: ['Business1', 'Business2', 'Business3'],
    CITIES: ["Bangalore Urban", "Hubballi-Dharwad", "Mysuru", "Kalaburagi", "Mangaluru", "Belagavi", "Davangere", "Ballari", "Vijayapura", "Shimoga", "Tumakuru", "Raichur", "Bidar", "Hospet", "Gadag-Betageri", "Robertsonpet", "Hassan", "Bhadravati", "Chitradurga", "Udupi", "Kolar", "Mandya", "Chikmagalur", "Gangavati", "Bagalkot", "Ranebennuru"],
    PROPERTY_ORIENTATION: ["North", "South", "East", "West"],
    CLIENT_PROFESSION: ['TBC', 'Others'],
    LEAD_SOURCES: ["Lead Agency", "Athiq Referral", "Jitu Referral", "Cx Referral", "Shivmoga Cx", "Employee Referral", "TLs Referral", "WD Social Media"],
}
const CLIENT_FAMILY_INFO = {
    RELIGION: ["Hindu", "Muslim", "Christian", "Jain"],
    REGION_OF_ORIGIN: ["North Indian", "South Indian", "Anglo Indian", "Parsi"],
    TYPE_OF_FAMILY: ["Join family", "Nuclear family", "Single parent family", "Visiting parent family", "Childless family"],
    NUMBER_OF_FAMILY_MEMBER: [1, 2, 3, 4, 5, 6, 7, 8, 9, "10", "10>"],
    FAMILY_CONSIST_OF: ["HW - 1G & 1B", "HW - 2G & 1B", "HW - 2B & 1G", "HW - 1G & 1B & Parents", "HW - 2G & 1B & Parents", "HW - 2B & 1G & Parents", "HW - 1G", "HW - 2G", "HW - 3G", "HW - 1B", "HW - 2B", "HW - 3B", "HW - 1G & Parents", "HW - 2G & Parents", "HW - 3G & Parents", "HW - 1B & Parents", "HW - 2B & Parents", "HW - 3B & Parents"],
    BELIEVE_IN_VASTU: ['Basic vastu', 'very much'],
    TRADITIONAL_AND_SPIRITUAL: ['Partially'],
}
const INFORMATION = {
    DROPDOWN_OPTIONS: ["Yes", "No", "Others", "To be checked/informed"]
}
const FOYER = {
    INSIDE_SHOE_RACK: ["Yes", "No", "Others", "To be checked/informed"],
    OUTSIDE_SHOE_RACK: ["Yes", "No", "Others", "To be checked/informed"],
    SHOE_RACK_CUM_SEATING: ["Yes", "No", "Others", "To be checked/informed"],
    WALL_TREATMENT: ["Yes", "No", "Others", "To be checked/informed"],
    FALSE_CEILING: ["Yes", "No", "Others", "To be checked/informed"],
    UPS_STORAGE: ["Yes", "No", "Others", "To be checked/informed"],
    STORAGE_UNIT: ["Yes", "No", "Others", "To be checked/informed"],
    ADDITIONAL_INFO: ["Yes", "No", "Others", "To be checked/informed"]
}

const LIVING = {
    TV_UNIT: ["Yes", "No", "Others", "To be checked/informed"],
    TV_UNIT_BACK_WALL_TREATMENT: ["Yes", "No", "Others", "To be checked/informed"],
    LTYPE_SOFA: ["Yes", "No", "Others", "To be checked/informed"],
    SPLIT_SOFA: ["Yes", "No", "Others", "To be checked/informed"],
    ACCENT_CHAIR: ["Yes", "No", "Others", "To be checked/informed"],
    CENTER_TABLE: ["Yes", "No", "Others", "To be checked/informed"],
    SIDE_TABLE: ["Yes", "No", "Others", "To be checked/informed"],
    SOFA_BLACK_WALL: ["Yes", "No", "Others", "To be checked/informed"],
    FALSE_CEILING: ["Yes", "No", "Others", "To be checked/informed"],
}
const PROJECT_INFO = {
    TENTATIVE_BUDGET: [
        "<50K", "50-1L", "1L-5L", "5L-10L", "10L-15L", "15L-20L", "20L-25L", "25L-30L", "30L-35L",
        "35L-40L", "40L-45L", "45L-50L", "50L>", "Not Disclosed", "Flexible in budget"
    ],
    FALSE_CEILING: ["Full house", "Full house minimal false ceiling", "Only common area", "Common area & MBR", "Just border false ceiling", "No", "TBC", "Others"],
    SCOPE: ["Full house designing", "KWS", "Loose furniture", "Renovation", "DOS", "Others"],
    STYLE_OF_DESIGN: ["Transitional Interior Design", "Scandinavian Interior Design", "Modern Interior Design", "Contemporary Interior Design", "Minimalist Interior Design", "Mid Century Modern Interior Design", "Bohemian Interior Design", "Rustic Interior Design", "Modern Industrial Interior Design", "Others"],
}
const COMMON_ARRAY = ["Yes", "No", "Others", "To be checked/informed"]
export default { LIVING, COMMON_ARRAY, FOYER, INFORMATION, CLIENT_DETAILS, MEETING_DETAILS, OTHER_DETAILS, CLIENT_AND_PROPERTY_DETAILS, CLIENT_FAMILY_INFO, PROJECT_INFO }