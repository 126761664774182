import React, { useState } from 'react'
import DropdownComponent from '../DropdownComponent'
import styles from '../../styles/additionalInfo.module.css'
import data from "./Data";

import { useDispatch, useSelector } from 'react-redux'
import { setfoyer } from '../../../../../Redux/Slices/additionalDetailsSlice';
import InputBoxComponent from '../InputBoxComponent';
function Foyer() {
    // const [foyer, setFoyer] = useState({
    //     insideShoeRack: '',
    //     outsideShoeRack: '',
    //     shoeRackcumSeating: '',
    //     wallTreatment: '',
    //     falseCeiling: '',
    //     UPSStorage: '',
    //     storageUnit: '',
    //     additionalInfo: '',
    // })
    const dispatch = useDispatch();
    const foyer = useSelector(state => state.additionalInfoSlice.foyer);

    const handleChange = (type, value) => {
        dispatch(setfoyer({ ...foyer, [type]: value }));
    };
    return (
        <>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Inside Shoe Rack `}</div>
                    <DropdownComponent
                        elementArr={data.FOYER.INSIDE_SHOE_RACK}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={foyer}
                        type="insideShoeRack"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Outside Shoe Rack `}</div>
                    <DropdownComponent
                        elementArr={data.FOYER.OUTSIDE_SHOE_RACK}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={foyer}
                        type="outsideShoeRack"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Shoe Rack cum Seating `}</div>
                    <DropdownComponent
                        elementArr={data.FOYER.SHOE_RACK_CUM_SEATING}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={foyer}
                        type="shoeRackcumSeating"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                >
                    <div
                        className={styles.filter_icon}

                    >{`Wall Treatment `}</div>
                    <DropdownComponent
                        elementArr={data.FOYER.WALL_TREATMENT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={foyer}
                        type="wallTreatment"
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`False Ceiling `}</div>
                    <DropdownComponent
                        elementArr={data.FOYER.FALSE_CEILING}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={foyer}
                        type="falseCeiling"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`UPS Storage `}</div>
                    <DropdownComponent
                        elementArr={data.FOYER.UPS_STORAGE}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={foyer}
                        type="UPSStorage"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Storage Unit `}</div>
                    <DropdownComponent
                        elementArr={data.FOYER.STORAGE_UNIT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={foyer}
                        type="storageUnit"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Additional info `}</div>
                  <InputBoxComponent selectedElement={foyer} type='additionalInfo' handleChange={handleChange} placeholder={"Any additional info"} />
                </div>

            </div>
        </>
    )
}

export default Foyer