import React, { useState, useEffect, useContext } from "react";
import styles from "./NewLayout.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { BiChevronDown } from "react-icons/bi";
import {
  baseUrl,
  formateDate,
  valueToLeadStatusFormatted,
  valueToCallRemarks,
} from "../../../piservices/compCommon";
import desquoContext from "../../../picontext/DashBoard/desquoContext";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { RxCross2 } from "react-icons/rx";
import { DateRangePicker } from "react-date-range";
import data from "../../Lms/LeadManagements/components/subcomponents/Data";
function DropdownComponent({
  elementArr,
  selectedElement,
  type,
  clearFilterOptions,
  handleChange,
}) {
  return (
    <Dropdown>
      <Dropdown.Toggle
        className={styles.dropdownButton}
        variant="outline-secondary"
        id="dropdown-basic"
      >
        <span className={styles.buttonText}>
          {selectedElement?.length == 0 ? "Select" : "Selected"}
        </span>{" "}
        {selectedElement?.length == 0 ? (
          <BiChevronDown size={20} color="#74737F" />
        ) : (
          <RxCross2
            size={15}
            style={{ cursor: "pointer" }}
            color="#74737F"
            onClick={() => clearFilterOptions(type)}
          />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.filterDropdownContainer}>
        <Dropdown.ItemText className="d-flex">
          <input
            type="checkbox"
            checked={selectedElement.includes("Select All")}
            onChange={(e) => {
              handleChange(e, "Select All", type);
              //  setActive(e.target.checked);
            }}
          />
          <div className={styles.filterDropdownText}>Select All</div>
        </Dropdown.ItemText>
        {elementArr?.map((t) => {
          return (
            <Dropdown.ItemText className="d-flex">
              <input
                type="checkbox"
                checked={selectedElement.includes(t)}
                onChange={(e) => {
                  handleChange(e, t, type);
                  //  setActive(e.target.checked);
                }}
              />
              <div className={styles.filterDropdownText}>{t}</div>
            </Dropdown.ItemText>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function Filter() {
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState([]);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
  const [selectedExpectedClosure, setSelectedExpectedClosure] = useState([]);
  const context = useContext(desquoContext);
  const [searchL, setSearchL] = useState("");
  const { leads, leads2, setLeads, activeLead } = context;
  const [checkboxData, setCheckBoxData] = useState([]);
  const [finalSelectedBudget, setFinalSelectedBudget] = useState([]);
  const [startDateCreatedOn, setStartDateCreatedOn] = useState(new Date());
  const [endDateCreatedOn, setEndDateCreatedOn] = useState(new Date());
  const [startDateFollowUp, setStartDateFollowUp] = useState(new Date());
  const [endDateFollowUp, setEndDateFollowUp] = useState(new Date());
  const [selectedCretedOn, setSelectedCreatedOn] = useState([]);
  const [selectedfollowUp, setSelectedFollowUp] = useState([]);
  const [selectedLeadStage, setSelectedLeadStage] = useState([]);
  const monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = {
    Jan: "Jan",
    Feb: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    Aug: "Aug",
    Sept: "Sep",
    Oct: "Oct",
    Nov: "Nov",
    Dec: "Dec",
  };
  useEffect(() => {
    setCheckBoxData(leads2?.data);
  });
  console.log('selectedBudget', selectedBudget)
  function clearFilterOptions(type) {
    if (type == "budget") {
      setSelectedBudget([]);
    } else if (type == "area") {
      setSelectedArea([]);
    } else if (type == "status") {
      setSelectedStatus([]);
    } else if (type == "expectedClosure") {
      setSelectedExpectedClosure([]);
    } else if (type == "assignedTo") {
      setSelectedAssignedTo([]);
    } else if (type == "source") {
      setSelectedSource([]);
    } else if (type == "createdOn") {
      setSelectedCreatedOn([]);
    } else if (type == "followUp") {
      setSelectedFollowUp([]);
    }
    else if (type == "stage") {
      setSelectedLeadStage([]);
    }
  }



  const statusArr = checkboxData
    ?.map((v) => v.leadStatus)
    ?.filter((item, index, arr) => arr?.indexOf(item) === index)
    ?.filter(
      (element) => element !== null && element !== undefined && element !== "-"
    );
console.log('statusArr', statusArr)
  // const budgetArr = [
  //   "Under 1 Lakh",
  //   "1 Lakh - 5 Lakh",
  //   "5 Lakh - 10 Lakh",
  //   "Over 10 Lakh",
  // ];
  

  const budgetArrAll = checkboxData
    ?.map((v) => v.budget)
    ?.filter((item, index, arr) => arr?.indexOf(item) === index)
    ?.map((item, index) => convertBudgetToInt(item))
    ?.filter(
      (element) => element !== null && element !== undefined && element !== "-"
    );

  const Under1Lakh = budgetArrAll?.filter((item) => item < 100000);
  const Between1LakhTo5Lakh = [budgetArrAll]?.filter(
    (item) => item > 100000 && item < 500000
  );
  const Between5LakhTo10Lakh = budgetArrAll?.filter(
    (item) => item > 500000 && item < 1000000
  );
  const Above10Lakh = budgetArrAll?.filter((item) => item > 1000000);

  // const AreaArr = checkboxData
  //   ?.map((v) => v.address)
  //   ?.filter((item, index, arr) => arr?.indexOf(item) === index)
  //   ?.sort((a, b) => a?.localeCompare(b))
  //   ?.filter(
  //     (element) => element !== null && element !== undefined && element !== "-"
  //   );
const AreaArr = ["Bangalore","Shimoga","Others"]

  const AssignedToArr = checkboxData
    ?.map((v) => v.assignedTo?.fullName)
    ?.filter((item, index, arr) => arr?.indexOf(item) === index)
    ?.sort((a, b) => a?.localeCompare(b))
    ?.filter(
      (element) => element !== null && element !== undefined && element !== "-"
    );
    const stageArr = checkboxData
    ?.map((v) => v.leadStage)
    ?.filter((item, index, arr) => arr?.indexOf(item) === index)
    ?.sort((a, b) => a?.localeCompare(b))
    ?.filter(
      (element) => element !== null && element !== undefined && element !== "-"
    );

    console.log(stageArr,'leadStagearr')

  const createdOnArr = checkboxData
    ?.map((v) => v.createdAt)
    ?.filter((item, index, arr) => arr?.indexOf(item) === index);
  const followUpArr = checkboxData
    ?.map((v) => v?.leadUpdates[v?.leadUpdates?.length - 1]?.updateDate)
    ?.filter((item, index, arr) => arr?.indexOf(item) === index)
    ?.filter(
      (element) => element !== null && element !== undefined && element !== "-"
    );

  const SourceArr = checkboxData
    ?.map((v) => v.leadSource)
    ?.filter((item, index, arr) => arr?.indexOf(item) === index)
    ?.sort((a, b) => a?.localeCompare(b))
    ?.filter(
      (element) => element !== null && element !== undefined && element !== "-"
    );

  function convertBudgetToInt(bud) {
    const newBudget = bud?.replaceAll(",", "");
    return Number(newBudget);
  }
  function handleChange(e, t, type) {
    if (type == "area") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedArea([...AreaArr, 'Select All']);
        } else {
          setSelectedArea([...selectedArea, t]);
        }
      } else {
        if (t == "Select All") {
          setSelectedArea([]);
        } else {
          setSelectedArea(
            selectedArea.filter((e, i) => selectedArea.indexOf(t) !== i)
          );
        }

      }
    }
    if (type == "status") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedStatus([...statusArr, 'Select All']);
        } else {
          setSelectedStatus([...selectedStatus, t]);
        }
      } else {

        if (t == "Select All") {
          setSelectedStatus([]);
        } else {
          setSelectedStatus(
            selectedStatus.filter((e, i) => selectedStatus.indexOf(t) !== i)
          );
        }
      }
    }
    if (type == "stage") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedLeadStage([...stageArr, 'Select All']);
        } else {
          setSelectedLeadStage([...selectedLeadStage, t]);
        }
      } else {

        if (t == "Select All") {
          setSelectedLeadStage([]);
        } else {
          setSelectedLeadStage(
            selectedLeadStage.filter((e, i) => selectedLeadStage.indexOf(t) !== i)
          );
        }
      }
    }
    if (type == "expectedClosure") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedExpectedClosure([...monthNames, 'Select All']);
        } else {
          setSelectedExpectedClosure([...selectedExpectedClosure, t]);
        }
      } else {
        if (t == "Select All") {
          setSelectedExpectedClosure([]);
        } else {
          setSelectedExpectedClosure(
            selectedExpectedClosure.filter((e, i) => selectedExpectedClosure.indexOf(t) !== i)
          );
        }
 
      }
    }
    if (type == "budget") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedBudget([...data?.CLIENT_DETAILS?.BUDGET, 'Select All']);
        } else {
          setSelectedBudget([...selectedBudget, t]);
        }
        // setSelectedBudget([...selectedBudget, t]);
      } else {
        if (t == "Select All") {
          setSelectedBudget([]);
        } else {
          setSelectedBudget(
            selectedBudget.filter((e, i) => selectedBudget.indexOf(t) !== i)
          );
        }
        // setSelectedBudget(
        //   selectedBudget.filter((e, i) => selectedBudget.indexOf(t) !== i)
        // );
      }
    }
    if (type == "assignedTo") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedAssignedTo([...AssignedToArr, 'Select All']);
        } else {
          setSelectedAssignedTo([...selectedAssignedTo, t]);
        }
        // setSelectedAssignedTo([...selectedAssignedTo, t]);
      } else {
        if (t == "Select All") {
          setSelectedAssignedTo([]);
        } else {
          setSelectedAssignedTo(
            selectedAssignedTo.filter((e, i) => selectedAssignedTo.indexOf(t) !== i)
          );
        }
      }
    }
    if (type == "source") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedSource([...SourceArr, 'Select All']);
        } else {
          setSelectedSource([...selectedSource, t]);
        }
        // setSelectedSource([...selectedSource, t]);
      } else {
        if (t == "Select All") {
          setSelectedSource([]);
        } else {
          setSelectedSource(
            selectedSource.filter((e, i) => selectedSource.indexOf(t) !== i)
          );
        }
        // setSelectedSource(
        //   selectedSource.filter((e, i) => selectedSource.indexOf(t) !== i)
        // );
      }
    }
  }
  const filterBudget = () => {
    let temp = [];
    if (selectedBudget?.length > 0) {
      selectedBudget.map((item, index, arr) => {
        if (item === "Under 1 Lakh") {
          temp = [...temp, ...Under1Lakh];
          // setFinalSelectedBudget([...finalSelectedBudget, ...Under1Lakh])
        } else if (item === "1 Lakh - 5 Lakh") {
          temp = [...temp, ...Between1LakhTo5Lakh];
          // setFinalSelectedBudget([...finalSelectedBudget, ...Between1LakhTo5Lakh])
        } else if (item === "5 Lakh - 10 Lakh") {
          temp = [...temp, ...Between5LakhTo10Lakh];
          // setFinalSelectedBudget([...finalSelectedBudget, ...Between5LakhTo10Lakh])
        } else if (item === "Over 10 Lakh") {
          temp = [...temp, ...Above10Lakh];
          // setFinalSelectedBudget([...finalSelectedBudget, ...Above10Lakh])
        }
      });
      setFinalSelectedBudget([...temp]);
    }
  };
  // useEffect(() => {
  //   setFinalSelectedBudget([]);
  //   filterBudget();
  // }, [selectedBudget]);

  const isFilterOpen = useSelector((state) => state.lmsSlice.isFilterOpen);

  function applyFilter() {
    if (
      selectedStatus.length > 0 ||
      selectedfollowUp.length > 0 ||
      selectedCretedOn.length > 0 ||
      selectedBudget.length > 0 ||
      // finalSelectedBudget.length > 0 ||
      selectedSource.length > 0 ||
      selectedLeadStage.length > 0 ||
      selectedArea.length > 0 ||
      selectedAssignedTo.length > 0 ||
      selectedExpectedClosure.length > 0
    ) {
      const filterData = checkboxData.filter((item) => {
        return (
          selectedArea?.includes(item.address) ||
          selectedStatus?.includes(item.leadStatus) ||
          selectedExpectedClosure?.includes(item.clouserMonth) ||
          selectedBudget?.includes(item.budget) ||
          selectedLeadStage?.includes(item.leadStage) ||
          // finalSelectedBudget?.includes(convertBudgetToInt(item.budget)) ||
          selectedAssignedTo?.includes(item.assignedTo?.fullName) ||
          selectedSource?.includes(item.leadSource) ||
          selectedCretedOn?.includes(item.createdAt) ||
          selectedfollowUp?.includes(
            item.leadUpdates[item?.leadUpdates?.length - 1]?.updateDate
          )
        );
      });
      let x = JSON.parse(JSON.stringify(leads));
      delete x["data"];
      x["data"] = filterData;
      setLeads(x);
    } else {
      setLeads(leads2);
    }
  }

  function clearFilter() {
    setSelectedArea([]);
    setSelectedStatus([]);
    setSelectedExpectedClosure([]);
    setSelectedBudget([]);
    setSelectedAssignedTo([]);
    setSelectedSource([]);
    setSelectedCreatedOn([]);
    setSelectedFollowUp([]);
    setLeads(leads2);
  }

  function handleCancle() { }
  //handling filter for created on date
  function handleSelect(date) {
    setStartDateCreatedOn(date.selection.startDate);
    setEndDateCreatedOn(date.selection.endDate);
    const filteredCreatedOnDate = createdOnArr?.filter((createdOn) => {
      let createdDate = new Date(createdOn).toLocaleDateString();
      return (
        createdDate >= date.selection.startDate.toLocaleDateString() &&
        createdDate <= date.selection.endDate.toLocaleDateString()
      );
    });
    setSelectedCreatedOn([...filteredCreatedOnDate]);
  }
  // handling filter for follow up
  function handleSelectFollowUp(date) {
    setStartDateFollowUp(date.selection.startDate);
    setEndDateFollowUp(date.selection.endDate);
    const filteredFollowUpDate = followUpArr?.filter((fllUp) => {
      let followUpDate = new Date(fllUp).toLocaleDateString();
      return (
        followUpDate >= date.selection.startDate.toLocaleDateString() &&
        followUpDate <= date.selection.endDate.toLocaleDateString()
      );
    });
    setSelectedFollowUp([...filteredFollowUpDate]);
  }
  const selectionRange = {
    startDate: startDateCreatedOn,
    endDate: endDateCreatedOn,
    key: "selection",
  };
  const selectionRangeFollowUp = {
    startDate: startDateFollowUp,
    endDate: endDateFollowUp,
    key: "selection",
  };
  return (
    <div className={` ${styles.mainContainer4}`}>
      <div className="d-flex justify-content-between w-100">
        <div>
          <div className="d-flex align-items-center">
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >{`City (${selectedArea?.length})`}</div>
              <DropdownComponent
                clearFilterOptions={clearFilterOptions}
                elementArr={AreaArr}
                selectedElement={selectedArea}
                type="area"
                handleChange={handleChange}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >{`Status (${selectedStatus?.length})`}</div>
              <Dropdown>
                <Dropdown.Toggle
                  className={styles.dropdownButton}
                  variant="outline-secondary"
                  id="dropdown-basic"
                >
                  <span className={styles.buttonText}>
                    {selectedStatus?.length == 0 ? "Select" : "Selected"}
                  </span>{" "}
                  {selectedStatus?.length == 0 ? (
                    <BiChevronDown size={25} color="#74737F" />
                  ) : (
                    <RxCross2
                      size={15}
                      style={{ cursor: "pointer" }}
                      color="#74737F"
                      onClick={() => clearFilterOptions("status")}
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu className={styles.filterDropdownContainer}>
                  <Dropdown.ItemText className="d-flex">
                    <input
                      type="checkbox"
                      checked={selectedStatus.includes("Select All")}
                      onChange={(e) => {
                        handleChange(e, "Select All", "status");
                        //  setActive(e.target.checked);
                      }}
                    />
                    <div className={styles.filterDropdownText}>Select All</div>
                  </Dropdown.ItemText>
                  {statusArr?.map((t) => {
                    return (
                      <Dropdown.ItemText className="d-flex">
                        <input
                          type="checkbox"
                          checked={selectedStatus.includes(t)}
                          onChange={(e) => {
                            handleChange(e, t, "status");
                            //  setActive(e.target.checked);
                          }}
                        />
                        <div style={{ marginLeft: "0.6rem" }}>
                          {valueToLeadStatusFormatted[t]}
                        </div>
                      </Dropdown.ItemText>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >{`Expected Closure (${selectedExpectedClosure?.length})`}</div>
              <DropdownComponent
                elementArr={monthNames}
                clearFilterOptions={clearFilterOptions}
                selectedElement={selectedExpectedClosure}
                type="expectedClosure"
                handleChange={handleChange}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >{`Budget (${selectedBudget?.length})`}</div>
              <DropdownComponent
                elementArr={data?.CLIENT_DETAILS?.BUDGET}
                clearFilterOptions={clearFilterOptions}
                selectedElement={selectedBudget}
                type="budget"
                handleChange={handleChange}
              />
            </div>

            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >{`Assign To (${selectedAssignedTo?.length})`}</div>
              <DropdownComponent
                elementArr={AssignedToArr}
                clearFilterOptions={clearFilterOptions}
                selectedElement={selectedAssignedTo}
                type="assignedTo"
                handleChange={handleChange}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >{`Source (${selectedSource?.length})`}</div>
              <DropdownComponent
                elementArr={SourceArr}
                clearFilterOptions={clearFilterOptions}
                selectedElement={selectedSource}
                type="source"
                handleChange={handleChange}
              />
            </div>
          </div>
          <br />
          <div className="d-flex align-items-center">
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >
                Date
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  className={styles.dropdownButton}
                  variant="outline-secondary"
                  id="dropdown-basic"
                >
                  <span className={styles.buttonText}>
                    <span style={{ marginRight: "50px" }}>
                      {selectedCretedOn?.length == 0 ? "Select" : "Selected"}
                    </span>{" "}
                    <span>
                      {selectedCretedOn?.length == 0 ? (
                        <BiChevronDown size={25} color="#74737F" />
                      ) : (
                        <RxCross2
                          size={15}
                          style={{ cursor: "pointer" }}
                          color="#74737F"
                          onClick={() => clearFilterOptions("createdOn")}
                        />
                      )}
                    </span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >
                Follow Up
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  className={styles.dropdownButton}
                  variant="outline-secondary"
                  id="dropdown-basic"
                >
                  <span className={styles.buttonText}>
                    <span style={{ marginRight: "50px" }}>
                      {selectedfollowUp?.length == 0 ? "Select" : "Selected"}{" "}
                    </span>
                    {selectedfollowUp?.length == 0 ? (
                      <BiChevronDown size={25} color="#74737F" />
                    ) : (
                      <RxCross2
                        size={15}
                        style={{ cursor: "pointer" }}
                        color="#74737F"
                        onClick={() => clearFilterOptions("followUp")}
                      />
                    )}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DateRangePicker
                    ranges={[selectionRangeFollowUp]}
                    onChange={handleSelectFollowUp}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >{`Stage (${selectedAssignedTo?.length})`}</div>
              <DropdownComponent
                elementArr={stageArr}
                clearFilterOptions={clearFilterOptions}
                selectedElement={selectedLeadStage}
                type="stage"
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="d-flex mt-2">
          <div
            className={`${styles.clear} d-flex align-items-center px-1 ms-3`}
            style={{
              height: "2.185rem",
            }}
            onClick={clearFilter}
          >
            <div>Clear</div>
          </div>
          <div
            className="d-flex align-items-center m px-3 ms-3"
            style={{
              borderRadius: "0.5rem",
              border: "1px solid #0084EF",
              background: "#0084EF",
              height: "2.185rem",
              marginRight: "1rem",
            }}
          >
            <div
              className="mx-2"
              onClick={applyFilter}
              style={{
                fontSize: "0.875rem",
                color: "white",
                cursor: "pointer",
              }}
            >
              Apply
            </div>
          </div>
          <div>
            {/* <RxCross2
            size={25}
            style={{ cursor: "pointer" }}
            color="#74737F"
            onClick={() => handleCancle()}
          /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;
