import React from "react";
import {
  Document,
  StyleSheet,
  Image,
  Page,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import pdfTop from "../../../../Assets/pdfTop.jpg";
import BCChecklistFooterMoto from "../../../../Assets/BC_checklistFooterMoto.jpg";
import { useSelector } from "react-redux";
import Balcony from "./subcomponents/Balcony";
import { formateDate } from "../../../../piservices/compCommon";
function PDFComponentPage({
  data,
  kBR,
  balcony,
  meetingDetails,
  clientAndPropertyDetails,
  dining,
  gBR,
  foyer,
  information,
  kitchen,
  mBR,
  otherDetails,
  pBR,
  living,
  projectInfo,
  utility,
  clientFamilyInfo,
  clientDetails,
}) {
  console.log("datataa", data);
  const styles = StyleSheet.create({
    page: { flexDirection: "row", backgroundColor: "#E4E4E4", padding: 10 },
    // section: { margin: 10, padding: 10, flexGrow: 1 },
    image: { width: "100%", height: "auto" },
    table: { display: "table", width: "100%", padding: "8" },
    rowTop: {
      flexDirection: "row",
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderBottomColor: "#000",
      alignItems: "center",
      width: "100%",
    },
    row: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderBottomColor: "#000",
      alignItems: "center",
      width: "100%",
    },
    cell1: {
      width: "30%",
      padding: 4,
      borderRightWidth: 1,
      fontWeight: "300",
      fontSize: 12,
      color: "#888888",
    },
    cell2: {
      width: "70%",
      padding: 4,
      borderRightWidth: 1,
      fontWeight: "bold",
      fontSize: 12,
    },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    componentContainer: {
      display: "flex",
      flexDirection: "column",
      padding: 2,
      paddingLeft: 4,
      margin: 8,
      border: "1px solid #888888",
      borderRadius: "4px",
    },
    topBar: {
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "center",
      paddingTop: 2,
      marginTop: 4,
    },
    bottomContainer: {},
    containerContent: {
      display: "flex",
      flexDirection: "row",
      paddingTop: 13,
    },
    borderContainer: {
      border: "1px solid #888888",
      minHeight: "20px",
      width: "140px",
      padding: "4",
      borderRadius: "4px",
      marginTop: 2,
    },
    boxContainer: {
      marginRight: 4,
    },
    textFont: {
      fontSize: 8,
      color: "#888888",
      fontWeight: 400,
      lineHeight: "normal",
    },
    textFont1: {
      fontSize: 8,
      color: "#434343",
      fontWeight: 400,
      lineHeight: "normal",
    },
    pdfHeading: {
      color: "white",
      backgroundColor: "black",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      fontSize: 15,
      padding: "5px 0px 5px 50px",
    },
    footerMotoText: {
      fontSize: 15,
    },
    footerText: {
      color: "black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 8,
      textAlign: 'center',
      padding: "10px 10px 10px 10px",
    },
  });
  Font.register({
    family: "normal",
  });
  return (
    <Document>
      <Page size="A4" wrap={false}>
        <View>
          <Image
            style={{ width: "100%", height: "auto" }}
            src={pdfTop}
            alt="My Image"
          />
          <View style={styles.pdfHeading}>
            <Text>Briefing Call Checklist</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.rowTop}>
              <View style={styles.cell1}>
                <Text>Date</Text>
              </View>
              <View style={styles.cell2}>
                <Text>{formateDate(new Date())}</Text>
              </View>
            </View>
            <View style={styles.rowTop}>
              <View style={styles.cell1}>
                <Text>Name</Text>
              </View>

              <View style={styles.cell2}>
                <Text>{data.name}</Text>
              </View>
            </View>
            <View style={styles.rowTop}>
              <View style={styles.cell1}>
                <Text>Contact</Text>
              </View>
              <View style={styles.cell2}>
                <Text>{data.phoneNumber}</Text>
              </View>
            </View>
            <View style={styles.rowTop}>
              <View style={styles.cell1}>
                <Text>Email</Text>
              </View>
              <View style={styles.cell2}>
                <Text>{data.email}</Text>
              </View>
            </View>
            <View style={styles.rowTop}>
              <View style={styles.cell1}>
                <Text>Location</Text>
              </View>
              <View style={styles.cell2}>
                <Text>{`${data.city} ${data.pinCode} ${data.address}`}</Text>
              </View>
            </View>
            <View style={styles.rowTop}>
              <View style={styles.cell1}>
                <Text>Designer Name</Text>
              </View>
              <View style={styles.cell2}>
                <Text>{data.assignedTo.fullName}</Text>
              </View>
            </View>

            {/* <View style={styles.row}>
              <View style={styles.cell1}>
                <Text>MOM No.</Text>
              </View>
              <View style={styles.cell2}>
                <Text>Age</Text>
              </View>
            </View> */}
            {/* <View style={styles.row}>
              <View style={styles.cell1}>
                <Text>Client Name</Text>
              </View>
              <View style={styles.cell2}>
                <Text>{`${data?.firstName} ${data?.lastName}`}</Text>
              </View>
            </View> */}
            {/* <View style={styles.row}>
              <View style={styles.cell1}>
                <Text>Client Contact</Text>
              </View>
              <View style={styles.cell2}>
                <Text>{data?.phoneNumber}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.cell1}>
                <Text>Property Address</Text>
              </View>
              <View style={styles.cell2}>
                <Text>{clientAndPropertyDetails?.propertyAddress ? clientAndPropertyDetails?.propertyAddress : '-'}</Text>
              </View>
            </View> */}

            {/* <View style={styles.row}>
              <View style={styles.cell1}>
                <Text>Venue</Text>
              </View>
              <View style={styles.cell2}>
                <Text>Age</Text>
              </View>
            </View> */}
            {/* <View style={styles.row}>
              <View style={styles.cell1}>
                <Text>Project type</Text>
              </View>
              <View style={styles.cell2}>
                <Text>{clientAndPropertyDetails?.typeOfProperty?clientAndPropertyDetails?.typeOfProperty:'-'}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.cell1}>
                <Text>Scope</Text>
              </View>
              <View style={styles.cell2}>
                <Text>{projectInfo?.scope ? projectInfo?.scope : '-'}</Text>
              </View>
            </View> */}
          </View>
          {!Object.keys(clientDetails)?.every(
            (info) => clientDetails[info] === ""
          ) && (
              <View style={styles.componentContainer}>
                <View style={styles.topBar}>
                  <Text style={{ fontSize: 12 }}>Client Details</Text>
                </View>
                <View style={styles.bottomContainer}>
                  <View style={styles.containerContent}>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Purpose</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientDetails?.purpose}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Property Name</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientDetails?.propertyName}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Property category</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientDetails?.propertyCategory}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Property Sub Category </Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientDetails?.propertySubcategory}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.containerContent}>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Possesion Status</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientDetails?.possessionStatus}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Budget</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientDetails?.budget}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Brief Call </Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientDetails?.briefCall}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )}
          {!Object.keys(meetingDetails)?.every(
            (info) => meetingDetails[info] === ""
          ) && (
              <View style={styles.componentContainer}>
                <View style={styles.topBar}>
                  <Text style={{ fontSize: 12 }}>Meeting Details</Text>
                </View>
                <View style={styles.bottomContainer}>
                  <View style={styles.containerContent}>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Meeting Date</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {meetingDetails?.meetingDate}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Attendies</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {meetingDetails?.attendees}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Place</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {meetingDetails?.meetingPlace}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Meeting Type</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {meetingDetails?.meetingType}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.containerContent}>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Meeting Time</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {meetingDetails?.meetingTime}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )}
          {!Object.keys(otherDetails)?.every(
            (info) => otherDetails[info] === ""
          ) && (
              <View style={styles.componentContainer}>
                <View style={styles.topBar}>
                  <Text style={{ fontSize: 12 }}>Other Details</Text>
                </View>
                <View style={styles.bottomContainer}>
                  <View style={styles.containerContent}>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>
                        Whatsapp Group Created?
                      </Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {otherDetails?.isWhatsappGroupCreated}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Floor Plan Recieved?</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {otherDetails?.isFloorPlanReceived}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Site Visit Recieved?</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {otherDetails?.isSiteVisitReceived}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Studio Visit</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {otherDetails?.isStudioVisit}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )}

          {!Object.keys(clientAndPropertyDetails)?.every(
            (info) => clientAndPropertyDetails[info] === ""
          ) && (
              <View style={styles.componentContainer}>
                <View style={styles.topBar}>
                  <Text style={{ fontSize: 12 }}>Client & Property Details</Text>
                </View>
                <View style={styles.bottomContainer}>
                  <View style={styles.containerContent}>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Client name</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.clientName}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Contact no.</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.contactNumber}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}> Alternate Contact no.</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.alternateContactNumber}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Email ID</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.emailId}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.containerContent}>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Business Type </Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.businessType}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Property Name</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.propertyName}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Flat no.</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.flatNumber}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Property Address </Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.propertyAddress}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.containerContent}>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Geographical location </Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.geographicalLocation}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>City</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.city}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Type of Property</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.typeOfProperty}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Property Orientation </Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.propertyOrientation}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.containerContent}>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Client Profession </Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.clientProfession}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Lead Source</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientAndPropertyDetails?.leadSource}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )}
          {!Object.keys(clientFamilyInfo)?.every(
            (info) => clientFamilyInfo[info] === ""
          ) && (
              <View style={styles.componentContainer}>
                <View style={styles.topBar}>
                  <Text style={{ fontSize: 12 }}>Client Family Info</Text>
                </View>
                <View style={styles.bottomContainer}>
                  <View style={styles.containerContent}>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Religion</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientFamilyInfo?.religion}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Region of Orgin</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientFamilyInfo?.regionOfOrigin}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Type of Family</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientFamilyInfo?.typeOfFamily}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>
                        Number of family Members
                      </Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientFamilyInfo?.numberOfMembers}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.containerContent}>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Family consist of</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientFamilyInfo?.familyConsistOf}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>
                        Traditional & Spiritual
                      </Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientFamilyInfo?.traditionalAndSpritual}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Current Residence </Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientFamilyInfo?.currentResidence}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Believe in vatsu</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {clientFamilyInfo?.believeInVatsu}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )}
          {!Object.keys(projectInfo)?.every(
            (info) => projectInfo[info] === ""
          ) && (
              <View style={styles.componentContainer}>
                <View style={styles.topBar}>
                  <Text style={{ fontSize: 12 }}>Project Info</Text>
                </View>
                <View style={styles.bottomContainer}>
                  <View style={styles.containerContent}>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Tentative Budget</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {projectInfo?.tentativeBudget}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Planned Move in Date</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {projectInfo?.plannedMoveInDate}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Scope</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>{projectInfo?.scope}</Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Style of Design</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {projectInfo?.styleOfDesign}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.containerContent}>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>Color Preference</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {projectInfo?.colorPreference}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.boxContainer}>
                      <Text style={styles.textFont1}>False Ceiling</Text>
                      <View style={styles.borderContainer}>
                        <Text style={styles.textFont}>
                          {projectInfo?.falseCeiling}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )}
          {/* <View style={styles.componentContainer}>
            <View style={styles.topBar}>
              <Text style={{ fontSize: 12 }}>Information</Text>
            </View>
            <View style={styles.bottomContainer}>
              <View style={styles.containerContent}>
                <View style={styles.boxContainer}>
                  <Text style={styles.textFont1}>Designer Introduction</Text>
                  <View style={styles.borderContainer}>
                    <Text style={styles.textFont}>{information?.designerIntroduction}</Text>
                  </View>
                </View>
                <View style={styles.boxContainer}>
                  <Text style={styles.textFont1}>Team Introduction</Text>
                  <View style={styles.borderContainer}>
                    <Text style={styles.textFont}>{information?.teamIntroduction}</Text>
                  </View>
                </View>
                <View style={styles.boxContainer}>
                  <Text style={styles.textFont1}>Company USP</Text>
                  <View style={styles.borderContainer}>
                    <Text style={styles.textFont}>{information?.companyUSP}</Text>
                  </View>
                </View>
                <View style={styles.boxContainer}>
                  <Text style={styles.textFont1}>Project Timeline </Text>
                  <View style={styles.borderContainer}>
                    <Text style={styles.textFont}>{information?.projectTimeline}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.containerContent}>
                <View style={styles.boxContainer}>
                  <Text style={styles.textFont1}>Payment Process</Text>
                  <View style={styles.borderContainer}>
                    <Text style={styles.textFont}>{information?.paymentProcess}</Text>
                  </View>
                </View>
                <View style={styles.boxContainer}>
                  <Text style={styles.textFont1}>Warranty Clause</Text>
                  <View style={styles.borderContainer}>
                    <Text style={styles.textFont}>{information?.warrantyClause}</Text>
                  </View>
                </View>
                <View style={styles.boxContainer}>
                  <Text style={styles.textFont1}>Post Sale Service </Text>
                  <View style={styles.borderContainer}>
                    <Text style={styles.textFont}>{information?.postSaleService}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View> */}
          {!Object.keys(foyer)?.every((info) => foyer[info] === "") && (
            <View style={styles.componentContainer}>
              <View style={styles.topBar}>
                <Text style={{ fontSize: 12 }}>Foyer</Text>
              </View>
              <View style={styles.bottomContainer}>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Inside Shoe Rack</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {foyer?.insideShoeRack}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Outside Shoe Rack</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {foyer?.outsideShoeRack}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Shoe Rack cum Seating</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {foyer?.shoeRackcumSeating}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Wall Treatment </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {foyer?.wallTreatment}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>False Ceiling</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{foyer?.falseCeiling}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>UPS Storage</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{foyer?.UPSStorage}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Storage Unit </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{foyer?.storageUnit}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Additional info</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {foyer?.additionalInfo}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          {!Object.keys(living)?.every((info) => living[info] === "") && (
            <View style={styles.componentContainer}>
              <View style={styles.topBar}>
                <Text style={{ fontSize: 12 }}>Living</Text>
              </View>
              <View style={styles.bottomContainer}>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>TV Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{living?.TVUnit}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      TV unit Back Wall Treatement
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {living?.TVUnitBackWallTreatment}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>L-Type Sofa</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{living?.LTypeSofa}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Split Sofa</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{living?.splitSofa}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Accent chair</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{living?.accentChair}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Center Table</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{living?.centerTable}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Side Table </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{living?.sideTable}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Sofa Back Wall</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {living?.sofaBackWall}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>False Ceiling</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {living?.falseCeiling}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Additional Info</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {living?.additionalInfo}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          {!Object.keys(dining)?.every((info) => dining[info] === "") && (
            <View style={styles.componentContainer}>
              <View style={styles.topBar}>
                <Text style={{ fontSize: 12 }}>Dining</Text>
              </View>
              <View style={styles.bottomContainer}>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>4 Seater</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{dining?.fourSeater}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>6 Seater</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{dining?.sixSeater}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>8 Seater</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{dining?.eightSeater}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>10 Seater </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{dining?.tenSeater}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Base & Wall Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {dining?.baseAndWallUnit}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Only Base Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {dining?.onlyBaseUnit}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Only Wall Unit </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {dining?.onlyWallUnit}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Pooja mandir</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{dining?.poojaMandir}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Stand & Do Pooja</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {dining?.standAndDoPooja}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Sit & Do Pooja</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {dining?.sitAndDoPooja}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>False Ceiling </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {dining?.falseCeiling}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Additional Info</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {dining?.additionalInfo}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          {!Object.keys(kitchen)?.every((info) => kitchen[info] === "") && (
            <View style={styles.componentContainer}>
              <View style={styles.topBar}>
                <Text style={{ fontSize: 12 }}>Kitchen</Text>
              </View>
              <View style={styles.bottomContainer}>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Base Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kitchen?.baseUnit}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Wall Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kitchen?.wallUnit}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Loft</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kitchen?.loft}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>More of Drawers </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kitchen?.moreDrawer}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Accessory Freek</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {kitchen?.accessoryFreak}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      Appliance like Hob & Chimney
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {kitchen?.applianceLikeHobAndChimney}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Tall unit </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kitchen?.tallUnit}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Dual Color Scheme</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {kitchen?.dualColorScheme}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Single Color Scheme</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {kitchen?.singleColorScheme}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Multi Color Scheme</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {kitchen?.multiColorScheme}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Counter Top</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kitchen?.counterTop}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Dado Tile</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kitchen?.dadoTile}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Additional Info</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {kitchen?.additionalInfo}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          {!Object.keys(utility)?.every((info) => utility[info] === "") && (
            <View style={styles.componentContainer}>
              <View style={styles.topBar}>
                <Text style={{ fontSize: 12 }}>Utility</Text>
              </View>
              <View style={styles.bottomContainer}>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Base Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{utility?.baseUnit}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Wall Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{utility?.wallUnit}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Janitor Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {utility?.janitorUnit}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Washing Machine </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {utility?.washingMachine}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Dishwasher</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{utility?.dishwasher}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Additional Info</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {utility?.additionalInfo}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          {!Object.keys(mBR)?.every((info) => mBR[info] === "") && (
            <View style={styles.componentContainer}>
              <View style={styles.topBar}>
                <Text style={{ fontSize: 12 }}>Master Bed Room</Text>
              </View>
              <View style={styles.bottomContainer}>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Swing Wardrobe</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{mBR?.swingWardrobe}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Sliding Wardrobe</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {mBR?.slidingWardrobe}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      Floor to Ceiling Wardrobe
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {mBR?.floorToCeilingWardrobe}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Queen Bed </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{mBR?.queenBed}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>King Bed</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{mBR?.kingBed}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Bedside Table</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{mBR?.bedsideTable}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Dresser</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{mBR?.dresser}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Study Table</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{mBR?.studyTable}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>TV Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{mBR?.TVUnit}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Seating</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{mBR?.seating}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>False Ceiling</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{mBR?.falseCeiling}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Bay Window Seating</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {mBR?.bayWindowSeating}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      Natural Wooden Flooring
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {mBR?.naturalWoodenFlooring}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      Laminated Wooden Flooring
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {mBR?.laminatedWoodenFlooring}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Partition</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{mBR?.partition}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Additional Info</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{mBR?.additionalInfo}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          {!Object.keys(pBR)?.every((info) => pBR[info] === "") && (
            <View style={styles.componentContainer}>
              <View style={styles.topBar}>
                <Text style={{ fontSize: 12 }}>Parent's Bed Room</Text>
              </View>
              <View style={styles.bottomContainer}>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Swing Wardrobe</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{pBR?.swingWardrobe}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Sliding Wardrobe</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {pBR?.slidingWardrobe}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      Floor to Ceiling Wardrobe
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {pBR?.floorToCeilingWardrobe}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Queen Bed </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{pBR?.queenBed}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>King Bed</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{pBR?.kingBed}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Bedside Table</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{pBR?.bedsideTable}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Dresser</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{pBR?.dresser}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Study Table</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{pBR?.studyTable}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>TV Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{pBR?.TVUnit}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Seating</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{pBR?.seating}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>False Ceiling</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{pBR?.falseCeiling}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Bay Window Seating</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {pBR?.bayWindowSeating}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      Natural Wooden Flooring
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {pBR?.naturalWoodenFlooring}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      Laminated Wooden Flooring
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {pBR?.laminatedWoodenFlooring}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Partition</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{pBR?.partition}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Additional Info</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{pBR?.additionalInfo}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          {!Object.keys(kBR)?.every((info) => kBR[info] === "") && (
            <View style={styles.componentContainer}>
              <View style={styles.topBar}>
                <Text style={{ fontSize: 12 }}>Kid's Bed Room</Text>
              </View>
              <View style={styles.bottomContainer}>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Swing Wardrobe</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kBR?.swingWardrobe}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Sliding Wardrobe</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {kBR?.slidingWardrobe}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      Floor to Ceiling Wardrobe
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {kBR?.floorToCeilingWardrobe}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Queen Bed </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kBR?.queenBed}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>King Bed</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kBR?.kingBed}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Bunk Bed</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kBR?.kingBed}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Murphy Bed</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kBR?.kingBed}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Bedside Table</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kBR?.bedsideTable}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Dresser</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kBR?.dresser}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Study Table</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kBR?.studyTable}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>TV Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kBR?.TVUnit}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Seating</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kBR?.seating}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>False Ceiling</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kBR?.falseCeiling}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Bay Window Seating</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {kBR?.bayWindowSeating}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      Natural Wooden Flooring
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {kBR?.naturalWoodenFlooring}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      Laminated Wooden Flooring
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {kBR?.laminatedWoodenFlooring}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Partition</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kBR?.partition}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Additional Info</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{kBR?.additionalInfo}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          {!Object.keys(gBR)?.every((info) => gBR[info] === "") && (
            <View style={styles.componentContainer}>
              <View style={styles.topBar}>
                <Text style={{ fontSize: 12 }}>Guest's Bed Room</Text>
              </View>
              <View style={styles.bottomContainer}>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Swing Wardrobe</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{gBR?.swingWardrobe}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Sliding Wardrobe</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {gBR?.slidingWardrobe}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      Floor to Ceiling Wardrobe
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {gBR?.floorToCeilingWardrobe}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Queen Bed </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{gBR?.queenBed}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>King Bed</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{gBR?.kingBed}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Bunk Bed</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{gBR?.kingBed}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Murphy Bed</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{gBR?.kingBed}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Bedside Table</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{gBR?.bedsideTable}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Dresser</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{gBR?.dresser}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Study Table</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{gBR?.studyTable}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>TV Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{gBR?.TVUnit}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Seating</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{gBR?.seating}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>False Ceiling</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{gBR?.falseCeiling}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Bay Window Seating</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {gBR?.bayWindowSeating}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      Natural Wooden Flooring
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {gBR?.naturalWoodenFlooring}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>
                      Laminated Wooden Flooring
                    </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {gBR?.laminatedWoodenFlooring}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Partition</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{gBR?.partition}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Additional Info</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{gBR?.additionalInfo}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          {!Object.keys(balcony)?.every((info) => balcony[info] === "") && (
            <View style={styles.componentContainer}>
              <View style={styles.topBar}>
                <Text style={{ fontSize: 12 }}>Balcony</Text>
              </View>
              <View style={styles.bottomContainer}>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Janitor Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {balcony?.janitorUnit}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Storage Unit</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {balcony?.storageUnit}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Clothes Drying Wire</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {balcony?.clothesDryingWire}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Green wall </Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{balcony?.greenWall}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.containerContent}>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Furniture</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>{balcony?.furniture}</Text>
                    </View>
                  </View>
                  <View style={styles.boxContainer}>
                    <Text style={styles.textFont1}>Additional Info</Text>
                    <View style={styles.borderContainer}>
                      <Text style={styles.textFont}>
                        {balcony?.additionalInfo}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
          <View style={styles.pdfHeading}>
            <Text></Text>
          </View>
          <View style={styles.footerText}>
            <Text>We make sure you feel your space is luxurious and modern and comfortable at the same time. We Dezine is a young and dynamic group of talents who will handle each project as if it’s their own space.  In recent years, the brand has spread its wings into designing homes, offices, commercial and hospitality spaces. We ensure that each customer’s experience is a special one.</Text>
              <Text>Be it planning, presentation or implementation, designing is re-defined in its own style of making. Rather than jumping directly to the carpentry work, at We Dezine, your vision is our prime importance.What makes We Dezine worth it is the people who work behind it. They are versatile, experienced, enthusiastic and always up for a challenge. Ergo, giving you furnishing solutions all under one platform.  Thank you for giving us the opportunity to serve you. We greatly value your business.</Text>
              <Text>We are looking forward to working with you!</Text>
          </View>
          <View style={styles.pdfHeading}>
            <Text></Text>
          </View>
          <View style={styles.footerText}>
            <Text style={styles.footerMotoText}>DESIGN TO DELIVER STUDIO PRIVATE LIMITED </Text>
              <Text>Corporate Office: No. 10. 19th cross. Kashi Mutt road. Malleswaram, Bangalore. 560055.</Text>
              <Text>Registered office: No. 6 2nd Cross, R.T Street, Avenue Road, Chickpet , Bangalore . 560053</Text>
              <Text>Director: Jitendra Raju, Contact: +91 98444 08406, Email -  jitendraraju@wedezinestudio.com </Text>
              <Text>website | www.wedezinestudio.com & CIN U74999KA2020PTC138265</Text>
          </View>
          <Image
            style={{ width: "100%", height: "auto" }}
            src={BCChecklistFooterMoto}
            alt="My Image"
          />
        </View>
      </Page>
    </Document>
  );
}

export default PDFComponentPage;
