import React, { useState } from 'react'
import DropdownComponent from '../DropdownComponent'
import styles from '../../styles/additionalInfo.module.css'
import data from "./Data";
import InputBoxComponent from '../InputBoxComponent';
import { useDispatch, useSelector } from 'react-redux'
import { setutility } from '../../../../../Redux/Slices/additionalDetailsSlice';

function Utility() {
const dispatch = useDispatch();
    const utility = useSelector(state => state.additionalInfoSlice.utility);

  const handleChange = (type, value) => {
    dispatch(setutility({ ...utility, [type]: value }));
};
    return (
        <>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Base Unit`}</div>
                  <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={utility}
                        type="baseUnit"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Wall Unit `}</div>
                  <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={utility}
                        type="wallUnit"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Janitor Unit `}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={utility}
                        type="janitorUnit"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                >
                    <div
                        className={styles.filter_icon}

                    >{`Washing Machine `}</div>
                     <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={utility}
                        type="washingMachine"
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Dishwasher `}</div>
                     <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={utility}
                        type="dishwasher"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Additional Info `}</div>
                 <InputBoxComponent  selectedElement={utility} type='additionalInfo' handleChange={handleChange} placeholder={"Any additional info"} />
                </div>
          

            </div>
        </>
    )
}

export default Utility