import React, { useState } from 'react'
import DropdownComponent from '../DropdownComponent'
import styles from '../../styles/additionalInfo.module.css'
import data from "./Data";
import InputBoxComponent from '../InputBoxComponent';
import { useDispatch, useSelector } from 'react-redux'
import { setkitchen } from '../../../../../Redux/Slices/additionalDetailsSlice';
function Kitchen() {
    // const [kitchen, setKitchen] = useState( {
    //     baseUnit:'',
    //     wallUnit:'',
    //     loft:'',
    //     moreDrawer:'',
    //     accessoryFreak:'',
    //     applianceLikeHobAndChimney:'',
    //     tallUnit:'',
    //     dualColorScheme:'',
    //     singleColorScheme:'',
    //     multiColorScheme:'',
    //     counterTop:'',
    //     dadoTile:'',
    //     additionalInfo: '',
    //   })
    const dispatch = useDispatch();
    const kitchen = useSelector(state => state.additionalInfoSlice.kitchen);

    
    const handleChange = (type, value) => {
        dispatch(setkitchen({ ...kitchen, [type]: value }));
    };
    return (
        <>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Base Unit `}</div>
                     <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={kitchen}
                        type="baseUnit"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Wall Unit `}</div>
                     <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={kitchen}
                        type="wallUnit"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Loft `}</div>
                      <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={kitchen}
                        type="loft"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                >
                    <div
                        className={styles.filter_icon}

                    >{`More of Drawers `}</div>
                      <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={kitchen}
                        type="moreDrawer"
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Accessory Freek `}</div>
                     <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={kitchen}
                        type="accessoryFreak"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Appliance like Hob & Chimney `}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={kitchen}
                        type="applianceLikeHobAndChimney"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Tall unit `}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={kitchen}
                        type="tallUnit"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Dual Color Scheme `}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={kitchen}
                        type="dualColorScheme"
                        handleChange={handleChange}
                    />
                </div>

            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Single Color Scheme `}</div>
                     <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={kitchen}
                        type="singleColorScheme"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Multi Color Scheme `}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={kitchen}
                        type="multiColorScheme"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Counter Top `}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={kitchen}
                        type="counterTop"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Dado Tile `}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={kitchen}
                        type="dadoTile"
                        handleChange={handleChange}
                    />
                </div>

            </div>
            <div className="d-flex align-items-center">
            <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Additional info `}</div>
                  <InputBoxComponent selectedElement={kitchen} type='additionalInfo' handleChange={handleChange} placeholder={"Any additional info"} />
                </div>

            </div>
        </>
    )
}

export default Kitchen