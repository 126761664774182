import React, { useState } from "react";
import DropdownComponent from "../DropdownComponent";
import styles from "../../styles/additionalInfo.module.css";
import DateInputBox from "../DateInputBox";
import data from "./Data";
import ColorPicker from "../ColorPicker";
import { useDispatch, useSelector } from "react-redux";
import { setprojectInfo } from "../../../../../Redux/Slices/additionalDetailsSlice";
import InputBoxComponent from "../InputBoxComponent";

function ProjectInfo() {
  const dispatch = useDispatch();
  const projectInfo = useSelector(
    (state) => state.additionalInfoSlice.projectInfo
  );

  const handleChange = (type, value) => {
    dispatch(setprojectInfo({ ...projectInfo, [type]: value }));
  };
  console.log("projectInfo", projectInfo);
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Tentative Budget `}</div>
          <DropdownComponent
            elementArr={data.PROJECT_INFO.TENTATIVE_BUDGET}
            // clearFilterOptions={clearFilterOptions}
            selectedElement={projectInfo}
            type="tentativeBudget"
            handleChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Planned Move `}</div>
          <DateInputBox
            placeholder={"Select a date"}
            inputType="date"
            selectedElement={projectInfo}
            type="plannedMoveInDate"
            handleChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Scope `}</div>
          <DropdownComponent
            elementArr={data.PROJECT_INFO.SCOPE}
            // clearFilterOptions={clearFilterOptions}
            selectedElement={projectInfo}
            type="scope"
            handleChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column">
          <div className={styles.filter_icon}>{`Style of Design `}</div>
          <DropdownComponent
            elementArr={data.PROJECT_INFO.STYLE_OF_DESIGN}
            // clearFilterOptions={clearFilterOptions}
            selectedElement={projectInfo}
            type="styleOfDesign"
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Color Preference `}</div>
          <InputBoxComponent
            type="colorPreference"
            selectedElement={projectInfo}
            handleChange={handleChange}
            placeholder={""}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`False Ceiling `}</div>
          <DropdownComponent
            elementArr={data.PROJECT_INFO.FALSE_CEILING}
            // clearFilterOptions={clearFilterOptions}
            selectedElement={projectInfo}
            type="falseCeiling"
            handleChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}

export default ProjectInfo;
