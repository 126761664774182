import React, { useState } from "react";
import DropdownComponent from "../DropdownComponent";
import styles from "../../styles/additionalInfo.module.css";
import InputBoxComponent from "../InputBoxComponent";
import data from "./Data";
import { useDispatch, useSelector } from "react-redux";
import { setclientAndPropertyDetails } from "../../../../../Redux/Slices/additionalDetailsSlice";
function ClientAndPropertyDetails() {
  const dispatch = useDispatch();
  const clientAndPropertyDetails = useSelector(
    (state) => state.additionalInfoSlice.clientAndPropertyDetails
  );

  const handleChange = (type, value) => {
    dispatch(
      setclientAndPropertyDetails({
        ...clientAndPropertyDetails,
        [type]: value,
      })
    );
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Client name `}</div>
          <InputBoxComponent
            type="clientName"
            handleChange={handleChange}
            selectedElement={clientAndPropertyDetails}
            placeholder={"Enter Client Name"}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Contact no. `}</div>
          <InputBoxComponent
            type="contactNumber"
            selectedElement={clientAndPropertyDetails}
            handleChange={handleChange}
            placeholder={"Enter Contact No"}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Alternative contact no `}</div>
          <InputBoxComponent
            type="alternateContactNumber"
            handleChange={handleChange}
            placeholder={"Enter alternate no."}
            selectedElement={clientAndPropertyDetails}
          />
        </div>
        <div className="d-flex flex-column">
          <div className={styles.filter_icon}>{`Email ID `}</div>
          <InputBoxComponent
            type="emailId"
            handleChange={handleChange}
            placeholder={"Enter email id"}
            selectedElement={clientAndPropertyDetails}
          />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Business Type `}</div>
          <DropdownComponent
            elementArr={data.CLIENT_AND_PROPERTY_DETAILS.BUSINESS_TYPE}
            selectedElement={clientAndPropertyDetails}
            type="businessType"
            handleChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Property Name `}</div>
          <InputBoxComponent
            type="propertyName"
            handleChange={handleChange}
            selectedElement={clientAndPropertyDetails}
            placeholder={"Enter Property Name"}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Flat no.`}</div>
          <InputBoxComponent
            type="flatNumber"
            handleChange={handleChange}
            selectedElement={clientAndPropertyDetails}
            placeholder={"Enter flat no"}
          />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Property Address `}</div>
          <InputBoxComponent
            type="propertyAddress"
            handleChange={handleChange}
            selectedElement={clientAndPropertyDetails}
            placeholder={"Enter property add."}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Geographical location `}</div>
          <InputBoxComponent
            type="geographicalLocation"
            handleChange={handleChange}
            placeholder={"Enter geo. location"}
            selectedElement={clientAndPropertyDetails}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`City`}</div>
          <DropdownComponent
            elementArr={data.CLIENT_AND_PROPERTY_DETAILS.CITIES}
            selectedElement={clientAndPropertyDetails}
            type="city"
            handleChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column">
          <div className={styles.filter_icon}>{`Type of Property`}</div>
          <DropdownComponent
            elementArr={data.CLIENT_AND_PROPERTY_DETAILS.TYPE_OF_PROPERTY}
            selectedElement={clientAndPropertyDetails}
            type="typeOfProperty"
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Property Orientation `}</div>
          <DropdownComponent
            elementArr={data.CLIENT_AND_PROPERTY_DETAILS.PROPERTY_ORIENTATION}
            // clearFilterOptions={clearFilterOptions}
            selectedElement={clientAndPropertyDetails}
            type="propertyOrientation"
            handleChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Client Profession `}</div>
          <DropdownComponent
            elementArr={data.CLIENT_AND_PROPERTY_DETAILS.CLIENT_PROFESSION}
            selectedElement={clientAndPropertyDetails}
            type="clientProfession"
            handleChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Lead Source`}</div>
          <DropdownComponent
            elementArr={data.CLIENT_AND_PROPERTY_DETAILS.LEAD_SOURCES}
            selectedElement={clientAndPropertyDetails}
            type="leadSource"
            handleChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}

export default ClientAndPropertyDetails;
