import React, { useState } from 'react'
import DropdownComponent from '../DropdownComponent'
import styles from '../../styles/additionalInfo.module.css'
import data from "./Data";
import InputBoxComponent from '../InputBoxComponent';

import { useDispatch, useSelector } from 'react-redux'
import { setdining } from '../../../../../Redux/Slices/additionalDetailsSlice';
function Dining() {
    const dispatch = useDispatch();
    const dining = useSelector(state => state.additionalInfoSlice.dining);

    const handleChange = (type, value) => {
        dispatch(setdining({ ...dining, [type]: value }));
    };
    return (
        <>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`4 Seater `}</div>
                    <DropdownComponent
                        elementArr={data.LIVING.TV_UNIT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={dining}
                        type="fourSeater"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`6 Seater `}</div>
                    <DropdownComponent
                        elementArr={data.LIVING.TV_UNIT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={dining}
                        type="sixSeater"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`8 Seater `}</div>
                    <DropdownComponent
                        elementArr={data.LIVING.TV_UNIT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={dining}
                        type="eightSeater"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                >
                    <div
                        className={styles.filter_icon}

                    >{`10 Seater `}</div>
                    <DropdownComponent
                        elementArr={data.LIVING.TV_UNIT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={dining}
                        type="tenSeater"
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Base & Wall Unit `}</div>
                    <DropdownComponent
                        elementArr={data.LIVING.TV_UNIT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={dining}
                        type="baseAndWallUnit"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Only Base Unit `}</div>
                    <DropdownComponent
                        elementArr={data.LIVING.TV_UNIT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={dining}
                        type="onlyBaseUnit"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Only Wall Unit `}</div>
                    <DropdownComponent
                        elementArr={data.LIVING.TV_UNIT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={dining}
                        type="onlyWallUnit"
                        handleChange={handleChange}
                    />
                </div>

                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Pooja mandir `}</div>
                    <DropdownComponent
                        elementArr={data.LIVING.TV_UNIT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={dining}
                        type="poojaMandir"
                        handleChange={handleChange}
                    />
                </div>

            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Stand & Do Pooja `}</div>
                    <DropdownComponent
                        elementArr={data.LIVING.TV_UNIT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={dining}
                        type="standAndDoPooja"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Seat & Do Pooja `}</div>
                    <DropdownComponent
                        elementArr={data.LIVING.TV_UNIT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={dining}
                        type="sitAndDoPooja"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`False Ceiling `}</div>
                    <DropdownComponent
                        elementArr={data.LIVING.TV_UNIT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={dining}
                        type="falseCeiling"
                        handleChange={handleChange}
                    />
                </div>

                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Additional Info `}</div>
                    <InputBoxComponent selectedElement={dining} type='additionalInfo' handleChange={handleChange} placeholder={"Any additional info"} />
                </div>

            </div>
        </>
    )
}

export default Dining