import React, { useState } from "react";
import DropdownComponent from "../DropdownComponent";
import styles from "../../styles/additionalInfo.module.css";
import data from "./Data";
import InputBoxComponent from "../InputBoxComponent";
import DateInputBox from "../DateInputBox";
import { useDispatch, useSelector } from 'react-redux'
import { setclientDetails } from "../../../../../Redux/Slices/additionalDetailsSlice";
function ClientDetails() {
  const dispatch = useDispatch();
  const clientDetails = useSelector(state => state.additionalInfoSlice.clientDetails);

  const handleChange = (type, value) => {
    dispatch(setclientDetails({ ...clientDetails, [type]: value }));
  };

  console.log('ClientDetailsss', clientDetails)

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Purpose`}</div>
          <DropdownComponent
            elementArr={data.CLIENT_DETAILS.PURPOSE}
            selectedElement={clientDetails}
            type="purpose"
            handleChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Property Name`}</div>
          <InputBoxComponent type='propertyName' selectedElement={clientDetails} handleChange={handleChange} placeholder={"Enter Property Name"} />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Property category `}</div>
          <DropdownComponent
            elementArr={Object.keys(
              data.CLIENT_DETAILS.PROPERTY_CATEGORY_SUBCATEGORY
            )}
            selectedElement={clientDetails}
            type="propertyCategory"
            handleChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column">
          <div className={styles.filter_icon}>{`Property Sub Category `}</div>
          <DropdownComponent
            elementArr={
              data.CLIENT_DETAILS.PROPERTY_CATEGORY_SUBCATEGORY[
              clientDetails?.propertyCategory
              ]
            }
            selectedElement={clientDetails}
            type="propertySubcategory"
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Possession Status `}</div>
          <DropdownComponent
            elementArr={data.CLIENT_DETAILS.POSSSESSION_STATUS}
            selectedElement={clientDetails}
            type="possessionStatus"
            handleChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Budget `}</div>
          <DropdownComponent
            elementArr={data.CLIENT_DETAILS.BUDGET}
            selectedElement={clientDetails}
            type="budget"
            handleChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column" style={{ marginRight: "5rem" }}>
          <div className={styles.filter_icon}>{`Brief Call `}</div>
          <DateInputBox
            placeholder={"Select a date"}
            selectedElement={clientDetails}
            inputType="datetime-local"
            type="briefCall"
            handleChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}

export default ClientDetails;
