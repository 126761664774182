import React, { useState } from 'react'
import DropdownComponent from '../DropdownComponent'
import styles from '../../styles/additionalInfo.module.css'
import DateInputBox from '../DateInputBox'
import data from "./Data";

import { useDispatch, useSelector } from 'react-redux'
import { setmeetingDetails } from '../../../../../Redux/Slices/additionalDetailsSlice';
function MeetingDetails() {
    const dispatch = useDispatch();
    const meetingDetails = useSelector(state => state?.additionalInfoSlice?.meetingDetails);
    console.log('meting details', meetingDetails)

    const handleChange = (type, value) => {
        dispatch(setmeetingDetails({ ...meetingDetails, [type]: value }));
    };
    console.log('meetingDetails', meetingDetails)
    return (
        <>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Meeting Date `}</div>
                    <DateInputBox
                        placeholder={"Select a date"}
                        inputType="date"
                        type="meetingDate"
                        selectedElement={meetingDetails}
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Attendees `}</div>
                    <DropdownComponent
                        elementArr={data.MEETING_DETAILS.MEETING_ATTENDIES}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={meetingDetails}
                        type="attendees"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Place `}</div>
                    <DropdownComponent
                        elementArr={data.MEETING_DETAILS.MEETING_PLACE}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={meetingDetails}
                        type="meetingPlace"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                >
                    <div
                        className={styles.filter_icon}

                    >{`Meeting Type `}</div>
                    <DropdownComponent
                        elementArr={data.MEETING_DETAILS.MEETING_TYPE}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={meetingDetails}
                        type="meetingType"
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Meeting Time `}</div>
                    <DateInputBox
                        placeholder={"Select a time"}
                        inputType="time"
                        type="meetingTime"
                        selectedElement={meetingDetails}
                        handleChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

export default MeetingDetails