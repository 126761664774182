import React, { useState } from "react";
import styles from "../GetOtpForgot/resetPassword.module.css";
import { TextField, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { postReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import LoginHeader from "../loginHeader/LoginHeader";
import { useDispatch } from "react-redux";
import { saveOtpMail } from "../../Redux/Slices/persistSlice";
import { apiLinks } from "../../piservices/constants";
import { toasterControl } from "../../piservices/commonService";

const ResetPassword = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [emailText, setEmailText] = useState("");
  const [error, setError] = useState("");

  const handleInput = (event) => {
    setEmailText(event.target.value);
  };
  const getOtp = async () => {
    const res = await postReq(`${apiLinks.emailApi}${apiEndpoints.sendOtp}`, {
      email: emailText,
    });
    if (res && !res.error) {
      //input ko redux me save kar do
      dispatch(saveOtpMail(emailText));
      navigateTo("/verify-forgot");
    } else {
      // toasterControl("toasterState", true, "error", "Email not exist");
      if(emailText.length === 0){
        setError("Field is Empty")
      }
      else{
        setError(res.error.response.data)
      }
      
    }
  };
  return (
    <>
      <LoginHeader />
      <div className={styles.container}>
        <div className={styles.box}>
          {/* <div style={{ padding: "24px", borderBottom: "1px solid #E0E3E6", color: "#40454A", fontFamily: "Inter", fontSize: "28px", fontStyle: "normal", fontWeight: 700, lineHeight: "28px" }}> */}
           <div className={styles.resetPassword} >
            Reset Password
          </div>
          {/* <div style={{ padding: "24px 24px 28px 24px", display: "flex", flexDirection: "column", alignItems: "center", gap: "1.5rem" }}> */}
           <div className={styles.resetPasswordContainer}>
            <div className={styles.para}>Please enter email associated with your account and we will send you an OTP</div>
            <div style={{ borderRadius: "12px", width: "100%" }}>
              <TextField fullWidth id="outlined-basic" error={error} label="Email" variant="outlined" InputProps={{ style: { borderRadius: "12px", } }} inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px"}}} onChange={handleInput}  />
              <div className="error">{error}</div>
            </div>

            <div style={{ width: "100%" }}>
              <button className={styles.getotpBtn} style={{ color: "#ffffff", cursor: "pointer" }} onClick={() => getOtp()}>
                Get OTP
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
