const BASE_URL = "https://erp.wedezinestudio.com";

export const headerArr = [
  {
    label: "Home",
    href: `${BASE_URL}/admin/`,
    accessName: "default",
    active: window.location.pathname.includes("admin") ? true : false,
  },
  {
    label: "Leads",
    href: `${BASE_URL}/leads/`,
    accessName: "crm",

    active: window.location.pathname.includes("leads") ? true : false,
  },
  {
    label: "Projects",
    href: `${BASE_URL}/pmt/`,
    accessName: "default",
    drop: true,
    active: window.location.pathname.includes("pmt") ? true : false,
  },
  {
    label: "Tools",
    href: `${BASE_URL}/tools/`,
    accessName: "default",
    active: false,
  },
  {
    label: "Chat",
    link: "",
    href: `${BASE_URL}/updates/`,
    accessName: "default",
    active: false,
  },
  {
    label: "Tasks",
    link: "",
    href: `${BASE_URL}/timeline/my-task/`,
    accessName: "default",
    active: false,
  },
];
