import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  BC_PDF :[],
  balcony: {
    janitorUnit: "",
    storageUnit: "",
    clothesDryingWire: "",
    greenWall: "",
    furniture: "",
    additionalInfo: "",
  },
  clientAndPropertyDetails: {
    clientName: "",
    contactNumber: "",
    alternateContactNumber: "",
    emailId: "",
    businessType: "",
    propertyName: "",
    geographicalLocation: "",
    possessionStatus: "",
    flatNumber: "",
    city: "",
    clientProfession: "",
    propertyAddress: "",
    typeOfProperty: "",
    leadSource: "",
    propertyOrientation: "",
    dateOfBC: "",
  },
  clientDetails: {
    purpose: "",
    name:"",
    propertyName: "",
    propertyCategory: "",
    propertySubcategory: "",
    possessionStatus: "",
    budget: "",
    briefCall: "",
  },
  clientFamilyInfo: {
    religion: "",
    regionOfOrigin: "",
    typeOfFamily: "",
    numberOfMembers: "",
    familyConsistOf: "",
    traditionalAndSpritual: "",
    currentResidence: "",
    believeInVatsu: "",
  },
  dining: {
    fourSeater: "",
    sixSeater: "",
    eightSeater: "",
    tenSeater: "",
    baseAndWallUnit: "",
    onlyWallUnit: "",
    onlyBaseUnit: "",
    poojaMandir: "",
    standAndDoPooja: "",
    sitAndDoPooja: "",
    falseCeiling: "",
    additionalInfo: "",
  },
  foyer: {
    insideShoeRack: "",
    outsideShoeRack: "",
    shoeRackcumSeating: "",
    wallTreatment: "",
    falseCeiling: "",
    UPSStorage: "",
    storageUnit: "",
    additionalInfo: "",
  },
  GBR: {
    swingWardrobe: "",
    slidingWardrobe: "",
    floorToCeilingWardrobe: "",
    queenBed: "",
    kingBed: "",
    bunkBed: "",
    murphyBed: "",
    bedsideTable: "",
    dresser: "",
    studyTable: "",
    TVUnit: "",
    seating: "",
    falseCeiling: "",
    bayWindowSeating: "",
    naturalWoodenFlooring: "",
    laminatedWoodenFlooring: "",
    partition: "",
    additionalInfo: "",
  },
  information: {
    designerIntroduction: "", // 0-no , 1-yes, 2-to be checked/informed, 3-others
    teamIntroduction: "", // 0-no , 1-yes, 2-to be checked/informed, 3-others
    companyUSP: "", // 0-no , 1-yes, 2-to be checked/informed, 3-others
    projectTimeline: "", // 0-no , 1-yes, 2-to be checked/informed, 3-others
    paymentProcess: "", // 0-no , 1-yes, 2-to be checked/informed, 3-others
    warrantyClause: "", // 0-no , 1-yes, 2-to be checked/informed, 3-others
    postSaleService: "",
  },
  KBR: {
    swingWardrobe: "",
    slidingWardrobe: "",
    floorToCeilingWardrobe: "",
    queenBed: "",
    kingBed: "",
    bunkBed: "",
    murphyBed: "",
    bedsideTable: "",
    dresser: "",
    studyTable: "",
    TVUnit: "",
    seating: "",
    falseCeiling: "",
    bayWindowSeating: "",
    naturalWoodenFlooring: "",
    laminatedWoodenFlooring: "",
    partition: "",
    additionalInfo: "",
  },
  kitchen: {
    baseUnit: "",
    wallUnit: "",
    loft: "",
    moreDrawer: "",
    accessoryFreak: "",
    applianceLikeHobAndChimney: "",
    tallUnit: "",
    dualColorScheme: "",
    singleColorScheme: "",
    multiColorScheme: "",
    counterTop: "",
    dadoTile: "",
    additionalInfo: "",
  },
  living: {
    TVUnit: "",
    TVUnitBackWallTreatment: "",
    LTypeSofa: "",
    splitSofa: "",
    accentChair: "",
    centerTable: "",
    sideTable: "",
    sofaBackWall: "",
    falseCeiling: "",
    additionalInfo: "",
  },
  MBR: {
    swingWardrobe: "",
    slidingWardrobe: "",
    floorToCeilingWardrobe: "",
    queenBed: "",
    kingBed: "",
    bedsideTable: "",
    dresser: "",
    studyTable: "",
    TVUnit: "",
    seating: "",
    falseCeiling: "",
    bayWindowSeating: "",
    naturalWoodenFlooring: "",
    laminatedWoodenFlooring: "",
    partition: "",
    additionalInfo: "",
  },
  meetingDetails: {
    attendees: "",
    meetingPlace: "",
    meetingType: "",
    meetingDate: "",
    meetingTime: "",
  },
  otherDetails: {
    isWhatsappGroupCreated: '',
    isFloorPlanReceived: '',
    isSiteVisitReceived: '',
    isStudioVisit: '',
  },
  PBR: {
    swingWardrobe: '',
    slidingWardrobe: "",
    floorToCeilingWardrobe: "",
    queenBed: "",
    kingBed: "",
    bedsideTable: "",
    dresser: "",
    studyTable: "",
    TVUnit: "",
    seating: "",
    falseCeiling: "",
    bayWindowSeating: "",
    naturalWoodenFlooring: "",
    laminatedWoodenFlooring: "",
    partition: "",
    additionalInfo: "",
  },
  projectInfo: {
    tentativeBudget: "",
    plannedMoveInDate: "",
    scope: "",
    styleOfDesign: "",
    colorPreference: "",
    falseCeiling: "",
  },
  utility: {
    baseUnit: "",
    wallUnit: "",
    janitorUnit: "",
    washingMachine: "",
    dishwasher: "",
    additionalInfo: "",
  },
};
export const additionalInfoSlice = createSlice({
  name: "additionalInfo",
  initialState,
  reducers: {
    setPDF: (state, action) => {
      state.BC_PDF = action.payload;
    },
    setBalcony: (state, action) => {
      state.balcony = action.payload;
    },
    setclientAndPropertyDetails: (state, action) => {
      state.clientAndPropertyDetails = action.payload;
    },
    setclientDetails: (state, action) => {
      console.log('clientdetailinredux', action.payload)
      state.clientDetails = action.payload;
    },
    setclientFamilyInfo: (state, action) => {
      state.clientFamilyInfo = action.payload;
    },
    setdining: (state, action) => {
      state.dining = action.payload;
    },
    setfoyer: (state, action) => {
      state.foyer = action.payload;
    },
    setgbr: (state, action) => {
      state.GBR = action.payload;
    },
    setinformation: (state, action) => {
      state.information = action.payload;
    },
    setkbr: (state, action) => {
      state.KBR = action.payload;
    },
    setkitchen: (state, action) => {
      state.kitchen = action.payload;
    },
    setliving: (state, action) => {
      state.living = action.payload;
    },
    setmbr: (state, action) => {
      state.MBR = action.payload;
    },
    setmeetingDetails: (state, action) => {
      state.meetingDetails = action.payload;
    },
    setotherDetails: (state, action) => {
      state.otherDetails = action.payload;
    },
    setpbr: (state, action) => {
      state.PBR = action.payload;
    },
    setprojectInfo: (state, action) => {
      state.projectInfo = action.payload;
    },
    setutility: (state, action) => {
      state.utility = action.payload;
    },
  },
});

export const {
  setBalcony,
  setclientAndPropertyDetails,
  setclientDetails,
  setclientFamilyInfo,
  setdining,
  setfoyer,
  setgbr,
  setinformation,
  setkbr,
  setkitchen,
  setliving,
  setmbr,
  setmeetingDetails,
  setotherDetails,
  setpbr,
  setPDF,
  setprojectInfo,
  setutility,
} = additionalInfoSlice.actions;
export default additionalInfoSlice.reducer;
