import React from "react";
import styles from "./AdminPersonalData.module.css";
// import icon from "../footerImg/file/Vector.svg";
// import icon1 from "../footerImg/task/Vector.png";
// import icon2 from "../footerImg/reports/Vector.png";
import { FaArrowLeft } from "react-icons/fa6";
import { useState } from "react";
import { TextField, useMediaQuery } from "@mui/material";
import { getReq, postTReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { checkIfHasOnlyNumbers, validationFunction } from "../../piservices/commonService";
import { adminPersonalDataValObj, apiLinks, base_url, numberArray } from "../../piservices/constants";
import idesign from "../../Assets/logo.png";
import MuiPhoneNumber from "material-ui-phone-number";
import { useEffect } from "react";
import { getToken } from "../../piservices/authService";

const AdminPersonalData = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const navigateTo = useNavigate();
  const [error, setError] = useState({});

  const [input, setinput] = useState({
    fname: "",
    lName: "",
    address: "",
    city: "",
    zipCode: "",
    birth: "",
    number: "",
  });

  const [profileData, setProfileData] = useState({});
  const getProfileData = async () => {
    const res = await getReq(`${apiLinks.crm}/user/profile`, {}, { Authorization: `Bearer ${getToken()}` });
    if (res && !res.error) {
      setProfileData({ ...res.data.data });
    } else {
      console.log(res.error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    if (profileData) {
      if (profileData.fullName) {
        setinput((prev) => {
          return {
            ...prev,
            fname: profileData.fullName.split(" ")[0],
            lName: profileData.fullName.split(" ")[1],
          };
        });
      }

      if (profileData.phoneNumber) {
        setinput((prev) => {
          return {
            ...prev,
            number: profileData.phoneNumber,
          };
        });
      }

      if (profileData.dateOfBirth) {
        setinput((prev) => {
          return {
            ...prev,
            birth: profileData.dateOfBirth,
          };
        });
      }
    }
  }, [profileData]);

  const handleInput = (event) => {
    const { name, value } = event.target;
    const valText = validationFunction(adminPersonalDataValObj[name], value);
    if (valText) {
      setError((prev) => {
        return {
          ...prev,
          [name]: true,
        };
      });
    } else {
      setError((prev) => {
        return {
          ...prev,
          [name]: false,
        };
      });
    }

    if (name === "zipCode") {
      if(checkIfHasOnlyNumbers(value)){
        if (value.length < 7) {
          setinput((prev) => {
            return {
              ...prev,
              zipCode: value,
            };
          });
        }
      }
    
    }
    if (name === "fname") {
      if (value.length < 20) {
        setinput((prev) => {
          return {
            ...prev,
            fname: value,
          };
        });
      }
    }
    if (name === "lName") {
      if (value.length < 20) {
        setinput((prev) => {
          return {
            ...prev,
            lName: value,
          };
        });
      }
    }

    if (name === "address") {
      if (value.length < 40) {
        setinput((prev) => {
          return {
            ...prev,
            address: value,
          };
        });
      }
    }

    if (name === "city") {
      let check = numberArray.some((curelem) => {
        return value.includes(curelem);
      });
      if (!check) {
        if (value.length < 20) {
          setinput((prev) => {
            return {
              ...prev,
              city: value,
            };
          });
        }
      }
    }

    if (name === "birth") {
      setinput((prev) => {
        return {
          ...prev,
          birth: value,
        };
      });
    }

    if (name === "number") {
      setinput((prev) => {
        return {
          ...prev,
          number: value,
        };
      });
    }
  };

  // update profile
  const upDateProfile = async () => {
    const payload = new FormData();
    payload.append("firstName", input.fname);
    payload.append("lastName", input.lName);
    payload.append("address", input.address);
    payload.append("city", input.city);
    payload.append("pinCode", input.zipCode);
    payload.append("dateOfBirth", input.birth);
    payload.append("phoneNumber", input.number);
    payload.append("isSuperAdmin", true);

    const res = await postTReq(`${apiLinks.crm}/user/updateProfile`, payload);
    if (res && !res.error) {
      navigateTo("/company-details");
      setinput({
        fname: "",
        lName: "",
        address: "",
        city: "",
        zipCode: "",
        birth: "",
        number: "",
      });
    } else {
      console.error(res.error);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.first_contain}>
          {/* <img src={idesign} alt="" style={{ height: "2rem", position: "absolute", top: "2rem", left: "4rem" }} /> */}
          <div className={styles.logoImgContainer}><img src={idesign} alt="" className={styles.logoImg} /></div>
          <Carousel
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "85%",
            }}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
          >
            {/* <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}> */}
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207461%20%281%29.png" alt="First slide" className={styles.imgg} />
              </div>
              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                {/* <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group.svg" alt="" style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.5rem", marginBottom: "1rem" }} /> */}
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group.svg" alt="" className={styles.miniImg} />
                {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}> */}
                <div className={styles.insideCaraouselItem}>

                  <div className={styles.heading}>FILE MANAGER</div>
                  <div className={styles.para}>Easily organize and manage all of your important documents with our file manager feature.</div>
                </div>
              </div>
              </div>
            </Carousel.Item>

            {/* <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}> */}
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207467%20%282%29.png" alt="Second slide" className={styles.imgg} />
              </div>

              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                {/* <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group1.svg" alt="" style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.5rem", marginBottom: "1rem" }} /> */}
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group1.svg" alt="" className={styles.miniImg} />

                {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}> */}
                <div className={styles.insideCaraouselItem}>

                  <div className={styles.heading}>TASK MANAGEMENT</div>
                  <div className={styles.para}>Optimize resource usage and ensures that projects are completed efficiently and on time.</div>
                </div>
              </div>
              </div>
            </Carousel.Item>
            {/* <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}> */}
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207462%20%283%29.png" alt="Third slide" className={styles.imgg} />
              </div>

              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem" }}>
                {/* <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/CurrencyDollarSimple.svg"
                  alt=""
                  style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.2rem", marginBottom: "1rem" }}
                /> */}
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/CurrencyDollarSimple.svg"
                  alt="" className={styles.miniImg}
                />
                {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}> */}
                <div className={styles.insideCaraouselItem}>

                  <div className={styles.heading}>Quotation</div>
                  <div className={styles.para}>Save time and improves the credibility of the interior designer by ensuring that the quotes are error-free and tailored to the client's needs.</div>
                </div>
              </div>
              </div>
            </Carousel.Item>

            {/* <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}> */}
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207464%20%283%29.svg" alt="Third slide" className={styles.imgg} />
              </div>

              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                {/* <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/LineSegments.svg" alt="" style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.5rem", marginBottom: "1rem" }} /> */}
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/LineSegments.svg" alt="" className={styles.miniImg} />

                {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}> */}
                <div className={styles.insideCaraouselItem}>

                  <div className={styles.heading}>Timeline</div>
                  <div className={styles.para}>Create project schedules and timelines & helps to keep projects on track and ensures that deadlines are met.</div>
                </div>
              </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* second div */}
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            > */}
            <div className={styles.personalDetailsDiv}>
              <div className={styles.personal_head}>Personal Details</div>
              <button className={styles.backBtn} onClick={() => navigateTo("/sign-up")}>
                <FaArrowLeft />
                Go Back
              </button>
              <div className={styles.skipShowHide}>
              <a href={`${base_url}/admin/`} className={styles.skip} style={{ textDecoration: "none" }}>
              Skip
            </a>
            </div>
            </div>

            {/* all inputs div */}
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              {/* <div style={{ display: "flex", gap: "1rem" }}> */}
              <div className={styles.nameWrapper} >
                <TextField
                  id="outlined-basic"
                  error={error.fname}
                  label="First Name"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="fname"
                  value={input.fname}
                  onChange={handleInput}
                  // style={{ width: "50%" }}
                  sx={{ width: "50%",  '@media (max-width: 600px)': { width: '100%', }}}
                  // style={{ borderColor: input.fname === "" ? "1px solid red" : "" }}
                  InputProps={{ style: { borderRadius: "12px" } }} 
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px",  fontSize:isMobile?"14px":"18px"}}}
                />

                <TextField
                  id="outlined-basic"
                  error={error.lName}
                  label="Last Name"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="lName"
                  value={input.lName}
                  onChange={handleInput}
                  // style={{ width: "50%" }}
                  sx={{ width: "50%",  '@media (max-width: 600px)': { width: '100%', }}}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                  InputProps={{ style: { borderRadius: "12px" } }}
                />
              </div>

              <div>
                <TextField
                  id="outlined-basic"
                  error={error.address}
                  label="Address"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="address"
                  value={input.address}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px",  fontSize:isMobile?"14px":"18px"}}}
                />
              </div>

              {/* <div style={{ display: "flex", gap: "1rem", position: "relative" }}> */}
              <div className={styles.cityContainer}>

                <TextField
                  id="outlined-basic"
                  error={error.city}
                  label="City"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="city"
                  value={input.city}
                  onChange={handleInput}
                  // style={{ borderRadius: "12px", width: "50%" }}
                  sx={{ width: "50%",  '@media (max-width: 600px)': { width: '100%', }}}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />

                <TextField
                  id="outlined-basic"
                  error={error.zipCode}
                  label="Zipcode"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="zipCode"
                  value={input.zipCode}
                  onChange={handleInput}
                  // style={{ width: "50%" }}
                  sx={{ width: "50%",  '@media (max-width: 600px)': { width: '100%', }}}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
                {/* <div style={{ position: "absolute", height: "3rem", width: "2rem", right: "1.2%", top: "3%", background: "#ffffff" }}></div> */}
              </div>

              <div style={{ display: "flex", gap: "1rem" }}>
                <TextField
                  id="outlined-basic"
                  error={error.birth}
                  label="Date of Birth"
                  variant="outlined"
                  className="saas-form"
                  type="date"
                  name="birth"
                  value={input.birth}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
                {/* <DatePicker
                  value={input.birth}
                  onChange={() => handleInput()}
                  renderInput={(props) => <TextField {...props} label="Select Date" helperText="Date should be between min and max" />}
                  minDate={new Date("2023-01-01")}
                  maxDate={new Date("2023-12-31")}
                /> */}
              </div>

              <div>
                {/* <div className={error.number ? "flagPhoneNumberInputErr" : "flagPhoneNumberInput"}>
                  <PhoneInput placeholder="Enter phone number" value={input.number} onChange={(newVal) => handleInput({ target: { name: "number", value: newVal } })} />
                </div> */}
                <MuiPhoneNumber
                  fullWidth
                  variant="outlined"
                  label="Enter phone number"
                  value={input.number}
                  onChange={(newVal) => handleInput({ target: { name: "number", value: newVal } })}
                  defaultCountry={"in"}
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
                {/* <TextField
                  id="outlined-basic"
                  error={error.number}
                  label="Phone Number"
                  variant="outlined"
                  className="saas-form"
                  type="number"
                  name="number"
                  value={input.number}
                  onChange={handleInput}
                  style={{ width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                /> */}
              </div>
            </div>

            <div>
              <button
                className={styles.nextBtn}
                onClick={() => {
                  Object.keys(input).forEach((curElem) => {
                    if (input[curElem]) {
                      setError((prev) => {
                        return {
                          ...prev,
                          [curElem]: false,
                        };
                      });
                    } else {
                      setError((prev) => {
                        return {
                          ...prev,
                          [curElem]: true,
                        };
                      });
                    }
                  });
                  if (input.fname !== "" && input.lName !== "" && input.address !== "" && input.city !== "" && input.zipCode !== "" && input.birth !== "" && input.number !== "") {
                    upDateProfile();
                  }
                }}
              >
                Next
              </button>
            </div>
            {/* <div style={{marginBottom:isMobile?"10px":"0px"}}></div> */}
            <div className={styles.skipBtnInLast}>
            <a href={`${base_url}/admin/`} className={styles.skip} style={{ textDecoration: "none" }}>
              Skip Complete Profile
            </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPersonalData;
