import React, { useState } from 'react'
import DropdownComponent from '../DropdownComponent'
import styles from '../../styles/additionalInfo.module.css'
import data from './Data'

import { useDispatch, useSelector } from 'react-redux'
import { setotherDetails } from '../../../../../Redux/Slices/additionalDetailsSlice'

function OtherDetails() {
    const dispatch = useDispatch();
    const otherDetails = useSelector(state => state.additionalInfoSlice.otherDetails);

    const handleChange = (type, value) => {
        dispatch(setotherDetails({ ...otherDetails, [type]: value }))
    }

    return (
        <>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Whatsapp Group Created? `}</div>
                    <DropdownComponent
                        elementArr={data.OTHER_DETAILS.WHATSAPP_GROUP_CREATED}
                        selectedElement={otherDetails}
                        type="isWhatsappGroupCreated"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Floor Plan Recieved? `}</div>
                    <DropdownComponent
                        elementArr={data.OTHER_DETAILS.FLOOR_PLAN_RECEIVED}
                        selectedElement={otherDetails}
                        type="isFloorPlanReceived"
                    handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Site Visit Recieved? `}</div>
                    <DropdownComponent
                        elementArr={data.OTHER_DETAILS.SITE_VISITED}
                        selectedElement={otherDetails}
                        type="isSiteVisitReceived"
                    handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                >
                    <div
                        className={styles.filter_icon}

                    >{`Studio Visit `}</div>
                    <DropdownComponent
                        elementArr={data.OTHER_DETAILS.STUDIO_VISITED}
                        selectedElement={otherDetails}
                        type="isStudioVisit"
                    handleChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

export default OtherDetails