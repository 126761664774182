import React, { useContext, useEffect, useState } from "react";
import "../index.css";
import vector1 from "../images/Vector.png";
import vector0 from "../images/Vector-4.png";
import vector2 from "../images/Vector-1.png";
import vector3 from "../images/Vector-2.png";
import vector4 from "../images/Vector-3.png";
import designquo from "../images/designquo.svg";
import executionquo from "../images/executionquo.svg";
import Remark from "./Remark";
import DateTime from "./DateTime";
import desquoContext from "../../../picontext/DashBoard/desquoContext";
import SendMail from "./SendMail";
import { getReq, postReq } from "../../../piservices/apis";
import expf, { apiLinks, base_url } from "../../../piservices/constants";
import { BiChevronDown } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  formateDate,
  valueToLeadStatus,
  valueToCallRemarks,
} from "../../../piservices/compCommon";
import PiAlert from "../../PiAlert/PiAlert";
import { BsCheck2, BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { HiOutlineClipboardList } from "react-icons/hi";
import { motion } from "framer-motion";
import { getToken } from "../../../piservices/authService";
import { Button, Modal, Tooltip, notification } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { selectedLeadData } from "../../../Redux/Slices/specificleadSlice";
import LockWhite from '../../../Assets/LockWhite.svg'
import LockBlack from '../../../Assets/LockBlack.svg'
import LmsMail from "../LmsMail.js/LmsMail";
import { setLeadsCount, setShowEmailModal } from "../../../Redux/Slices/lmsSlice";
import useApiFetchCustomHook from "../../../piservices/ApiFetchCustomHook";

const LeadUpdate = () => {
  const context = useContext(desquoContext);
  let { tab } = useParams();
  let isLead = window.location.href?.includes('lead-details');
  const panelUpdated = useSelector(state => state.lmsSlice.panelUpdated)
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState(tab)
  const [quotationModal, setQuotationModal] = useState(false);
  const [currentQuot, setCurrentQuot] = useState("");
  const [quotationModal2, setQuotationModal2] = useState(false);
  const [panelupdate, setPanelUpdate] = useState()
  const {apiFetch} =  useApiFetchCustomHook()
  const [buttonClickedOnce, setButtonClickedOnce] = useState(false)
const [remarkText, setRemarkText] = useState("")

  const [quotInput, setQuotInput] = useState("");
  const userData = useSelector((state) => state.lmsSlice.userData);
  useEffect(() => {
    setPanelUpdate(panelUpdated)
  }, [panelUpdated])

  useEffect(() => setCurrentTab(tab), [tab])
  const handleQuotChange = (e) => {
    setQuotInput(e.target.value);
  };
  const noEmailRedirect = () => { };
  const handleOk = () => {
    setQuotationModal(true);
  };
  const handleCloseModal = () => {
    setQuotationModal(false);
  };
  const handleOk2 = () => {
    setQuotationModal2(true);
  };
  const handleOk22 = async () => {
    if (quotInput != "") {
      const res2 = await postReq(
        `${expf.proapi}/user/edit-lead?id=${activeLead?._id}`,
        {
          // leadId: activeLead?._id,
          email: quotInput,
        }
      );
      if (res2 && !res2.error) {
        const res = await postReq(`${expf.pmtapi}/api/projects/addClient?userId=${localStorage.getItem(
          "userId"
        )}`, {
          email: quotInput,
          location: activeLead.address,
          name: activeLead.name,
          projectName: activeLead.name,
        });
        if (res && !res.error) {
          console.log(res);
        } else {
          console.log(res.error);
        }
        await axios
          .post(`${expf.pmtapi}/api/projects/add-project`, {
            clientId: res.data._id,
            location: activeLead.address,
            projectName: activeLead.name,
            userId: localStorage.getItem("userId"),
            isConfirmed: false,
            isFromLead: true,
            projectAdmin: activeLead?.assignedTo?._id,
            teamMembers: [activeLead?.assignedTo?._id]
          })
          .then(function (response) {
            localStorage.setItem("projectId", response?.data?._id);
            if (currentQuot == "exQuot") {
              localStorage.setItem("projectId", response?.data?._id);
              setQuotInput("");
              setCurrentQuot("");
              localStorage.setItem("leadQuo", true);
              window.location.assign(
                `${base_url}/quotation/new-quotation/${response.data._id}`
              );
            } else {
              localStorage.setItem("projectId", response?.data?._id);
              setQuotInput("");
              setCurrentQuot("");
              window.location.assign(
                `${base_url}/quotation/enterprise-quotation/${response.data._id}`
              );
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        console.log(res2.error);
      }
    }
  };
  const handleCloseModal2 = () => {
    setQuotationModal2(false);
  };
  const {
    activeLead,
    leadstatus,
    setLeadstatus,
    getActive,
    mailStatus,
    isnewlead,
    dateTime,
    leads,
    setLeads,
    mailForm,
    myLeadStatus,
    setMyLeadStatus,
    showAlert,
    setMailStatus,
    setActiveLeadState,
    setDateTime, 
  } = context;


  const {
    name,
    renovationTimeline,
    phoneNumber,
    email,
    updatedAt,
    leadStatus,
    assignedTo,
  } = activeLead;

  const [viewremark, setViewremark] = useState(false);
  const [showUpdateBadge, setShowUpdateBadge] = useState(false);
  const [actualLeadStatus, setActualLeadStatus] = useState("");
  const [actualLeadCallStatus, setActualLeadCallStatus] = useState('');
  const [actualLeadStatus2, setActualLeadStatus2] = useState(1);
  const [actualLeadStatus3, setActualLeadStatus3] = useState(1);
  const [actualCallType, setActualCallType] = useState();
  const [originalCallStatus, setOriginalCallStatus] = useState();
  const [callStatus2, setCallStatus2] = useState();

  useEffect(() => {
    if (Object.keys(activeLead)?.length > 0) {
      setActualLeadStatus(activeLead.leadStatus);
      setActualLeadStatus2(activeLead.leadStatus);
      setActualLeadStatus3(activeLead.leadStatus);
      // setActualCallType(activeLead?.todayRemark || 0);
      setCallStatus2(getActive);
      setOriginalCallStatus(leadstatus[getActive]?.value);
      setActualLeadCallStatus('')
    }
  }, [activeLead]);

  const [teamLeadArr, setTeamLeadArr] = useState([]);
  const getTeamLeads = async () => {
    const res = await getReq(
      `${expf.proapi}/api/enterprise/get-team-leaders?userId=${localStorage.getItem(
        "userId"
      )}&access=1`
    );
    if (res && !res.error) {
      setTeamLeadArr(res.data.data);
    } else {
      console.log(res.error);
    }
  };


  const createProjectAndGoToDesignQuotation = async () => {
    if (activeLead?.projectId) {
      localStorage.setItem("projectId", activeLead?.projectId);
      localStorage.setItem("leadQuo", true);
      window.location.assign(
        `${base_url}/quotation/enterprise-quotation/${activeLead?.projectId}`
      );
    } else {

      if (activeLead.email) {
        try {
          await axios
            .post(`${expf.pmtapi}/api/projects/addClient?userId=${localStorage.getItem(
              "userId"
            )}`, {
              email: activeLead.email,
              location: activeLead.address,
              name: activeLead.name,
              projectName: activeLead.name,
            })
            .then(async (res) => {
              await axios
                .post(
                  `${expf.pmtapi
                  }/api/projects/add-project?userId=${localStorage.getItem(
                    "userId"
                  )}`,
                  {
                    clientId: res.data._id,
                    location: activeLead.address,
                    projectName: activeLead.name,
                    userId: localStorage.getItem("userId"),
                    isConfirmed: false,
                    phoneNumber: activeLead.phoneNumber,
                    clientName: activeLead.name,
                    isFromLead: true,
                    projectAdmin: activeLead?.assignedTo?._id,
                    teamMembers: [activeLead?.assignedTo?._id]
                  }
                )
                .then(async (response) => {
                  // await setActiveLeadState({...activeLead, projectId: response?.data?._id});

                  localStorage.setItem("projectId", response?.data?._id);
                  localStorage.setItem("leadQuo", true);
                  await postReq(
                    `${apiLinks.crm}/api/save-projectId-leads?leadId=${activeLead?._id}&projectId=${response?.data?._id}`
                  )
                    .then((res2) => {
                      apiFetch(tab);
                      window.location.assign(
                        `${base_url}/quotation/enterprise-quotation/${response?.data?._id}`
                      );
                    })
                    .catch((err) => {
                      console.log(err.error);
                      notification.error({
                        message: err.error || "client already exist",
                        duration: 1,
                      });
                    });
                })
                .catch((e) => {
                  console.log(e);
                  notification.error({
                    message: e.error || "project already exists",
                    duration: 1,
                  });
                })
                .catch((err) => {
                  notification.error({
                    message: err.error || "client already exists",
                    duration: 1,
                  });
                });
            });
        } catch (e) {
          console.log(e);
          notification.error({
            message: e.error || "client already exists",
            duration: 1,
          });
        }

      } else {
        setCurrentQuot("desQuot");
        handleCloseModal();
        handleOk2();
      }
    }

  };
  const createProjectAndGoToQuotation = async () => {
    if (activeLead?.projectId) {
      localStorage.setItem("projectId", activeLead?.projectId);
      localStorage.setItem("leadQuo", true);
      window.open(
        `${base_url}/quotation/new-quotation/${activeLead?.projectId}`, '_blank'
      );
    } else {
      if (activeLead.email) {
        try {
          await axios
            .post(`${expf.pmtapi}/api/projects/addClient?userId=${localStorage.getItem(
              "userId"
            )}`, {
              email: activeLead.email,
              location: activeLead.address,
              name: activeLead.name,
              projectName: activeLead.name,
            })
            .then(async (res) => {
              await axios
                .post(
                  `${expf.pmtapi
                  }/api/projects/add-project?userId=${localStorage.getItem(
                    "userId"
                  )}`,
                  {
                    clientId: res.data._id,
                    location: activeLead.address,
                    projectName: activeLead.name,
                    userId: localStorage.getItem("userId"),
                    isConfirmed: false,
                    phoneNumber: activeLead.phoneNumber,
                    clientName: activeLead.name,
                    isFromLead: true,
                    projectAdmin: activeLead?.assignedTo?._id,
                    teamMembers: [activeLead?.assignedTo?._id]
                  }
                )
                .then(async (response) => {

                  localStorage.setItem("projectId", response?.data?._id);
                  localStorage.setItem("leadQuo", true);
                  await setActiveLeadState({ ...activeLead, projectId: response?.data?._id });
                  await postReq(
                    `${apiLinks.crm}/api/save-projectId-leads?leadId=${activeLead?._id}&projectId=${response?.data?._id}`
                  )
                    .then((res2) => {
                      apiFetch(tab);
                      window.open(
                        `${base_url}/quotation/new-quotation/${response.data._id}`, '_blank'
                      );
                    })
                    .catch((err) => {
                      console.log(err.error);
                      notification.error({
                        message: err.error || "client already exist",
                        duration: 1,
                      });
                    });
                })
                .catch((e) => {
                  console.log(e);
                  notification.error({
                    message: e.error || "project already exists",
                    duration: 1,
                  });
                })
                .catch((err) => {
                  notification.error({
                    message: err.error || "client already exists",
                    duration: 1,
                  });
                });
            });
        } catch (e) {
          console.log(e);
          notification.error({
            message: e.error || "client already exists",
            duration: 1,
          });
        }

      } else {
        setCurrentQuot("desQuot");
        handleCloseModal();
        handleOk2();
      }
    }

  };

  const assignLeadToTL = async (tlId) => {
    const res = await postReq(`${expf.proapi}/user/newleadActions`, {
      leadId: activeLead?._id,
      assignedTo: tlId,
    });
    if (res && !res.error) {
    } else {
      console.log(res.error);
    }
  };

  const getLeadsCount = async () => {
    let countObj={
    }
    const res = await getReq(
      expf.dev + "/user/newLeadCount?pageNo=0" + (tab > 0 ? "&type=" + tab : "")
    );
    if (res && !res.error) {
      countObj.all = res?.data?.data?.totalCount
      countObj.interested = res?.data?.data?.interestedLeadsCount
      countObj.followUp = res?.data?.data?.followUpLeadsCount
      countObj.noResponse = res?.data?.data?.noResponseLeadsCount
      countObj.notInterested = res?.data?.data?.notInterestedLeadsCount
      countObj.unassigned = res?.data?.data?.unAssignedLeadsCount
      countObj.won = res?.data?.data?.wonLeadsCount
      countObj.inActiveCount=res?.data?.data?.inActiveCount
      dispatch(setLeadsCount({...countObj}))
    }else{
      console.error("Something went wrong")
    }
  }
  const isUpdateHappened =
  remarkText?.length > 0 ||
    actualLeadStatus3 !== actualLeadStatus2 ||
    // leadstatus[getActive]?.value !== originalCallStatus ||
    actualLeadCallStatus !== '' ||
    dateTime[getActive]?.updateDate;

  const updateLeadStatus = async (val) => {
    const res = await postReq(
      `${expf.proapi}/user/edit-lead?id=${activeLead?._id
      }&userId=${localStorage.getItem("userId")}`,
      {
        // leadId: activeLead?._id,
        leadStatus: val,
      }
    );
    if (res && !res.error) {
      notification.success({
        message: res?.message || "Lead Status Updated Successfully",
        duration: 1,
      });
      apiFetch(tab);
    } else {
      console.log(res.error);
      notification.error({
        message: res?.message || "Oops Something Went Wrong",
        duration: 1,
      });
    }
  };

  const handleSubmit = async () => {
    console.log(
      "datetimeee",
      dateTime[getActive],
      leadstatus[getActive]?.value
    );
    if (actualLeadCallStatus === '') {
      if (actualLeadStatus3 !== actualLeadStatus2) {
        updateLeadStatus(actualLeadStatus3)
      } else {
        alert('Please select a call status');
      }

    }
    else {
      if(remarkText == undefined){
      // if(!Boolean(dateTime[getActive])){
        alert('Please enter Remarks');
      }
      else if (
        dateTime[getActive]?.updateDate == undefined &&
        leadstatus[getActive]?.value == 2
      ) {
        alert("Please select Date");
      } else {
        try {
          if (getActive === mailStatus?.of && mailStatus?.send) {
            const res0 = await postReq(expf?.dev + "/user/lead-email", {
              to: activeLead?.email,
              // "replyTo": "testing4200@gmmail.com",
              subject: mailForm?.subject,
              emailBody: mailForm?.newText2,
            });

            if (res0) {
              console.log(res0);
            }
          }
          let conditionalLeadStatus = actualLeadStatus3;

          //condition will only execute if there is a change in call status
          if (isnewlead) {
            if (actualLeadCallStatus !== 6) {
              conditionalLeadStatus = actualLeadCallStatus;
            } else {
            }
          }

          if (actualLeadCallStatus !== originalCallStatus) {
            if (actualLeadStatus3 === 2) {
              if (actualLeadCallStatus === 3) {
                conditionalLeadStatus = 3;
              } else if (
                actualLeadCallStatus === 6 ||
                actualLeadCallStatus === 2
              ) {
                conditionalLeadStatus = actualLeadStatus3;
              } else if (actualLeadCallStatus === 5) {
                conditionalLeadStatus = 5;
              } else if (actualLeadCallStatus === 7) {
                conditionalLeadStatus = 3;
              } else {
                alert(
                  "Please select valid combination of lead status and call status"
                );
              }
            } else if (actualLeadStatus3 === 3) {
              if (
                actualLeadCallStatus === 3 ||
                actualLeadCallStatus === 2 ||
                actualLeadCallStatus === 6 ||
                actualLeadCallStatus == 7
              ) {
                conditionalLeadStatus = actualLeadStatus3;
              } else if (actualLeadCallStatus === 5) {
                conditionalLeadStatus = 5;
              } else {
                alert(
                  "Please select valid combination of lead status and call status"
                );
              }
            } else if (actualLeadStatus3 === 5) {
              if (actualLeadCallStatus === 5 || actualLeadCallStatus === 6) {
                conditionalLeadStatus = actualLeadStatus3;
              } else if (
                actualLeadCallStatus === 7 ||
                actualLeadCallStatus === 3
              ) {
                conditionalLeadStatus = 3;
              } else if (actualLeadCallStatus === 2) {
                conditionalLeadStatus = 2;
              } else {
                alert(
                  "Please select valid combination of lead status and call status"
                );
              }
            } else if (actualLeadStatus3 === 6) {
              conditionalLeadStatus = actualLeadCallStatus;
            }
          }

          if (!leadstatus[myLeadStatus]?.value) {
            showAlert("Select A Lead Status To Submit !", "Okay");
            return;
          }
          setButtonClickedOnce(true);
          const res = await postReq(expf?.dev + "/user/newleadActions", {
            leadId: activeLead?._id,
            leadStatus: conditionalLeadStatus,
            // callStatus: actualLeadCallStatus,
            todayRemark: Number(actualCallType),
            leadUpdates: [
              {
                isEmailSend:
                  getActive === mailStatus?.of && mailStatus?.send
                    ? true
                    : false,
                callLeadStatus: actualLeadCallStatus,
                text: remarkText,
                updateDate: dateTime[getActive]?.updateDate || null,
                updateTime: dateTime[getActive]?.updateTime || "10:00",
              },
            ],
          });
          if (res && !res?.error) {
            setButtonClickedOnce(false);
            let x = leads;
            x.data[activeLead?.idx] = res?.data?.data;
            await setActiveLeadState({
              ...res?.data?.data,
              assignedTo: assignedTo,
              idx: activeLead?.idx,
            });
            apiFetch(tab);
            getLeadsCount();
            // notification.success({
            //   message: res?.message || "Lead Updated Successfully",
            //   duration: 2,
            // });

            if (remarkText) {
              await axios
                .post(`${apiLinks.crm}/user/save-lead-interaction`, {
                  leadId: activeLead?._id,
                  meetingMode: valueToCallRemarks[actualCallType],
                  outcome: leadstatus[getActive]?.label,
                  meetingRemarks: remarkText,
                  userId: localStorage.getItem("userId"),
                })
                .then(() => console.log("success"))
                .catch(() => console.log("something error"));
            }
            if (isLead) {
              dispatch(
                selectedLeadData({
                  ...res?.data?.data,
                  assignedTo: assignedTo,
                  idx: activeLead?.idx,
                })
              );
            }
            await setLeads({ ...x });
            if (leadstatus[getActive]?.value !== 3) {
              !leadstatus[getActive]?.addEmail
                ? showAlert(
                    "You have successfully updated your lead status ",
                    "Okay"
                  )
                : getActive === mailStatus?.of && mailStatus?.send
                ? showAlert("You have successfully sent the email", "Okay")
                : activeLead?.dontShowAgain === false &&
                  showAlert(
                    "You have successfully updated your lead status <br><br> Want to send a reference email? ",
                    "Yes",
                    true,
                    () => {
                      // console.log(isChecked)
                      // updateShowAgain();
                      document.getElementById("opensendMail")?.click();
                      setMailStatus({ of: getActive });
                    }
                  );
            } else {
              showAlert(
                "You have successfully updated your lead status ",
                "Okay"
              );
              setRemarkText("")
            }
          }
        } catch (error) {
          setButtonClickedOnce(false);
          console.log(error);
        }
      }
    }

  };

  setTimeout(() => {
    setShowUpdateBadge(false);
  }, 1000);

  useEffect(() => {
    getTeamLeads();
  }, []);

  return (
    <>
      <Modal
        // title={false}
        open={quotationModal}
        onOk={handleOk}
        onCancel={handleCloseModal}
        centered
        footer={null}
        // closable={false}
        title="Select the quotation"
        // className="ant-modal-centered"
      >
        <div className="d-flex mt-2">
          <div
            onClick={createProjectAndGoToDesignQuotation}
            className="d-flex flex-column align-items-center justify-content-center  py-5"
            style={{
              background: "#FFFFFF",
              border: "1px solid #DFDFDF",
              borderRadius: "8px",
              width: "50%",
              cursor: "pointer",
            }}
          >
            <img
              src={designquo}
              style={{ height: "2.75rem", width: "2.75rem" }}
            />
            <div
              className="mt-4"
              style={{
                color: "#444444",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Design Quotation
            </div>
          </div>
          <div
            onClick={createProjectAndGoToQuotation}
            className="d-flex flex-column align-items-center justify-content-center  py-5 ms-4"
            style={{
              background: "#FFFFFF",
              border: "1px solid #DFDFDF",
              borderRadius: "8px",
              width: "50%",
              cursor: "pointer",
            }}
          >
            <img
              src={executionquo}
              style={{ height: "2.75rem", width: "2.75rem" }}
            />
            <div
              className="mt-4"
              style={{
                color: "#444444",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Execution Quotation
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        // title={false}
        open={quotationModal2}
        onOk={handleOk2}
        onCancel={handleCloseModal2}
        centered
        title={
          currentQuot === "exQuot"
            ? `Send Execution Quotation`
            : `Send Design Quotation`
        }
        footer={
          <div>
            <Button onClick={handleCloseModal2}>Cancel</Button>
            <Button
              style={{
                background: "rgb(23,78,134)",
                color: "white",
                borderRadius: "0.25rem",
                fontSize: "13px",
              }}
              onClick={handleOk22}
            >
              Send
            </Button>
          </div>
        }
        closable={false}
        // className="ant-modal-centered"
      >
        <div className="d-flex mt-2">
          <input
            placeholder="Enter Lead Email"
            name="quotInput"
            value={quotInput}
            onChange={handleQuotChange}
            className="quoEmail"
          />
        </div>
      </Modal>

      <div className="br-5 mv-br-0 mv-vh-50 " style={{ width: "100%" }}>
        <div
          className="pt-1 pt-md-2 px-2 px-md-2 py-2"
          style={{
            background: "linear-gradient(90deg, #0084EF 0%, #0063B2 100%)",
            color: "#ffffff",
          }}
        >
          <div className="d-flex justify-content-between mb-2 align-items-center">
            <div className="d-flex align-items-center w-100 justify-content-between mt-2">
              <h6 className="fw-bold mb-0">{name}</h6>
              <div>
                <div className="d-flex flex-column">
                  <div
                    // class="btn btn-primary"
                    // href="#"
                    // role="button"
                    id="dropdownMenuLink"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      padding: "0",
                      backgroundColor: "#00000000",
                      display: "flex",
                      alignItems: "center",
                      zIndex: "1000",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Assigned to: &nbsp;
                  </div>
                  <div
                    // class="btn btn-primary"
                    // href="#"
                    // role="button"
                    id="dropdownMenuLink"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      padding: "0",
                      backgroundColor: "#00000000",
                      display: "flex",
                      alignItems: "center",
                      zIndex: "1000",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {assignedTo ? assignedTo?.fullName : "Not Assigned yet"}
                  </div>

                  {/* <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  {teamLeadArr &&
                    teamLeadArr.map((curElem) => {
                      if (curElem._id !== localStorage.getItem("userId")) {
                        return (
                          <li>
                            <a class="dropdown-item px-2" href="#" onClick={() => assignLeadToTL(curElem._id)}>
                              {curElem.fullName}
                            </a>
                          </li>
                        );
                      }
                    })}
                </ul> */}
                </div>
              </div>
              {leadStatus !== 1 && (
                <p
                  className="position-md-absolute br-0 mb-0 ms-2 ms-md-0"
                  style={{
                    top: "-3px",
                    right: "-2px",
                  }}
                >
                  <motion.span
                    initial={showUpdateBadge ? { opacity: 1 } : { opacity: 0 }}
                    animate={showUpdateBadge ? { opacity: 1 } : { opacity: 0 }}
                    transition={
                      showUpdateBadge ? { duration: 1 } : { duration: 3 }
                    }
                    className="badge badge-primary fw-normal p-2 mv-rounded-pill"
                  >
                    Updated on {formateDate(new Date())?.slice(0, -5)}
                  </motion.span>
                </p>
              )}
              {renovationTimeline && (
                <button
                  type="button"
                  className="btn btn-outline-light btn-rounded border btn-sm py-0 px-1 ms-3"
                  data-mdb-ripple-color="dark"
                >
                  {renovationTimeline}
                </button>
              )}
            </div>
            <div className="d-md-none">
              <button
                type="button"
                className="btn btn-outline-dark btn-floating btn-close"
                data-mdb-dismiss="modal"
                aria-label="Close"
                style={{
                  width: 20,
                  height: 20,
                }}
              ></button>
            </div>
            {/* <div class="dropdown d-flex align-items-center">
            <button style={{ boxShadow: "none", color: "#ffffff" }} class="btn dropdown-toggle p-0" type="button" id="dropdownMenuButton" data-mdb-toggle="dropdown" aria-expanded="false">
              Dropdown button
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <a class="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>
          </div> */}
          </div>
          {phoneNumber && (
            <div className="d-flex align-items-center">
              {/* <img src={vector4} alt="" width={12} height={12} className="" /> */}
              <BsTelephone fontSize={12} />
              <span
                style={{ color: "#ffffff" }}
                className="pfs-12 mv-pfs-14 ms-2"
              >
                {phoneNumber}
              </span>
            </div>
          )}
          {email && (
            <div className="d-flex align-items-center">
              {/* <img src={vector0} alt="" width={12} height={12} className="" /> */}
              <AiOutlineMail />
              <span
                style={{ color: "#ffffff" }}
                className="pfs-12 mv-pfs-14 ms-2"
              >
                {email}
              </span>
            </div>
          )}
          <div>
            <div className="d-flex  mt-2  ">
              {email ? (
                <button
                  className="btn btn-outline-base-secondary border-none px-0 d-flex align-items-center cl-base-blue me-4"
                  data-mdb-toggle="modal"
                  data-mdb-target="#leadmail"
                  id="opensendMail"
                  disabled={!userData?.rolesAssigned?.crm?.edit}
                  onClick={() => {
                    dispatch(setShowEmailModal(true));
                    setMailStatus();
                  }}
                >
                  {/* <img src={vector1} alt="" width={14} height={14} className="" /> */}
                  <AiOutlineMail fontSize={18} color="#ffffff" />
                  <span
                    style={{ color: "#ffffff" }}
                    className="pfs-12 mv-pfs-14 ms-2"
                  >
                    Send mail
                  </span>
                  {!userData?.rolesAssigned?.crm?.edit && (
                    <Tooltip title="Access Disabled by the Admin">
                      <span>
                        <img src={LockWhite} alt="LockImg"></img>
                      </span>
                    </Tooltip>
                  )}
                </button>
              ) : (
                <button
                  style={{ pointerEvents: "none" }}
                  className="btn btn-outline-base-secondary border-none px-0 d-flex align-items-center cl-base-blue me-4"
                  // data-mdb-toggle="modal"
                  // data-mdb-target="#leadmail"
                  disabled={!userData?.rolesAssigned?.crm?.edit}
                >
                  {/* <img src={vector1} alt="" width={14} height={14} className="" /> */}
                  <AiOutlineMail fontSize={18} color="#ffffff7a" />
                  <span
                    style={{ color: "rgb(255 255 255 / 48%)" }}
                    className="pfs-12 mv-pfs-14 ms-2"
                  >
                    Send mail
                  </span>
                </button>
              )}
              <button
                // data-mdb-toggle="modal"
                // data-mdb-target="#addQuotationModal"

                role="button"
                onClick={createProjectAndGoToQuotation}
                disabled={!userData?.rolesAssigned?.quotation?.edit}
                className="btn btn-outline-base-secondary border-none px-0 d-flex align-items-center cl-base-blue me-4"
              >
                {/* <img src={vector2} alt="" width={14} height={14} className="" /> */}
                <HiOutlineClipboardList color="#ffffff" fontSize={18} />
                <span
                  style={{ color: "#ffffff" }}
                  className="pfs-12 mv-pfs-14 mx-1"
                >
                  Send a quotation
                </span>
                {!userData?.rolesAssigned?.quotation?.edit && (
                  <Tooltip title="Access Disabled by the Admin">
                    <span>
                      <img src={LockWhite} alt="LockImg"></img>
                    </span>
                  </Tooltip>
                )}
              </button>
              {/* <button className="btn btn-outline-base-secondary border-none px-0 d-flex align-items-center cl-base-blue">
              <img src={vector3} alt="" width={14} height={14} className="" />
              <span style={{ color: "#ffffff" }} className="pfs-12 mv-pfs-14 ms-2">
                Chat
              </span>
            </button> */}
            </div>
          </div>
        </div>
        <div className="px-2 pb-2 bg-white ">
          {isnewlead ? (
            <div className="mt-2">
              <p className="fw-bold d-flex mb-0">
                <div>
                  {`How was the ${valueToCallRemarks[actualCallType]}`}?
                </div>
                <div
                  className=" dropdown-toggle pfs-12 mv-pfs-14"
                  type="button"
                  id="dropdownMenuButton"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  <BiChevronDown size={25} className="mx-0" />
                </div>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {valueToCallRemarks &&
                    Object.keys(valueToCallRemarks)?.map((e, i) => {
                      return (
                        <li key={i}>
                          <a
                            className="dropdown-item pfs-14"
                            href="#!"
                            onClick={async () => {
                              setActualCallType(e);
                            }}
                          >
                            {valueToCallRemarks[e]}
                          </a>
                        </li>
                      );
                    })}
                </ul>
                <span className="cl-grey1 fw-normal">(Lead Status)</span>{" "}
              </p>
              <div className="d-flex flex-wrap pe-2  ">
                {Object.keys(leadstatus)?.map((e, i) => {
                  return (
                    e !== "meeting_schedule" &&
                    (activeLead.leadStatus == 5 ||
                      (activeLead.leadStatus !== 5 && e !== "no_response")) && (
                      // leadstatus[e].active !== true &&
                      <button
                        key={i}
                        type="button"
                        className={
                          leadstatus[e].clicked !== true
                            ? "btn  btn-outline-light border cl-black px-2 fw-normal me-2 mt-2 py-1"
                            : "btn  btn-secondary outline-base-blue px-2  fw-normal me-2 mt-2 py-1"
                        }
                        data-mdb-ripple-color="dark"
                        onClick={async () => {
                          setActualLeadStatus(leadstatus[e]?.value);
                          setActualLeadCallStatus(leadstatus[e]?.value);
                          // setMyLeadStatus(e);
                          getActive
                            ? await setLeadstatus({
                                ...leadstatus,
                                [getActive]: {
                                  ...leadstatus[getActive],
                                  active: false,
                                  clicked: false,
                                },
                                [e]: {
                                  ...leadstatus[e],
                                  active: true,
                                  clicked: true,
                                },
                              })
                            : await setLeadstatus({
                                ...leadstatus,
                                [e]: {
                                  ...leadstatus[e],
                                  active: true,
                                  clicked: false,
                                },
                              });
                        }}
                      >
                        {leadstatus[e]?.label}
                      </button>
                    )
                  );
                })}

                {/* {getActive && (
                  <div className="w-100">
                    <button
                      type="button"
                      className="btn  btn-secondary outline-base-blue px-2  fw-normal me-2 mt-2 py-1"
                      data-mdb-ripple-color="dark"
                      onClick={() => {
                        setLeadstatus({
                          ...leadstatus,
                          [getActive]: {
                            ...leadstatus[getActive],
                            active: false,
                          },
                        });
                        setActualLeadStatus(leadstatus[getActive].value);
                        setMyLeadStatus();
                      }}
                    >
                      {leadstatus[getActive]?.label}
                    </button>
                  </div>
                )} */}
              </div>
              <hr />
            </div>
          ) : (
            <div className="pt-2 pb-2">
              <div className="d-flex align-items-center w-100 pfs-14">
                <div>Lead Status</div>
                <div className="dropdown ms-3 border br-5 px-3 py-1 px-md-2 e bg-grey1">
                  <div
                    className=" dropdown-toggle pfs-12 mv-pfs-14"
                    type="button"
                    id="dropdownMenuButton"
                    data-mdb-toggle="dropdown"
                    style={{
                      cursor: userData?.rolesAssigned?.crm?.edit
                        ? "pointer"
                        : "default",
                    }}
                    aria-expanded="false"
                    disabled={!userData?.rolesAssigned?.crm?.edit}
                  >
                    {actualLeadStatus3 != 1
                      ? actualLeadStatus3 == 2
                        ? "Follow Up"
                        : actualLeadStatus3 == 3
                        ? "Active"
                        : actualLeadStatus3 == 5
                        ? "On Hold"
                        : actualLeadStatus3 == 6
                        ? "Re Activation"
                        : actualLeadStatus3 == 7
                        ? "Meeting Scheduled"
                        : actualLeadStatus3 == 10
                        ? "Inactive"
                        : "Select"
                      : "Select"}
                    <BiChevronDown className="mx-0" />
                    {!userData?.rolesAssigned?.crm?.edit && (
                      <Tooltip title="Access Disabled by the Admin">
                        <span>
                          <img src={LockBlack} alt="LockImg"></img>
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <ul
                    className="dropdown-menu leadStatushovercontaineer"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {leadstatus &&
                      Object.keys(leadstatus)?.map((e, i) => {
                        return (
                          !leadstatus[e].notvisible && (
                            <li key={i}>
                              <a
                                className="dropdown-item pfs-14 leadStatushover"
                                href="#!"
                                onClick={async () => {
                                  // getActive
                                  //     ? await setLeadstatus({
                                  //         ...leadstatus,
                                  //         [getActive]: {
                                  //             ...leadstatus[getActive],
                                  //             active: false,
                                  //         },
                                  //         [e]: { ...leadstatus[e], active: true },
                                  //     })
                                  //     : await setLeadstatus({
                                  //         ...leadstatus,
                                  //         [e]: { ...leadstatus[e], active: true },
                                  //     });
                                  setActualLeadStatus3(leadstatus[e]?.value);
                                  setMyLeadStatus(e);
                                }}
                              >
                                {leadstatus[e]?.label}
                              </a>
                            </li>
                          )
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          )}
          {!isnewlead && (
            <div className="d-flex w-100 justify-content-between align-items-center">
              <div className="pfs-14 d-flex fw-bold align-items-center">
                <div>
                  {`Today’s ${valueToCallRemarks[actualCallType]} Remark`}{" "}
                </div>{" "}
                <div className="dropdown br-5 py-1 px-md-2 e">
                  <div
                    className=" dropdown-toggle pfs-12 mv-pfs-14"
                    type="button"
                    id="dropdownMenuButton"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <BiChevronDown size={25} className="mx-0" />
                  </div>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {valueToCallRemarks &&
                      Object.keys(valueToCallRemarks)?.map((e, i) => {
                        return (
                          <li key={i}>
                            <a
                              className="dropdown-item pfs-14"
                              href="#!"
                              onClick={async () => {
                                // setActualLeadStatus(leadstatus[e]?.value);
                                // setMyLeadStatus(e);
                                // alert("ll");

                                setActualCallType(e);
                              }}
                            >
                              {valueToCallRemarks[e]}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>

              <div>
                <p
                  className="mb-0 pfs-12 cl-base-blue cursor-pointer"
                  onClick={() => {
                    setViewremark(!viewremark);
                  }}
                >
                  {!viewremark ? "View last remark" : "Hide remarks"}
                </p>
              </div>
            </div>
          )}
          {!viewremark ? (
            <div
              className={`${!isnewlead ? "br-5 px-1 py-1" : ""}`}
              style={{
                background: !isnewlead && "#FFFFFF",
              }}
            >
              {!isnewlead && (
                <div className="">
                  {leadstatus &&
                    Object.keys(leadstatus)?.map((e, i) => {
                      return (
                        !leadstatus[e].isNewOnly &&
                        e !== "meeting_schedule" &&
                        (activeLead.leadStatus == 5 ||
                          (activeLead.leadStatus !== 5 &&
                            e !== "no_response")) && (
                          <button
                            key={i}
                            type="button"
                            className={`btn 
                                border px-3 px-md-2 fw-normal me-2 py-2 py-md-2 mt-2 mt-md-1 removeShoadow `}
                            style={{
                              fontSize:
                                window.screen.width < 1400 ? "11px" : "12px",
                              // background
                              // :
                              //   leadstatus[e]?.clicked === true
                              //     ? 'blue'
                              //     : "#f2f2f2",
                              color:
                                leadstatus[e]?.value === actualLeadCallStatus
                                  ? "#0084EF"
                                  : "#000000",
                            }}
                            data-mdb-ripple-color="dark"
                            disabled={!userData?.rolesAssigned?.crm?.edit}
                            onClick={() => {
                              console.log("ee==>",e,leadstatus[e]?.value,actualLeadCallStatus)
                              setActualLeadCallStatus(leadstatus[e]?.value);
                              setActualLeadStatus3(leadstatus[e]?.value);
                              // setMyLeadStatus(e);
                              setActualCallType(leadstatus[e]?.value)    
                              getActive
                                ?  setLeadstatus({
                                    ...leadstatus,
                                    [getActive]: {
                                      ...leadstatus[getActive],
                                      active: false,
                                    },
                                    [e]: { ...leadstatus[e], active: true },
                                  })
                                : setLeadstatus({
                                    ...leadstatus,
                                    [e]: { ...leadstatus[e], active: true },
                                  });
                            }}
                          >
                            {leadstatus[e]?.label}
                          </button>
                        )
                      );
                    })}
                  <hr />
                </div>
              )}
              {actualLeadCallStatus === 7 && (
                <SendMail
                  text={`Send a thank you email to ${activeLead?.name} for their time!`}
                />
              )}
              {actualLeadCallStatus === 6 && (
                <SendMail
                  text={`Send a quick email to ${activeLead?.name} for a callback`}
                />
              )}
              {console.log(getActive, "hhhh")}
              {(getActive === "followUp" ||
                getActive === "not_interested" ||
                getActive === "no_response" ||
                getActive === "interested" ||
                getActive === "meeting_schedule" ||
                getActive === "in_active") &&
                (mailStatus?.of !== getActive || !mailStatus.send ? (
                  <div>
                    {actualLeadCallStatus === 2 && <DateTime />}
                    <Remark setRemarkText={setRemarkText} remarkText={remarkText}/>
                  </div>
                ) : mailStatus?.of === "followUp" ? (
                  <SendMail
                    text={`Send a quick email to ${activeLead?.name} for a follow up`}
                  />
                ) : mailStatus?.of === "interested" ? (
                  <SendMail
                    text={`Send a quick email to ${activeLead?.name} to show your interest`}
                  />
                ) : (
                  mailStatus?.of === "meeting_schedule" && (
                    <SendMail
                      text={`Send a quick email to ${activeLead?.name} to know that a meet is scheduled`}
                    />
                  )
                ))}
            </div>
          ) : (
            <div
              className={`border br-5 px-2 py-1 overflow-auto scb-none pt-3`}
              style={{
                background: "#FAFAFA",
                height: "290px",
              }}
            >
              {activeLead?.leadUpdates
                ?.map((e, i) => {
                  return (
                    (activeLead?.leadUpdates[i]?.text?.length > 0 ||
                      activeLead?.leadUpdates[i]?.isEmailSend) &&
                    (i < activeLead?.leadUpdates?.length - 1
                      ? formateDate(activeLead?.leadUpdates[i]?.createdDate) ===
                          formateDate(
                            activeLead?.leadUpdates[i + 1]?.createdDate
                          ) &&
                        activeLead?.leadUpdates[i]?.text ===
                          activeLead?.leadUpdates[i + 1]?.text &&
                        activeLead?.leadUpdates[i]?.callLeadStatus ===
                          activeLead?.leadUpdates[i + 1]?.callLeadStatus
                        ? activeLead?.leadUpdates[i]?.isEmailSend ===
                          activeLead?.leadUpdates[i + 1]?.isEmailSend
                        : true
                      : true) && (
                      <div key={i}>
                        <div className="d-flex w-100 ">
                          <div
                            style={{
                              width: "150px",
                            }}
                          >
                            <p className="mb-1 pfs-12 ">
                              {
                                leadstatus[valueToLeadStatus[e?.callLeadStatus]]
                                  ?.label
                              }
                            </p>
                            <p className="mb-1 cl-grey1 pfs-12 ">
                              {e?.createdDate && formateDate(e?.createdDate)}
                            </p>
                          </div>
                          <div className="w-75">
                            {e?.isEmailSend && (
                              <button className="btn btn-secondary py-0 px-2 mb-1">
                                Email sent
                              </button>
                            )}
                            <p className="pfs-12 mb-0 text-break">{e?.text} </p>
                          </div>
                        </div>
                        <hr className="my-2" />
                      </div>
                    )
                  );
                })
                .reverse()}
            </div>
          )}

          {/* <div className="d-md-none">
            <br />
            <br />
          </div> */}
          {!viewremark && (
            <div className="d-flex w-100 justify-content-end mv-position-fixed bottom-0 end-0 pe-md-0 pb-md-0">
              <button
                className="btn btn-primary bg-base-blue box-shadow-none py-3 py-md-2 mv-pfs-14"
                onClick={() => {
                  if(mailStatus?.of !== getActive || !mailStatus.send){
                    setDateTime({
                      ...dateTime,
                      [getActive]: { ...dateTime[getActive], text: remarkText},
                    });
                  }
                  
                  setShowUpdateBadge(true);
                  handleSubmit();
                }}
                disabled={!isUpdateHappened || buttonClickedOnce}
                style={{
                  borderRadius: "8px",
                  background: isUpdateHappened ? "#0084EF" : "#DDD",
                }}
              >
                {isnewlead ? "Submit" : "Update"}
              </button>
            </div>
          )}
          <PiAlert />
        </div>
      </div>
    </>
  );
};

export default LeadUpdate;
