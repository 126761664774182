import React, { useEffect, useState } from "react";
import DonutChart from "./DonutChart";
import Card from "react-bootstrap/Card";
import styles from "./Analytic.module.css";
import BarChart from "./BarChart";
import BarChart2 from "./BarChart2";
import { getReq } from "../../../piservices/apis";
import { getToken } from "../../../piservices/authService";
import { apiLinks } from "../../../piservices/constants";

const months = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export const getDateString = (Date) => {
  let month = Date.getMonth() + 1;
  let dt = Date.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (dt < 10) {
    dt = "0" + dt;
  }
  return `${Date.getFullYear()}-${month}-${dt}`;
};

const Analytic = ({
  selectedPeriod,
  value,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
}) => {
  const [analyticsData, setAnalyticsData] = useState([]);
  const [leadStatusObj, setLeadStatusObj] = useState({
    FOLLOW_UP: 0,
    INTERESTED: 0,
    NOT_INTERESTED: 0,
    NO_RESPONSE: 0,
    NEW_LEADS: 0,
  });

  // ************  week Data
  const [weekDataInterested, setWeekDataInterested] = useState();
  const [weekDataContacted, setWeekDataContacted] = useState();

  const [weekDataWon, setWeekDataWon] = useState();

  const [weekTotalResponse, setWeekTotalResponse] = useState();

  //   ********** Month Data
  const [monthDataInterested, setMonthDataInterested] = useState();
  const [monthDataContacted, setMonthDataContacted] = useState();
  const [monthDataWon, setMonthDataWon] = useState();
  const [monthDataTotalResponse, setMonthDataTotalResponse] = useState();

  //  *********** quarter wise
  const [quarterDataInterested, setQuarterDataInterested] = useState();

  const [quarterDataContacted, setQuarterDataContacted] = useState();

  const [quarterDataWon, setQuarterDataWon] = useState();

  const [quarterDataTotalResponse, setQuarterDataTotalResponse] = useState();

  //   ********** Year Data
  const [yearDataInterested, setYearDataInterested] = useState();
  const [yearDataContacted, setYearDataContacted] = useState();
  const [yearDataWon, setYearDataWon] = useState();
  const [yearDataTotalResponse, setYearDataTotalResponse] = useState();

  // ************* custom Data for days selected more than 15
  const [customDataInterested, setCustomDataInterested] = useState([
    ["Name", "Intersted"],
    ["Week 1", 0],
    ["Week 2", 0],
    ["Week 3", 0],
    ["Week 4", 0],
    ["Week 5", 0],
    ["Week 6", 0],
    ["Week 7", 0],
    ["Week 8", 0],
    ["Week 9", 0],
    ["Week 10", 0],
    ["Week 11", 0],
    ["Week 12", 0],
  ]);
  const [customDataContacted, setCustomDataContacted] = useState([
    ["Name", "Contacted"],
    ["Week 1", 0],
    ["Week 2", 0],
    ["Week 3", 0],
    ["Week 4", 0],
    ["Week 5", 0],
    ["Week 6", 0],
    ["Week 7", 0],
    ["Week 8", 0],
    ["Week 9", 0],
    ["Week 10", 0],
    ["Week 11", 0],
    ["Week 12", 0],
  ]);
  const [customDataWon, setCustomDataWon] = useState([
    ["Name", "Won"],
    ["Week 1", 0],
    ["Week 2", 0],
    ["Week 3", 0],
    ["Week 4", 0],
    ["Week 5", 0],
    ["Week 6", 0],
    ["Week 7", 0],
    ["Week 8", 0],
    ["Week 9", 0],
    ["Week 10", 0],
    ["Week 11", 0],
    ["Week 12", 0],
  ]);
  const [customDataTotalResponse, setCustomDataTotalResponse] = useState([
    ["Name", "Total Response"],
    ["Week 1", 0],
    ["Week 2", 0],
    ["Week 3", 0],
    ["Week 4", 0],
    ["Week 5", 0],
    ["Week 6", 0],
    ["Week 7", 0],
    ["Week 8", 0],
    ["Week 9", 0],
    ["Week 10", 0],
    ["Week 11", 0],
    ["Week 12", 0],
  ]);

  // ************* custom Data1 for days selected less or equal to 15
  const [customDataInterested1, setCustomDataInterested1] = useState([
    ["Name", "Intersted"],
    ["day 1", 0],
    ["day 2", 0],
    ["day 3", 0],
    ["day 4", 0],
    ["day 5", 0],
    ["day 6", 0],
    ["day 7", 0],
    ["day 8", 0],
    ["day 9", 0],
    ["day 10", 0],
    ["day 11", 0],
    ["day 12", 0],
    ["day 13", 0],
    ["day 14", 0],
    ["day 15", 0],
  ]);
  const [customDataContacted1, setCustomDataContacted1] = useState([
    ["Name", "Contacted"],
    ["day 1", 0],
    ["day 2", 0],
    ["day 3", 0],
    ["day 4", 0],
    ["day 5", 0],
    ["day 6", 0],
    ["day 7", 0],
    ["day 8", 0],
    ["day 9", 0],
    ["day 10", 0],
    ["day 11", 0],
    ["day 12", 0],
    ["day 13", 0],
    ["day 14", 0],
    ["day 15", 0],
  ]);
  const [customDataWon1, setCustomDataWon1] = useState([
    ["Name", "Won"],
    ["day 1", 0],
    ["day 2", 0],
    ["day 3", 0],
    ["day 4", 0],
    ["day 5", 0],
    ["day 6", 0],
    ["day 7", 0],
    ["day 8", 0],
    ["day 9", 0],
    ["day 10", 0],
    ["day 11", 0],
    ["day 12", 0],
    ["day 13", 0],
    ["day 14", 0],
    ["day 15", 0],
  ]);
  const [customDataTotalResponse1, setCustomDataTotalResponse1] = useState([
    ["Name", "Total Response"],
    ["day 1", 0],
    ["day 2", 0],
    ["day 3", 0],
    ["day 4", 0],
    ["day 5", 0],
    ["day 6", 0],
    ["day 7", 0],
    ["day 8", 0],
    ["day 9", 0],
    ["day 10", 0],
    ["day 11", 0],
    ["day 12", 0],
    ["day 13", 0],
    ["day 14", 0],
    ["day 15", 0],
  ]);

  useEffect(() => {
    getAnalyticsData();
  }, []);

  useEffect(() => {
    getAnalyticsData();
  }, [selectedPeriod, value]);

  //   returning date string
  function addOneDay(date) {
    date.setDate(date.getDate() + 1);
    return date;
  }
  function getDaysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
  }
  const getAnalyticsData = async () => {
    let todayDate = getDateString(new Date());
    if (selectedPeriod === "this week") {
      const currentDate = new Date();
      const newDate = getDateString(addOneDay(currentDate));
      const currentDayOfWeek = currentDate.getDay();
      const offset = currentDayOfWeek > 0 ? -currentDayOfWeek + 1 : -7;
      currentDate.setDate(currentDate.getDate() + offset);
      let firstDateWeek = getDateString(currentDate);
      setStartDate(firstDateWeek);
      setEndDate(todayDate);
      const res = await getReq(
        `${apiLinks.crm}/user/newLeadsData?pageNo=0&startDate=${firstDateWeek}&endDate=${newDate}`,
        {},
        { Authorization: `Bearer ${getToken()}` }
      );
      setAnalyticsData(res?.data?.data?.data);
      getDataWeek(res?.data?.data?.data);
      setupLeadStatusObj(res?.data?.data?.data);
    } else if (selectedPeriod === "this month") {
      const newDate = addOneDay(new Date());
      let firstDateMonth = new Date();
      firstDateMonth.setDate(1);
      firstDateMonth = getDateString(firstDateMonth);
      setStartDate(firstDateMonth);
      setEndDate(todayDate);
      const res = await getReq(
        `${apiLinks.crm}/user/newLeadsData?pageNo=0&startDate=${firstDateMonth}&endDate=${newDate}`,
        {},
        { Authorization: `Bearer ${getToken()}` }
      );
      setAnalyticsData(res?.data?.data?.data);
      getDataMonth(res?.data?.data?.data);
      setupLeadStatusObj(res?.data?.data?.data);
    } else if (selectedPeriod === "this year") {
      const newDate = addOneDay(new Date());
      let firstDateYear = new Date();
      firstDateYear.setDate(1);
      firstDateYear.setMonth(0);
      firstDateYear = getDateString(firstDateYear);
      setStartDate(firstDateYear);
      setEndDate(todayDate);
      const res = await getReq(
        `${
          apiLinks.crm
        }/user/newleads?pageNo=0&startDate=${firstDateYear}&endDate=${getDateString(
          newDate
        )}`,
        {},
        { Authorization: `Bearer ${getToken()}` }
      );
      setAnalyticsData(res?.data?.data?.data);
      getDataYear(res?.data?.data?.data);
      setupLeadStatusObj(res?.data?.data?.data);
    } else if (selectedPeriod == "this quarter") {
      let quarterStartMonth = "";
      const newDate = addOneDay(new Date());
      let firstDateQuarter = new Date();
      let currMonth = firstDateQuarter.getMonth();
      if (currMonth + 1 == 1 || currMonth + 1 == 2 || currMonth + 1 == 3) {
        quarterStartMonth = 1;
      } else if (
        currMonth + 1 == 4 ||
        currMonth + 1 == 5 ||
        currMonth + 1 == 6
      ) {
        quarterStartMonth = 4;
      } else if (
        currMonth + 1 == 7 ||
        currMonth + 1 == 8 ||
        currMonth + 1 == 9
      ) {
        quarterStartMonth = 7;
      } else {
        quarterStartMonth = 10;
      }
      firstDateQuarter.setDate(1);
      firstDateQuarter.setMonth(quarterStartMonth - 1);
      firstDateQuarter = getDateString(firstDateQuarter);
      setStartDate(firstDateQuarter);
      setEndDate(todayDate);

      const res = await getReq(
        `${
          apiLinks.crm
        }/user/newleads?pageNo=0&startDate=${firstDateQuarter}&endDate=${getDateString(
          newDate
        )}`,
        {},
        { Authorization: `Bearer ${getToken()}` }
      );
      setAnalyticsData(res?.data?.data?.data);
      getDataQuarter(res?.data?.data?.data);
      setupLeadStatusObj(res?.data?.data?.data);
    } else if (selectedPeriod === "last week") {
      const currentDate = new Date();
      const currentDayOfWeek = currentDate.getDay();
      const offset = currentDayOfWeek > 0 ? -currentDayOfWeek + 1 : -7;
      currentDate.setDate(currentDate.getDate() + offset);
      currentDate.setDate(currentDate.getDate() - 1);
      let lastDateWeek = getDateString(currentDate);
      currentDate.setDate(currentDate.getDate() - 7);
      let firstDateWeek = getDateString(currentDate);
      setStartDate(firstDateWeek);
      setEndDate(lastDateWeek);
      const res = await getReq(
        `${apiLinks.crm}/user/newLeadsData?pageNo=0&startDate=${firstDateWeek}&endDate=${lastDateWeek}`,
        {},
        { Authorization: `Bearer ${getToken()}` }
      );
      setAnalyticsData(res?.data?.data?.data);
      getDataWeek(res?.data?.data?.data);
      setupLeadStatusObj(res?.data?.data?.data);
    } else if (selectedPeriod === "last month") {
      let firstDateMonth = new Date();
      let lastDateMonth = new Date();
      firstDateMonth.setDate(1);
      firstDateMonth.setMonth(firstDateMonth.getMonth() - 1);
      lastDateMonth.setMonth(lastDateMonth.getMonth() - 1);
      lastDateMonth.setDate(
        getDaysInMonth(
          lastDateMonth.getMonth() + 1,
          lastDateMonth.getFullYear()
        )
      );
      setStartDate(firstDateMonth);
      setEndDate(lastDateMonth);
      const res = await getReq(
        `${apiLinks.crm}/user/newleads?pageNo=0&startDate=${getDateString(
          firstDateMonth
        )}&endDate=${getDateString(lastDateMonth)}`,
        {},
        { Authorization: `Bearer ${getToken()}` }
      );
      setAnalyticsData(res?.data?.data?.data);
      getDataMonth(res?.data?.data?.data);
      setupLeadStatusObj(res?.data?.data?.data);
    } else if (selectedPeriod === "last quarter") {
      let quarterStartMonth = "";
      let firstDateQuarter = new Date();
      let currMonth = firstDateQuarter.getMonth();
      if (currMonth + 1 == 1 || currMonth + 1 == 2 || currMonth + 1 == 3) {
        quarterStartMonth = 1;
      } else if (
        currMonth + 1 == 4 ||
        currMonth + 1 == 5 ||
        currMonth + 1 == 6
      ) {
        quarterStartMonth = 1;
      } else if (
        currMonth + 1 == 7 ||
        currMonth + 1 == 8 ||
        currMonth + 1 == 9
      ) {
        quarterStartMonth = 4;
      } else {
        quarterStartMonth = 7;
      }
      firstDateQuarter.setDate(1);
      firstDateQuarter.setMonth(quarterStartMonth - 1);
      // firstDateQuarter.setDate(firstDateQuarter.getDate() - 1);
      let firstDateQuarterstr = getDateString(firstDateQuarter);
      let lastDatequarter= new Date()
      lastDatequarter.setMonth(quarterStartMonth+1)
      const noOfDays= getDaysInMonth(lastDatequarter.getMonth() ,lastDatequarter.getFullYear())
      lastDatequarter.setDate(noOfDays)
      let lastDateQuarterstr = getDateString(lastDatequarter)
      // firstDateQuarter.setDate(firstDateQuarter.getDate() - 89);
      let firstDateLastQuarter = getDateString(firstDateQuarter);
      setStartDate(firstDateQuarterstr);
      setEndDate(lastDateQuarterstr);
      const res = await getReq(
        `${apiLinks.crm}/user/newLeadsData?pageNo=0&startDate=${firstDateQuarterstr}&endDate=${lastDateQuarterstr}`,
        {},
        { Authorization: `Bearer ${getToken()}` }
      );
      setAnalyticsData(res?.data?.data?.data);
      getDataLastQuarter(res?.data?.data?.data);
      setupLeadStatusObj(res?.data?.data?.data);
    } else if (selectedPeriod === "last year") {
      let firstDateYear = new Date();
      firstDateYear.setDate(1);
      firstDateYear.setMonth(0);
      firstDateYear.setFullYear(firstDateYear.getFullYear() - 1);
      let firstDtLastYear = getDateString(firstDateYear);
      firstDateYear.setDate(firstDateYear.getDate() + 364);
      let lastDateLastYear = getDateString(firstDateYear);
      setStartDate(firstDtLastYear);
      setEndDate(lastDateLastYear);
      const res = await getReq(
        `${apiLinks.crm}/user/newLeadsData?pageNo=0&startDate=${firstDtLastYear}&endDate=${lastDateLastYear}`,
        {},
        { Authorization: `Bearer ${getToken()}` }
      );
      setAnalyticsData(res?.data?.data?.data);
      getDataYear(res?.data?.data?.data);
      setupLeadStatusObj(res?.data?.data?.data);
    } else if (selectedPeriod === "custom1") {
      const res = await getReq(
        `${
          apiLinks.crm
        }/user/leadsAnalytics?startDate=${startDate}&endDate=${endDate}&userId=${localStorage.getItem(
          "userId"
        )}`,
        {},
        { Authorization: `Bearer ${getToken()}` }
      );
      setAnalyticsData(res?.data?.data);
      getDataCustom1(res?.data?.data);
      setupLeadStatusObj(res?.data?.data);
    } else {
      const res = await getReq(
        `${
          apiLinks.crm
        }/user/leadsAnalytics?startDate=${startDate}&endDate=${endDate}&userId=${localStorage.getItem(
          "userId"
        )}`,
        {},
        { Authorization: `Bearer ${getToken()}` }
      );
      setAnalyticsData(res?.data?.data);
      getDataCustom(res?.data?.data);
      setupLeadStatusObj(res?.data?.data);
    }
  };

  // const getLatestDate = (leads) => {
  //   leads = leads.sort((a, b) => {
  //     return (
  //       new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
  //     );
  //   });

  //   let latestDate = new Date(leads[0]?.createdAt);
  //   let date = getDateString(latestDate);

  //   setLatestDate(date);
  // };

  const setupLeadStatusObj = (leads) => {
    let temp = {
      FOLLOW_UP: 0,
      INTERESTED: 0,
      NOT_INTERESTED: 0,
      NO_RESPONSE: 0,
      NEW_LEADS: 0,
    };
    leads?.forEach((o) => {
      if (o?.leadStatus === 2) {
        temp.FOLLOW_UP = temp?.FOLLOW_UP + 1;
      } else if (o?.leadStatus == 3) {
        temp.INTERESTED = temp?.INTERESTED + 1;
      } else if (o?.leadStatus == 5) {
        temp.NOT_INTERESTED = temp?.NOT_INTERESTED + 1;
      } else if (o?.leadStatus == 6) {
        temp.NO_RESPONSE = temp?.NO_RESPONSE + 1;
      } else if (o?.leadStatus == 1) {
        temp.NEW_LEADS = temp?.NEW_LEADS + 1;
      }
    });
    setLeadStatusObj(temp);
  };

  //   getting Data week wise
  const getDataWeek = (leads) => {
    let temp = [
      ["Name", "Interested"],
      ["Mon", 0],
      ["Tue", 0],
      ["Wed", 0],
      ["Thur", 0],
      ["Fri", 0],
      ["Sat", 0],
      ["Sun", 0],
    ];
    let tempCont = [
      ["Name", "Contacted"],
      ["Mon", 0],
      ["Tue", 0],
      ["Wed", 0],
      ["Thur", 0],
      ["Fri", 0],
      ["Sat", 0],
      ["Sun", 0],
    ];
    let tempWon = [
      ["Name", "Won"],
      ["Mon", 0],
      ["Tue", 0],
      ["Wed", 0],
      ["Thur", 0],
      ["Fri", 0],
      ["Sat", 0],
      ["Sun", 0],
    ];
    let tempTotal = [
      ["Name", "Total Response"],
      ["Mon", 0],
      ["Tue", 0],
      ["Wed", 0],
      ["Thur", 0],
      ["Fri", 0],
      ["Sat", 0],
      ["Sun", 0],
    ];
    leads.forEach((o) => {
      let date = new Date(o?.createdAt);
      const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
      let day = date.getDay();
      //   Interested
      if (o?.leadStatus == 3) {
        temp = temp.map((o) => {
          if (o[0] === dayNames[day]) {
            o[1] = o[1] + 1;
            return o;
          }
          return o;
        });
      }

      //   contacted
      if (o?.leadUpdates?.length > 0) {
        tempCont = tempCont.map((b) => {
          if (b[0] === dayNames[day]) {
            b[1] = b[1] + 1;
            return b;
          }
          return b;
        });
      }

      //   won cases
      if (o?.leadStatus == 7) {
        tempWon = tempWon.map((k) => {
          if (k[0] === dayNames[day]) {
            k[1] = k[1] + 1;
            return k;
          }
          return k;
        });
      }

      //   total response
      if (o?.leadStatus != 6) {
        tempTotal = tempTotal.map((j) => {
          if (j[0] === dayNames[day]) {
            j[1] = j[1] + 1;
            return j;
          }
          return j;
        });
      }
    });

    setWeekDataWon(tempWon);
    setWeekDataContacted(tempCont);
    setWeekDataInterested(temp);
    setWeekTotalResponse(tempTotal);
  };

  //   getting data month wise
  const getDataMonth = (leads) => {
    let temp = [
      ["Name", "Interested"],
      ["Week 1", 0],
      ["Week 2", 0],
      ["Week 3", 0],
      ["Week 4", 0],
    ];
    let tempCont = [
      ["Name", "Contacted"],
      ["Week 1", 0],
      ["Week 2", 0],
      ["Week 3", 0],
      ["Week 4", 0],
    ];
    let tempWon = [
      ["Name", "Won"],
      ["Week 1", 0],
      ["Week 2", 0],
      ["Week 3", 0],
      ["Week 4", 0],
    ];
    let tempTotal = [
      ["Name", "Total Response"],
      ["Week 1", 0],
      ["Week 2", 0],
      ["Week 3", 0],
      ["Week 4", 0],
    ];
    leads.forEach((o) => {
      let date = new Date(o?.createdAt);
      let startDate = 1;
      let dateSpecific = date.getDate();
      let rem = dateSpecific - startDate;
      // interested
      if (o?.leadStatus == 3) {
        if (dateSpecific <= 7) {
          temp[1][1] = temp[1][1] + 1;
        } else if (dateSpecific > 7 && dateSpecific <= 15) {
          temp[2][1] = temp[2][1] + 1;
        } else if (dateSpecific > 15 && dateSpecific <= 22) {
          temp[3][1] = temp[3][1] + 1;
        } else {
          temp[4][1] = temp[4][1] + 1;
        }
      }
      //contacted
      if (o?.leadUpdates?.length > 0) {
        if (dateSpecific <= 7) {
          tempCont[1][1] = tempCont[1][1] + 1;
        } else if (dateSpecific > 7 && dateSpecific <= 15) {
          tempCont[2][1] = tempCont[2][1] + 1;
        } else if (dateSpecific > 15 && dateSpecific <= 22) {
          tempCont[3][1] = tempCont[3][1] + 1;
        } else {
          tempCont[4][1] = tempCont[4][1] + 1;
        }
      }
      //won
      if (o?.isConvertedToProject) {
        if (dateSpecific <= 7) {
          tempWon[1][1] = tempWon[1][1] + 1;
        } else if (dateSpecific > 7 && dateSpecific <= 15) {
          tempWon[2][1] = tempWon[2][1] + 1;
        } else if (dateSpecific > 15 && dateSpecific <= 22) {
          tempWon[3][1] = tempWon[3][1] + 1;
        } else {
          tempWon[4][1] = tempWon[4][1] + 1;
        }
      }

      // total response

      if (o?.leadStatus != 6) {
        if (dateSpecific <= 7) {
          tempTotal[1][1] = tempTotal[1][1] + 1;
        } else if (dateSpecific > 7 && dateSpecific <= 15) {
          tempTotal[2][1] = tempTotal[2][1] + 1;
        } else if (dateSpecific > 15 && dateSpecific <= 22) {
          tempTotal[3][1] = tempTotal[3][1] + 1;
        } else {
          tempTotal[4][1] = tempTotal[4][1] + 1;
        }
      }
    });
    setMonthDataContacted(tempCont);
    setMonthDataInterested(temp);
    setMonthDataWon(tempWon);
    setMonthDataTotalResponse(tempTotal);
  };

  //   getting data quarter wise
  const getDataQuarter = (leads) => {
    const q1 = [0, 1, 2];
    const q2 = [3, 4, 5];
    const q3 = [6, 7, 8];
    const q4 = [9, 10, 11];
    const date = new Date();
    const currentQuarter = q1.includes(date.getMonth())
      ? q1
      : q2.includes(date.getMonth())
      ? q2
      : q3.includes(date.getMonth())
      ? q3
      : q4;

    let temp = [["Name", "Interested"]];
    let tempCont = [["Name", "Contacted"]];
    let tempWon = [["Name", "Won"]];
    let tempTotal = [["Name", "Total Response"]];

    currentQuarter.map((item, i) => {
      tempTotal.push([months[item], 0]);
      tempCont.push([months[item], 0]);
      tempWon.push([months[item], 0]);
      temp.push([months[item], 0]);
    });
    leads.forEach((o) => {
      let date = new Date(o?.createdAt);
      // Get the month names using the options
      const monthName = date.toLocaleString("default", { month: "short" });
      // Interested
      if (o?.leadStatus == 3) {
        temp = temp.map((o) => {
          if (o[0] == monthName) {
            o[1] = o[1] + 1;
            return o;
          }
          return o;
        });
      }
      if (o?.leadUpdates?.length > 0) {
        tempCont = tempCont.map((b) => {
          if (b[0] == monthName) {
            b[1] = b[1] + 1;
            return b;
          }
          return b;
        });
      }
      //converted to project
      if (o?.isConvertedToProject) {
        tempWon = tempWon.map((k) => {
          if (k[0] == monthName) {
            k[1] = k[1] + 1;
            return k;
          }
          return k;
        });
      }
      //
      if (o?.leadStatus != 6) {
        tempTotal = tempTotal.map((j) => {
          if (j[0] == monthName) {
            j[1] = j[1] + 1;
            return j;
          }
          return j;
        });
      }
    });

    // ****Filtering only quarter months which are having leads data
    // temp = temp.filter((o) => o[1] > 0);
    // tempCont = tempCont.filter((o) => o[1] > 0);
    // tempWon = tempWon.filter((o) => o[1] > 0);
    // tempTotal = tempTotal.filter((o) => o[1] > 0);

    // temp.unshift(["Name", "Leads"]);
    // tempCont.unshift(["Name", "Leads"]);
    // tempWon.unshift(["Name", "Leads"]);
    // tempTotal.unshift(["Name", "Leads"]);

    setQuarterDataContacted(tempCont);
    setQuarterDataInterested(temp);
    setQuarterDataWon(tempWon);
    setQuarterDataTotalResponse(tempTotal);
  };
  const getDataLastQuarter = (leads) => {
    const q1 = [0,1,2]
    const q2 = [3,4,5]
    const q3 = [6,7,8]
    const q4 = [9,10,11]
    const date  = new Date();
    const currentQuarter = q1.includes(date.getMonth())?q1:q2.includes(date.getMonth())?q2:q3.includes(date.getMonth())?q3:q4;
    let lastQuarter=''
    if(currentQuarter==q1){
      lastQuarter =[...q1]
    }else if(currentQuarter==q2){
      lastQuarter =[...q1]
    }else if(currentQuarter==q3){
      lastQuarter =[...q2]
    }else{
      lastQuarter =[...q3]
    }
   
      let temp = [
        ["Name", "Interested"],
      ];
      let tempCont = [
        ["Name", "Contacted"],
      ];
      let tempWon = [
        ["Name", "Won"],
      ];
      let tempTotal = [
        ["Name", "Total Response"],
      ];
      lastQuarter.map((item,i)=>{
        tempTotal.push([months[item],0]);
        tempCont.push([months[item],0]);
        tempWon.push([months[item],0]);
        temp.push([months[item],0]);
      })
      leads.forEach((o) => {
        let date = new Date(o?.createdAt);
        // Get the month names using the options
        const monthName = date.toLocaleString("default", { month: "short" });
        // Interested
        if (o?.leadStatus == 3) {
          temp = temp.map((o) => {
            if (o[0] == monthName) {
              o[1] = o[1] + 1;
              return o;
            }
            return o;
          });
        }
        if (o?.leadUpdates?.length > 0) {
          tempCont = tempCont.map((b) => {
            if (b[0] == monthName) {
              b[1] = b[1] + 1;
              return b;
            }
            return b;
          });
        }
        //converted to project
        if (o?.isConvertedToProject) {
          tempWon = tempWon.map((k) => {
            if (k[0] == monthName) {
              k[1] = k[1] + 1;
              return k;
            }
            return k;
          });
        }
        //
        if (o?.leadStatus != 6) {
          tempTotal = tempTotal.map((j) => {
            if (j[0] == monthName) {
              j[1] = j[1] + 1;
              return j;
            }
            return j;
          });
        }
      });
  
  
      setQuarterDataContacted(tempCont);
      setQuarterDataInterested(temp);
      setQuarterDataWon(tempWon);
      setQuarterDataTotalResponse(tempTotal);
    };
  //   getting data year wise
  const getDataYear = (leads) => {
    let temp = [
      ["Name", "Interested"],
      ["Jan", 0],
      ["Feb", 0],
      ["Mar", 0],
      ["Apr", 0],
      ["May", 0],
      ["Jun", 0],
      ["Jul", 0],
      ["Aug", 0],
      ["Sep", 0],
      ["Oct", 0],
      ["Nov", 0],
      ["Dec", 0],
    ];
    let tempCont = [
      ["Name", "Contacted"],
      ["Jan", 0],
      ["Feb", 0],
      ["Mar", 0],
      ["Apr", 0],
      ["May", 0],
      ["Jun", 0],
      ["Jul", 0],
      ["Aug", 0],
      ["Sep", 0],
      ["Oct", 0],
      ["Nov", 0],
      ["Dec", 0],
    ];
    let tempWon = [
      ["Name", "Won"],
      ["Jan", 0],
      ["Feb", 0],
      ["Mar", 0],
      ["Apr", 0],
      ["May", 0],
      ["Jun", 0],
      ["Jul", 0],
      ["Aug", 0],
      ["Sep", 0],
      ["Oct", 0],
      ["Nov", 0],
      ["Dec", 0],
    ];
    let tempTotal = [
      ["Name", "Total Response"],
      ["Jan", 0],
      ["Feb", 0],
      ["Mar", 0],
      ["Apr", 0],
      ["May", 0],
      ["Jun", 0],
      ["Jul", 0],
      ["Aug", 0],
      ["Sep", 0],
      ["Oct", 0],
      ["Nov", 0],
      ["Dec", 0],
    ];
    leads.forEach((o) => {
      let date = new Date(o?.createdAt);

      // Get the month names using the options
      const monthName = date.toLocaleString("default", { month: "short" });
      if (o?.leadStatus == 3) {
        temp = temp.map((o) => {
          if (o[0] == monthName) {
            o[1] = o[1] + 1;
            return o;
          }
          return o;
        });
      }
      if (o?.leadUpdates?.length > 0) {
        tempCont = tempCont.map((b) => {
          if (b[0] == monthName) {
            b[1] = b[1] + 1;
            return b;
          }
          return b;
        });
      }
      if (o?.leadStatus == 7) {
        tempWon = tempWon.map((k) => {
          if (k[0] == monthName) {
            k[1] = k[1] + 1;
            return k;
          }
          return k;
        });
      }
      if (o?.leadStatus != 6) {
        tempTotal = tempTotal.map((j) => {
          if (j[0] == monthName) {
            j[1] = j[1] + 1;
            return j;
          }
          return j;
        });
      }
    });

    let dt = new Date();
    let currMonth = dt.getMonth() + 1;
    temp = temp.splice(0, currMonth + 1);
    tempCont = tempCont.splice(0, currMonth + 1);
    tempWon = tempWon.splice(0, currMonth + 1);
    tempTotal = tempTotal.splice(0, currMonth + 1);

    setYearDataContacted(tempCont);
    setYearDataInterested(temp);
    setYearDataWon(tempWon);
    setYearDataTotalResponse(tempTotal);
  };

  // getting custom data for less than or equal to 15 days
  const getDataCustom1 = (leads) => {
    let temp = [...customDataInterested1];
    let tempCont = [...customDataContacted1];
    let tempWon = [...customDataWon1];
    let tempTotal = [...customDataTotalResponse1];
    leads.forEach((o) => {
      let date = new Date(o?.createdAt);
      let stDate = new Date(startDate);
      stDate = stDate.getDate();
      let dateSpecific = date.getDate();
      let rem = dateSpecific - stDate;
      // interested
      if (o?.leadStatus == 3) {
        temp[rem + 1][1] = temp[rem + 1][1] + 1;
      }

      // contacted
      if (o?.leadUpdates?.length > 0) {
        tempCont[rem + 1][1] = tempCont[rem + 1][1] + 1;
      }

      // won
      if (o?.leadStatus == 7) {
        tempWon[rem + 1][1] = tempWon[rem + 1][1] + 1;
      }
      // total response
      if (o?.leadStatus != 6) {
        tempTotal[rem + 1][1] = tempTotal[rem + 1][1] + 1;
      }
    });

    setCustomDataContacted1(tempCont);
    setCustomDataInterested1(temp);
    setCustomDataWon1(tempWon);
    setCustomDataTotalResponse1(tempTotal);
  };

  // getting data custom
  const getDataCustom = (leads) => {
    let temp = [...customDataInterested];
    let tempCont = [...customDataContacted];
    let tempWon = [...customDataWon];
    let tempTotal = [...customDataTotalResponse];
    leads.forEach((o) => {
      let date = new Date(o?.createdAt);
      let stDate = new Date(startDate);
      stDate = stDate.getDate();
      let dateSpecific = date.getDate();
      let rem = dateSpecific - stDate;
      // interested
      if (o?.leadStatus == 3) {
        if (rem < 7) {
          temp[1][1] = temp[1][1] + 1;
        } else if (rem >= 7 && rem <= 14) {
          temp[2][1] = temp[2][1] + 1;
        } else if (rem > 14 && rem <= 21) {
          temp[3][1] = temp[3][1] + 1;
        } else if (rem > 21 && rem <= 28) {
          temp[4][1] = temp[4][1] + 1;
        } else if (rem > 28 && rem <= 35) {
          temp[5][1] = temp[5][1] + 1;
        } else if (rem > 35 && rem <= 42) {
          temp[6][1] = temp[6][1] + 1;
        } else if (rem > 42 && rem <= 49) {
          temp[7][1] = temp[7][1] + 1;
        } else if (rem > 49 && rem <= 56) {
          temp[8][1] = temp[8][1] + 1;
        } else if (rem > 56 && rem <= 63) {
          temp[9][1] = temp[9][1] + 1;
        } else if (rem > 63 && rem <= 70) {
          temp[10][1] = temp[10][1] + 1;
        } else if (rem > 70 && rem <= 77) {
          temp[11][1] = temp[11][1] + 1;
        } else {
          temp[12][1] = temp[12][1] + 1;
        }
      }

      // contacted
      if (o?.leadUpdates?.length > 0) {
        if (rem <= 7) {
          tempCont[1][1] = tempCont[1][1] + 1;
        } else if (rem > 7 && rem <= 14) {
          tempCont[2][1] = tempCont[2][1] + 1;
        } else if (rem > 14 && rem <= 21) {
          tempCont[3][1] = tempCont[3][1] + 1;
        } else if (rem > 21 && rem <= 28) {
          tempCont[4][1] = tempCont[4][1] + 1;
        } else if (rem > 28 && rem <= 35) {
          tempCont[5][1] = tempCont[5][1] + 1;
        } else if (rem > 35 && rem <= 42) {
          tempCont[6][1] = tempCont[6][1] + 1;
        } else if (rem > 42 && rem <= 49) {
          tempCont[7][1] = tempCont[7][1] + 1;
        } else if (rem > 49 && rem <= 56) {
          tempCont[8][1] = tempCont[8][1] + 1;
        } else if (rem > 56 && rem <= 63) {
          tempCont[9][1] = tempCont[9][1] + 1;
        } else if (rem > 63 && rem <= 70) {
          tempCont[10][1] = tempCont[10][1] + 1;
        } else if (rem > 70 && rem <= 77) {
          tempCont[11][1] = tempCont[11][1] + 1;
        } else {
          tempCont[12][1] = tempCont[12][1] + 1;
        }
      }

      // won
      if (o?.leadStatus == 7) {
        if (rem < 7) {
          tempWon[1][1] = tempWon[1][1] + 1;
        } else if (rem >= 7 && rem <= 14) {
          tempWon[2][1] = tempWon[2][1] + 1;
        } else if (rem > 14 && rem <= 21) {
          tempWon[3][1] = tempWon[3][1] + 1;
        } else if (rem > 21 && rem <= 28) {
          tempWon[4][1] = tempWon[4][1] + 1;
        } else if (rem > 28 && rem <= 35) {
          tempWon[5][1] = tempWon[5][1] + 1;
        } else if (rem > 35 && rem <= 42) {
          tempWon[6][1] = tempWon[6][1] + 1;
        } else if (rem > 42 && rem <= 49) {
          tempWon[7][1] = tempWon[7][1] + 1;
        } else if (rem > 49 && rem <= 56) {
          tempWon[8][1] = tempWon[8][1] + 1;
        } else if (rem > 56 && rem <= 63) {
          tempWon[9][1] = tempWon[9][1] + 1;
        } else if (rem > 63 && rem <= 70) {
          tempWon[10][1] = tempWon[10][1] + 1;
        } else if (rem > 70 && rem <= 77) {
          tempWon[11][1] = tempWon[11][1] + 1;
        } else {
          tempWon[12][1] = tempWon[12][1] + 1;
        }
      }
      // total response
      if (o?.leadStatus != 6) {
        if (rem < 7) {
          tempTotal[1][1] = tempTotal[1][1] + 1;
        } else if (rem >= 7 && rem <= 14) {
          tempTotal[2][1] = tempTotal[2][1] + 1;
        } else if (rem > 14 && rem <= 21) {
          tempTotal[3][1] = tempTotal[3][1] + 1;
        } else if (rem > 21 && rem <= 28) {
          tempTotal[4][1] = tempTotal[4][1] + 1;
        } else if (rem > 28 && rem <= 35) {
          tempTotal[5][1] = tempTotal[5][1] + 1;
        } else if (rem > 35 && rem <= 42) {
          tempTotal[6][1] = tempTotal[6][1] + 1;
        } else if (rem > 42 && rem <= 49) {
          tempTotal[7][1] = tempTotal[7][1] + 1;
        } else if (rem > 49 && rem <= 56) {
          tempTotal[8][1] = tempTotal[8][1] + 1;
        } else if (rem > 56 && rem <= 63) {
          tempTotal[9][1] = tempTotal[9][1] + 1;
        } else if (rem > 63 && rem <= 70) {
          tempTotal[10][1] = tempTotal[10][1] + 1;
        } else if (rem > 70 && rem <= 77) {
          tempTotal[11][1] = tempTotal[11][1] + 1;
        } else {
          tempTotal[12][1] = tempTotal[12][1] + 1;
        }
      }
    });
    temp = temp.filter((o) => {
      if (o[0] !== "Name") {
        return o[1] > 0;
      }
    });
    tempCont = tempCont.filter((o) => {
      if (o[0] !== "Name") {
        return o[1] > 0;
      }
    });
    tempWon = tempWon.filter((o) => {
      if (o[0] !== "Name") {
        return o[1] > 0;
      }
    });
    tempTotal = tempTotal.filter((o) => {
      if (o[0] !== "Name") {
        return o[1] > 0;
      }
    });

    temp.unshift(["Name", "Interested"]);
    tempCont.unshift(["Name", "Contacted"]);
    tempWon.unshift(["Name", "Won"]);
    tempTotal.unshift(["Name", "Total Response"]);
    setCustomDataContacted(tempCont);
    setCustomDataInterested(temp);
    setCustomDataWon(tempWon);
    setCustomDataTotalResponse(tempTotal);
  };

  const data = {
    datasets: [
      {
        data: [
          leadStatusObj?.FOLLOW_UP,
          leadStatusObj?.INTERESTED,
          leadStatusObj?.NOT_INTERESTED,
          leadStatusObj?.NEW_LEADS,
          leadStatusObj?.NO_RESPONSE,
        ],
        backgroundColor: [
          "#71A5DE",
          "#26AD74",
          "#EF233C",
          "#0084EF",
          "#F5B82E",
        ],
      },
    ],
  };

  const diffdata = {
    old:
      selectedPeriod == "this week" || selectedPeriod == "last week"
        ? weekDataContacted
        : selectedPeriod == "this year" || selectedPeriod == "last year"
        ? yearDataContacted
        : selectedPeriod == "this quarter" || selectedPeriod == "last quarter"
        ? quarterDataContacted
        : selectedPeriod == "this month" || selectedPeriod == "last month"
        ? monthDataContacted
        : selectedPeriod == "custom1"
        ? customDataContacted1
        : customDataContacted,
    new:
      selectedPeriod == "this week" || selectedPeriod == "last week"
        ? weekDataInterested
        : selectedPeriod == "this year" || selectedPeriod == "last year"
        ? yearDataInterested
        : selectedPeriod == "this quarter" || selectedPeriod == "last quarter"
        ? quarterDataInterested
        : selectedPeriod == "this month" || selectedPeriod == "last month"
        ? monthDataInterested
        : selectedPeriod == "custom1"
        ? customDataInterested1
        : customDataInterested,
  };

  const diffData2 = {
    old:
      selectedPeriod == "this week" || selectedPeriod == "last week"
        ? weekDataContacted
        : selectedPeriod == "this year" || selectedPeriod == "last year"
        ? yearDataContacted
        : selectedPeriod == "this quarter" || selectedPeriod == "last quarter"
        ? quarterDataContacted
        : selectedPeriod == "this month" || selectedPeriod == "last month"
        ? monthDataContacted
        : selectedPeriod == "custom1"
        ? customDataContacted1
        : customDataContacted,
    new:
      selectedPeriod == "this week" || selectedPeriod == "last week"
        ? weekDataWon
        : selectedPeriod == "this year" || selectedPeriod == "last year"
        ? yearDataWon
        : selectedPeriod == "this quarter" || selectedPeriod == "last quarter"
        ? quarterDataWon
        : selectedPeriod == "this month" || selectedPeriod == "last month"
        ? monthDataWon
        : selectedPeriod == "custom1"
        ? customDataWon1
        : customDataWon,
  };

  const options = {
    responsive: true,
    legend: { position: "none" }, // Remove the legend
    chartArea: { width: "80%" }, // Adjust the chart area width as needed
    hAxis: {}, // Hide the horizontal axis labels
    bar: {
      groupWidth:
        selectedPeriod == "this quarter" || selectedPeriod == "custom"
          ? "35%"
          : "55%",
    },
    vAxis: { title: "", titleTextStyle: { italic: false } }, // Customize the vertical axis
    orientation: "horizontal", // Set the chart orientation to vertical
    colors: ["#26AD74"],
  };

  const optionsConversion = {
    responsive: true,
    // width:1000,
    legend: { position: "none" }, // Remove the legend
    chartArea: { width: "80%" }, // Adjust the chart area width as needed
    hAxis: {}, // Hide the horizontal axis labels
    bar: {
      groupWidth:
        selectedPeriod == "this quarter" || selectedPeriod == "custom"
          ? "35%"
          : "55%",
    },
    vAxis: { title: "", titleTextStyle: { italic: false } }, // Customize the vertical axis
    orientation: "horizontal", // Set the chart orientation to vertical
    colors: ["rgb(86, 86, 96)"],
  };

  const optionsTotalResponse = {
    responsive: true,
    title: "",
    chartArea: { width: "80%" }, // Adjust the chart area width as needed
    bar: {
      groupWidth:
        selectedPeriod == "this quarter" || selectedPeriod == "custom"
          ? "35%"
          : "55%",
    },
    legend: { position: "none" },
    orientation: "horizontal",
    colors: ["#BE3455"],
  };

  const optionsTotalInterested = {
    responsive: true,
    title: "",
    chartArea: { width: "80%" }, // Adjust the chart area width as needed
    bar: {
      groupWidth:
        selectedPeriod == "this quarter" || selectedPeriod == "custom"
          ? "35%"
          : "55%",
    },
    legend: { position: "none" },
    orientation: "horizontal",
    colors: ["#26AD74"],
  };

  return (
    <>
      <div className={styles.AnalyticsMain}>
        {/* Leads (Doughnut chart)*/}
        <div className={styles.cardMain}>
          <Card>
            <div className={styles.cardHeader}>
              <h5 className={styles.cardHeading}>Leads</h5>
            </div>
            <div className={styles.cardBody}>
              <div style={{ marginLeft: "40px", position: "relative" }}>
                <DonutChart data={data} />
                <div className={styles.totalLeads}>
                  <div style={{ fontWeight: "500", fontSize: "14px" }}>
                    Total Leads
                  </div>
                  <h1 style={{ fontWeight: "700", fontSize: "26.67px" }}>
                    {/* {analyticsData && analyticsData.length} */}
                    {leadStatusObj &&
                      leadStatusObj?.FOLLOW_UP +
                        leadStatusObj?.INTERESTED +
                        leadStatusObj?.NEW_LEADS +
                        leadStatusObj?.NO_RESPONSE +
                        leadStatusObj?.NOT_INTERESTED}
                  </h1>
                </div>
              </div>

              <div style={{ paddingRight: "16px" }}>
                <div className={styles.statusBars1}>
                  <span className={styles.statusBarHeading1}>Follow up</span>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "13.5422px",
                    }}
                  >
                    {leadStatusObj && leadStatusObj?.FOLLOW_UP}
                  </span>
                </div>
                <div className={styles.statusBars2}>
                  <span className={styles.statusBarHeading2}>
                    On Hold
                  </span>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "13.5422px",
                      color: "#EF233C",
                    }}
                  >
                    {leadStatusObj && leadStatusObj?.NOT_INTERESTED}
                  </span>
                </div>
                <div className={styles.statusBars3}>
                  <span className={styles.statusBarHeading3}>Active</span>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "13.5422px",
                      color: "#26AD74",
                    }}
                  >
                    {leadStatusObj && leadStatusObj?.INTERESTED}
                  </span>
                </div>
                <div className={styles.statusBars4}>
                  <span className={styles.statusBarHeading4}>On Hold</span>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "13.5422px",
                      color: "#F5B82E",
                    }}
                  >
                    {leadStatusObj && leadStatusObj?.NO_RESPONSE}
                  </span>
                </div>
                <div className={styles.statusBars5}>
                  <span className={styles.statusBarHeading5}>New Leads</span>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "13.5422px",
                      color: "#0084EF",
                    }}
                  >
                    {leadStatusObj && leadStatusObj?.NEW_LEADS}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.cardFooter}>
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "11.003px",
                  color: "#212427",
                }}
              >
                {/* Latest Report : {latestDate} */}
              </span>
              <span>
                <span
                  style={{
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "11.003px",
                    color: "#1D3557",
                    textDecoration: "underline",
                  }}
                >
                  {/* View Report */}
                </span>
              </span>
            </div>
          </Card>
        </div>
        {/* Interested vs contacted */}
        <div className={styles.cardMain}>
          <Card>
            <div className={styles.cardHeader}>
              <h5 className={styles.cardHeading}> Interested vs contacted</h5>
            </div>
            <div className={styles.cardBody}>
              <div>
                <BarChart diffdata={diffdata} options={options} />
              </div>
            </div>
            <div className={styles.cardFooter2}>
              <span className={styles.interested}></span>
              <span className={styles.BottomText}>Interested Cases</span>
              <span className={styles.contacted}></span>
              <span className={styles.BottomText}>Contacted Cases</span>
            </div>
          </Card>
        </div>
        {/* Conversion Ratio */}
        <div className={styles.cardMain}>
          <Card>
            <div className={styles.cardHeader}>
              <h5 className={styles.cardHeading}> Conversion Ratio</h5>
            </div>
            <div className={styles.cardBody}>
              <div>
                <BarChart diffdata={diffData2} options={optionsConversion} />
              </div>
            </div>
            <div className={styles.cardFooter2}>
              <span className={styles.won}></span>
              <span className={styles.BottomText}>Won Cases</span>
              <span className={styles.contacted}></span>
              <span className={styles.BottomText}>Total Cases</span>
            </div>
          </Card>
        </div>
        {/* Total Interested */}
        <div className={styles.cardMain}>
          <Card>
            <div className={styles.cardHeader}>
              <h5 className={styles.cardHeading}> Total Interested</h5>
            </div>
            <div className={styles.cardBody}>
              <div>
                <BarChart2
                  data={
                    selectedPeriod == "this week" ||
                    selectedPeriod == "last week"
                      ? weekDataInterested
                      : selectedPeriod == "this year" ||
                        selectedPeriod == "last year"
                      ? yearDataInterested
                      : selectedPeriod == "this quarter" ||
                        selectedPeriod == "last quarter"
                      ? quarterDataInterested
                      : selectedPeriod == "this month" ||
                        selectedPeriod == "last month"
                      ? monthDataInterested
                      : selectedPeriod == "custom1"
                      ? customDataInterested1
                      : customDataInterested
                  }
                  options={optionsTotalInterested}
                />
              </div>
            </div>
            <div className={styles.cardFooter2}>
              <span className={styles.interested2}></span>
              <span className={styles.BottomText}>Interested Cases</span>
            </div>
          </Card>
        </div>

        {/* Total Connected */}
        <div className={styles.cardMain}>
          <Card>
            <div className={styles.cardHeader}>
              <h5 className={styles.cardHeading}> Total Connected</h5>
            </div>
            <div className={styles.cardBody}>
              <div>
                <BarChart2
                  data={
                    selectedPeriod == "this week" ||
                    selectedPeriod == "last week"
                      ? weekTotalResponse
                      : selectedPeriod == "this year" ||
                        selectedPeriod == "last year"
                      ? yearDataTotalResponse
                      : selectedPeriod == "this quarter" ||
                        selectedPeriod == "last quarter"
                      ? quarterDataTotalResponse
                      : selectedPeriod == "this month" ||
                        selectedPeriod == "last month"
                      ? monthDataTotalResponse
                      : selectedPeriod == "custom1"
                      ? customDataTotalResponse1
                      : customDataTotalResponse
                  }
                  options={optionsTotalResponse}
                />
                {/* <BarChart diffdata={diffData4} options={optionsTotalResponse} /> */}
              </div>
            </div>
            <div className={styles.cardFooter2}>
              <span className={styles.totalResponse}></span>
              <span className={styles.BottomText}>Connected</span>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Analytic;
