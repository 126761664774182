import { TextField, Tooltip, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import bgmain from "../../Assets/bg-main.svg";
import styles from "../SignIn/signin.module.css";
import { useNavigate } from "react-router-dom";
import { getReq, postReq, postTReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { PiEyeLight, PiEyeClosedLight } from "react-icons/pi";
import LoginHeader from "../loginHeader/LoginHeader";
import { BASE_URL, apiLinks, signInValidationObject, iDesignWebsite } from "../../piservices/constants";
import { MdToken } from "react-icons/md";
import { toasterControl, validationFunction } from "../../piservices/commonService";

const SignIn = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const [passError, setPassError] = useState("");
  const navigateTo = useNavigate();
  const [input, setinput] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleInput = (event) => {
    const { name, value } = event.target;
    // let valText = validationFunction(signInValidationObject[name], value);
    // setErrors((prev) => {
    //   return {
    //     ...prev,
    //     [name]: valText,
    //   };
    // });
    setinput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const login = async () => {
    Object.keys(input).forEach((curElem) => {
      let valText = validationFunction(signInValidationObject[curElem], input[curElem]);
      setErrors((prev) => {
        return {
          ...prev,
          [curElem]: valText,
        };
      });
    });
    const res = await postTReq(`${apiLinks.crm}${apiEndpoints.login}`, {
      email: input.email,
      password: input.password,
    });
    if (res && !res.error) {
      localStorage.setItem("token", res?.data?.data?.accessToken);
      localStorage.setItem("userId", res?.data?.data?._id);
      localStorage.setItem("userPosition", "des");
      const profRes = await getReq(`${apiLinks.crm}/user/profile`, {
        headers: {
          Authorization: `Bearer${localStorage.getItem("token")}`,
        },
      });
      if (profRes && !profRes.error) {
        if (profRes.data.data.isClient) {
          window.location.assign(`${BASE_URL}/client/`);
          localStorage.setItem("projectId", profRes.data.data.sharedProjectsIds[0]);
        } else {
          window.location.assign(`${BASE_URL}/admin/`);
        }
      } else {
        console.error(profRes.error);
      }
    } else {
      if (res.error.response.data?.statusCode === 400) {
        setPassError("Email or Password is wrong");
        // toasterControl("toasterState", true, "error", "Invalid Credentials");
        if (input.email.length === 0 && input.password.length === 0) {
          setErrors({
            email: " ",
            password: "Fields is Required",
          });
        } else {
          setErrors({
            email: " ",
            password: "Invalid Credentials",
          });
        }
      }
    }
  };

  const [inputType, setInputType] = useState("password");
  const togglePasswordIcon = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };
  return (
    <>
      <LoginHeader />
      <div
        style={{
          backgroundImage: `url(${bgmain})`,
        }}
        className={styles.signInContainer}
      >
        {/* <div style={{ width: "40%", height: "fit-content", border: "1px solid #E0E3E6", borderRadius: "12px", backgroundColor: "#ffffff" }}> */}
        <div className={styles.mainContainer}>

          <div className={styles.welcomeHead}>Welcome {isMobile ? "" : "Back!"}</div>
          <div className={styles.allTextfield}>
            <div style={{ borderRadius: "12px", width: "100%", display: "flex", flexDirection: "column" }}>
              <div style={{ marginBottom: "1rem" }}>
                <TextField
                  label="Email"
                  variant="outlined"
                  error={errors.email}
                  // className="saas-form"
                  type="email"
                  name="email"
                  value={input.email}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputLabelProps={{ shrink: true }} // Removes mm/dd/yy placeholder
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px"}}}
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }} // Removes mm/dd/yy placeholder
                />

                <div className="error">{errors.email}</div>
              </div>

              <div className={styles.passwordInputContainer}>
                <TextField
                  error={errors.password}
                  label="Password"
                  variant="outlined"
                  // className="saas-form"
                  type={inputType}
                  name="password"
                  value={input.password}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputLabelProps={{ shrink: true }} // Removes mm/dd/yy placeholder
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }} // Removes mm/dd/yy placeholder
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px"}}}
                />
                {input.password !== "" && (
                  <Tooltip title={inputType === "password" ? "Show Password" : "Hide Password"} placement="top">
                    <div className={styles.showPasswordIcon} onClick={togglePasswordIcon}>
                      {inputType === "password" ? <PiEyeLight fontSize={22} color="#4d4d4d" /> : <PiEyeClosedLight fontSize={22} color="#4d4d4d" />}
                    </div>
                  </Tooltip>
                )}
              </div>

              <div className="error">{errors.password}</div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div className={styles.forgotText} onClick={() => navigateTo("/reset")}>
                  Forgot Password
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <button className={styles.createBtn} onClick={() => login()}>
                Sign In
              </button>
            </div>

            {/* <div style={{border:"2px solid red",height:"0.2rem",width:"100%",padding:"0"}}></div> */}
            <div style={{ borderTop: "1px solid var(--Neutral-200, #E0E3E6)", width: "100%" }}>
              <div className={styles.footerPara} style={{ paddingTop: "1rem" }}>
              <div>
                On signing you agree to our <span style={{textDecoration:"underline" ,textUnderlineOffset:"0.2em", cursor: "pointer"}} onClick={()=> window.open(`https://www.wedezinestudio.com/contactus.php`, "_blank")}>Terms of Use </span>and <span style={{textDecoration:"underline" ,textUnderlineOffset:"0.2em"}}>Privacy Policy</span>. <br />
                {/* On signing you agree to our <span style={{textDecoration:"underline" ,textUnderlineOffset:"0.2em", cursor: "pointer"}} onClick={()=> window.open(`${iDesignWebsite}/terms`, "_blank")}>Terms of Use </span>and <span style={{textDecoration:"underline" ,textUnderlineOffset:"0.2em"}}>Privacy Policy</span>. <br /> */}
                </div>
                <div className={styles.flexCreateNewPassword}>
                <span style={{marginRight:"0.3rem"}}>Are you logging in for the first time?</span>
                <span style={{ color: "#0084EF", cursor: "pointer" }} onClick={() => navigateTo("/get-otp")}>
                  Create New Password
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
