import React, { useEffect, useState } from "react";
import styles from "./teamOnboard.module.css";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField, createFilterOptions, useMediaQuery } from "@mui/material";
import { Carousel } from "react-bootstrap";
import { AiOutlineArrowLeft, AiOutlinePlusCircle } from "react-icons/ai";
import { postReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { useNavigate } from "react-router-dom";
import { createDesignation, errorChecker, getDesignation, toasterControl, validationFunction } from "../../piservices/commonService";
import { useSelector } from "react-redux";
import { getUserId } from "../../piservices/authService";
import { apiLinks, base_url, teamOnboardVerifyObj } from "../../piservices/constants";
import idesign from "../../Assets/logo.png";

const filter = createFilterOptions();

const TeamOnboard = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const navigateTo = useNavigate();
  const { designationArr } = useSelector((state) => state.onboarding);
  const [inputData, setinputData] = useState([
    {
      fName: "",
      Lname: "",
      email: "",
      role: "",
      designation: "",
    },
    {
      fName: "",
      Lname: "",
      email: "",
      role: "",
      designation: "",
    },
    {
      fName: "",
      Lname: "",
      email: "",
      role: "",
      designation: "",
    },
  ]);

  const [error, seterror] = useState([{}, {}, {}]);
  const handleinput = (name, event, newValue, index) => {
    const { value } = event.target;
    if (newValue) {
      if (name === "fName" || name === "Lname") {
        if (value.length < 16) {
         
          let x = JSON.parse(JSON.stringify(inputData));
          x[index][name] = newValue;
          setinputData(x);
          let valtext = validationFunction(teamOnboardVerifyObj[name], newValue);
          let y = error;
          if (valtext) {
            y[index][name] = true;
          } else {
            y[index][name] = false;
          }
          seterror(y);
        }
      } else {
        let x = JSON.parse(JSON.stringify(inputData));
        x[index][name] = newValue;
        setinputData(x);
        let valtext = validationFunction(teamOnboardVerifyObj[name], newValue);
        let y = error;
        if (valtext) {
          y[index][name] = true;
        } else {
          y[index][name] = false;
        }
        seterror(y);
      }
    } else {
      if (name === "fName" || name === "Lname") {
        if (value.length < 16) {
          let x = JSON.parse(JSON.stringify(inputData));
          x[index][name] = value;
          setinputData(x);
          let valtext = validationFunction(teamOnboardVerifyObj[name], value);
          let y = error;
          if (valtext) {
            y[index][name] = true;
          } else {
            y[index][name] = false;
          }
          seterror(y);
        }
      } else {
        let x = JSON.parse(JSON.stringify(inputData));
        x[index][name] = value;
        setinputData(x);
        let valtext = validationFunction(teamOnboardVerifyObj[name], value);
        let y = error;
        if (valtext) {
          y[index][name] = true;
        } else {
          y[index][name] = false;
        }
        seterror(y);
      }
    }
  };

  const createAndSelectDesignation = async (desName, index) => {
    const newDes = await createDesignation(desName);
    let x = JSON.parse(JSON.stringify(inputData));
    x[index]["designation"] = newDes;
    setinputData(x);
  };

  useEffect(() => {
    getDesignation();
  }, []);

  // invite member
  const teamOnboard = async () => {
    let check = errorChecker(false, error);
    if (check) {
      let x = JSON.parse(JSON.stringify(error));
      inputData.forEach((curElem, index) => {
        Object.keys(curElem).forEach((cur) => {
          let valtext = validationFunction(teamOnboardVerifyObj[cur], curElem[cur]);
          if (valtext) {
            x[index][cur] = true;
          } else {
            x[index][cur] = false;
          }
        });
      });
      seterror(x);
    } else {
      let payload = JSON.parse(JSON.stringify(inputData));
      let x = payload.map((elem) => {
        return {
          firstName: elem.fName,
          lastName: elem.Lname,
          email: elem.email,
          role: elem.role,
          designation: elem.designation._id,
        };
      });
      let data = x.filter((elem) => {
        return elem.fName !== "" && elem.Lname !== "" && elem.email !== "" && elem.role !== "" && elem.designation !== "";
      });
      const res = await postReq(`${apiLinks.crm}${apiEndpoints.teamOnboard}?userId=${getUserId()}`, {
        teamMembers: data,
      });
      if (res && !res.error) {
        // window.location.assign("https://erp.wedezinestudio.com/admin/");
      } else {
        console.error(res.error);
      }
    }
  };

  const checkIfAllFilled = () => {
    const check = inputData.some((curElem) => {
      return curElem.Lname !== "" && curElem.designation !== "" && curElem.email !== "" && curElem.fName !== "" && curElem.role !== "";
    });
    return check;
  };
  console.log("hey")

  return (
    <>
      <div className={styles.container}>
        <div className={styles.first_contain}>
          {/* <img src={idesign} alt="" style={{ height: "2rem", position: "absolute", top: "2rem", left: "4rem"  }} /> */}
          <div className={styles.logoImgContainer}><img src={idesign} alt="" className={styles.logoImg} /></div>
 
          <Carousel
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "85%",
              // marginTop:"2.5rem",
            }}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
          >
            {/* <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}> */}
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207461%20%281%29.png" alt="First slide" className={styles.imgg} />
              </div>
              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                {/* <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group.svg" alt="" style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.5rem", marginBottom: "1rem" }} /> */}
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group.svg" alt="" className={styles.miniImg} />
                {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}> */}
                <div className={styles.insideCaraouselItem} >
                  <div className={styles.heading}>FILE MANAGER</div>
                  <div className={styles.para}>Easily organize and manage all of your important documents with our file manager feature.</div>
                </div>
              </div>
              </div>
            </Carousel.Item>

            {/* <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}> */}
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207467%20%282%29.png" alt="Second slide" className={styles.imgg} />
              </div>

              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                {/* <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group1.svg" alt="" style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.5rem", marginBottom: "1rem" }} /> */}
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group1.svg" alt="" className={styles.miniImg} />
                {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}> */}
                <div className={styles.insideCaraouselItem} >
                  <div className={styles.heading}>TASK MANAGEMENT</div>
                  <div className={styles.para}>Optimize resource usage and ensures that projects are completed efficiently and on time.</div>
                </div>
              </div>
              </div>
            </Carousel.Item>

            {/* <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}> */}
            <Carousel.Item className={styles.CarouselItem}>
          <div className={styles.flexForCarousel}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207462%20%283%29.png" alt="Third slide" className={styles.imgg} />
              </div>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem" }}>
                {/* <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/CurrencyDollarSimple.svg"
                  alt=""
                  style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.2rem", marginBottom: "1rem" }}
                /> */}
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/CurrencyDollarSimple.svg" alt="" className={styles.miniImg} />
                {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}> */}
                <div className={styles.insideCaraouselItem} >
                  <div className={styles.heading}>Quotation</div>
                  <div className={styles.para}>Save time and improves the credibility of the interior designer by ensuring that the quotes are error-free and tailored to the client's needs.</div>
                </div>
              </div>
              </div>
            </Carousel.Item>

            {/* <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}> */}
            <Carousel.Item className={styles.CarouselItem}>
           <div className={styles.flexForCarousel}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207464%20%283%29.svg" alt="Third slide" className={styles.imgg} />
              </div>

              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                {/* <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/LineSegments.svg" alt="" style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.5rem", marginBottom: "1rem" }} /> */}
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/LineSegments.svg" alt="" className={styles.miniImg} /> 
                {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}> */}
                <div className={styles.insideCaraouselItem} >

                  <div className={styles.heading}>Timeline</div>
                  <div className={styles.para}>Create project schedules and timelines & helps to keep projects on track and ensures that deadlines are met.</div>
                </div>
              </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* second div */}
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            > */}
            <div className={styles.getItemOnboard} >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className={styles.personal_head}>Get your team on board</div>
                <div className={styles.head_para}>Invite your team mates and collaborate</div>
              </div>

              <button className={styles.backBtn} onClick={() => navigateTo("/company-details")}>
                <AiOutlineArrowLeft className={styles.backArrow} />
                Go back
              </button>
              <div className={styles.addLater}>
              <a href={`${base_url}/admin/`} className={styles.skip} style={{ textDecoration: "none" }}>
              Add Later
            </a>
            </div>
            </div>

            {/* <div>Invite your team mates and collaborate</div> */}

            <div style={{ overflowY: "scroll", height: "30rem", marginTop:isMobile?"0rem" :"0.5rem",}} className="example">
              {inputData.map((curElem, index) => {
                return (
                  <div style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>
                    {/* all inputs div */}
                    {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
                    <div className={styles.inputWrapper}>

                      {/* <div style={{ display: "flex" }}> */}
                      <div className={styles.wrapper}>
                      <div className={styles.textAreaWrapper}>
                        <TextField
                          id="outlined-basic"
                          error={error[index].fName}      
                          label="First Name"
                          variant="outlined"
                          // className="saas-form "
                          type="text"
                          value={curElem.fName}
                          onChange={(event) => handleinput("fName", event, false, index)}
                          sx={{ width: "50%", marginRight: "0.5rem", marginBottom: "0.5rem" ,'@media (max-width: 600px)':{ marginRight: "0rem", marginBottom: "0rem"} }}
                          InputProps={{ style: { borderRadius: "8px" } }}
                          inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                        />
               
                        <TextField
                          id="outlined-basic"
                          error={error[index].Lname}
                          label="Last Name"
                          variant="outlined"
                          className="saas-form"
                          type="text"
                          value={curElem.Lname}
                          onChange={(event) => handleinput("Lname", event, false, index)}
                          sx={{ width: "50%", marginRight: "0.5rem", marginBottom: "0.5rem", '@media (max-width: 600px)':{ marginRight: "0rem", marginBottom: "0rem"}  }}
                          InputProps={{ style: { borderRadius: "8px" } }}
                          inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                        />
                        </div>
                        <div className={styles.emailWrapper}>
                        <TextField
                          id="outlined-basic"
                          error={error[index].email}
                          label="Email"
                          variant="outlined"
                          className="saas-form"
                          type="email"
                          value={curElem.email}
                          onChange={(event) => handleinput("email", event, false, index)}
                          sx={{ width: "100%",  '@media (max-width: 600px)': { width: '100%', }}}
                          InputProps={{ style: { borderRadius: "8px" } }}
                          inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                        />
                        </div>
                      </div>
                      {/* <div style={{ display: "flex" }}> */}
                      <div className={styles.roleWrapper}>

                        <FormControl sx={{ width: "30%", marginRight: "0.5rem", marginBottom: "0.5rem", '@media (max-width: 600px)': { width: '50%',  marginBottom: "0rem",  marginRight: "0rem", }, }} error={error[index].role}>
                          <InputLabel id="demo-simple-select-helper-label" >Role</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={curElem.role}
                            label="Role"
                            onChange={(event) => handleinput("role", event, false, index)}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="Super Admin">Super Admin</MenuItem>
                            <MenuItem value="Admin">Admin</MenuItem>
                            <MenuItem value="Site Manager">Site Manager</MenuItem>
                            <MenuItem value="Project Head">Project Head</MenuItem>
                            <MenuItem value="Team Leader">Team Leader</MenuItem>
                            <MenuItem value="Accountant">Accountant</MenuItem>
                            <MenuItem value="Purchase Order">Purchase Order</MenuItem>
                            <MenuItem value="BD/Sales">BD/Sales</MenuItem>
                          </Select>
                        </FormControl>

                        <Autocomplete
                          sx={{ width: "30%", marginRight: "0.5rem", marginBottom: "0.5rem",  '@media (max-width: 600px)': { width: '50%',  marginBottom: "0rem",  marginRight: "0rem", }, }}
                          value={curElem.designation}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              let x = JSON.parse(JSON.stringify(inputData));
                              x[index]["designation"] = { designationName: newValue };
                              setinputData(x);
                            } else if (newValue && newValue.inputValue) {
                              if (newValue.designationName && newValue.designationName.includes("Create")) {
                                createAndSelectDesignation(newValue.inputValue, index);
                              } else {
                                let x = JSON.parse(JSON.stringify(inputData));
                                x[index]["designation"] = { designationName: newValue.inputValue };
                                setinputData(x);
                              }
                            } else {
                              let x = JSON.parse(JSON.stringify(inputData));
                              x[index]["designation"] = newValue;
                              setinputData(x);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.designationName);
                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                designationName: `Create ${inputValue}`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={designationArr}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.designationName;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.designationName}</li>}
                          freeSolo
                          renderInput={(params) => <TextField {...params} label="Designation" />}
                        />
                      </div>
                      {/* <div style={{ width: "40%" }}></div> */}
                      <div className={styles.forDiv}></div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className={styles.addMoreBtn}
              onClick={() => {
                setinputData((prev) => {
                  return [
                    ...prev,
                    {
                      fName: "",
                      Lname: "",
                      email: "",
                      role: "",
                      designation: "",
                    },
                  ];
                });
                seterror((prev) => {
                  return [...prev, {}];
                });
              }}
            >
              <AiOutlinePlusCircle className="me-1 " /> Add Another
            </div>

            <div >
              <button
                className={styles.nextBtn}
                onClick={() => {
                  if (checkIfAllFilled()) {
                    teamOnboard();
                    window.location.assign(`${base_url}/admin/`);
                  } else {
                    toasterControl(true, "Please fill at least one row", "error");
                  }
                }}
              >
                Invite
              </button>
              <div style={{marginBottom:"10px", display:isMobile?"block":"none"}}></div>
            </div>
                <div className={styles.addBtnInLast}>
            <a href={`${base_url}/admin/`} className={styles.skip} style={{ textDecoration: "none" }}>
              Add Later
            </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamOnboard;
