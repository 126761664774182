import React, { useState } from 'react'
import DropdownComponent from '../DropdownComponent'
import styles from '../../styles/additionalInfo.module.css'
import data from "./Data";
import InputBoxComponent from '../InputBoxComponent';

import { useDispatch, useSelector } from 'react-redux'
import { setliving } from '../../../../../Redux/Slices/additionalDetailsSlice';
function Living() {

    const dispatch = useDispatch();
    const living = useSelector(state => state.additionalInfoSlice.living);

      const handleChange = (type, value) => {
        dispatch(setliving({ ...living, [type]: value }));
    };

    return (
        <>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`TV Unit `}</div>
                 <DropdownComponent
                        elementArr={data.LIVING.TV_UNIT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={living}
                        type="TVUnit"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`TV unit Back Wall Treatement `}</div>
                    <DropdownComponent
                        elementArr={data.LIVING.TV_UNIT_BACK_WALL_TREATMENT}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={living}
                        type="TVUnitBackWallTreatment"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`L-Type Sofa `}</div>
                      <DropdownComponent
                        elementArr={data.LIVING.LTYPE_SOFA}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={living}
                        type="LTypeSofa"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                >
                    <div
                        className={styles.filter_icon}

                    >{`Split Sofa `}</div>
                    <DropdownComponent
                        elementArr={data.LIVING.SPLIT_SOFA}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={living}
                        type="splitSofa"
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Accent chair `}</div>
                   <DropdownComponent
                        elementArr={data.LIVING.ACCENT_CHAIR}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={living}
                        type="accentChair"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Center Table `}</div>
                   <DropdownComponent
                        elementArr={data.LIVING.CENTER_TABLE}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={living}
                        type="centerTable"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Side Table `}</div>
                     <DropdownComponent
                        elementArr={data.LIVING.SIDE_TABLE}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={living}
                        type="sideTable"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Sofa Back Wall `}</div>
                     <DropdownComponent
                        elementArr={data.LIVING.SOFA_BLACK_WALL}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={living}
                        type="sofaBackWall"
                        handleChange={handleChange}
                    />
                </div>

            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`False Ceiling `}</div>
                   <DropdownComponent
                        elementArr={data.LIVING.FALSE_CEILING}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={living}
                        type="falseCeiling"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Additional Info `}</div>
                  <InputBoxComponent selectedElement={living} type='additionalInfo' handleChange={handleChange} placeholder={"Any additional info"} />
                </div>

            </div>
        </>
    )
}

export default Living