import React, { useState } from 'react'
import DropdownComponent from '../DropdownComponent'
import styles from '../../styles/additionalInfo.module.css'
import data from "./Data";
import InputBoxComponent from '../InputBoxComponent';

import { useDispatch, useSelector } from 'react-redux'
import { setclientFamilyInfo } from '../../../../../Redux/Slices/additionalDetailsSlice';
function ClientFamilyInfo() {
    const dispatch = useDispatch();
    const clientFamilyInfo = useSelector(state => state.additionalInfoSlice.clientFamilyInfo);

    const handleChange = (type, value) => {
        dispatch(setclientFamilyInfo({ ...clientFamilyInfo, [type]: value }));
    };
    return (
        <>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Religion `}</div>
                    <DropdownComponent
                        elementArr={data.CLIENT_FAMILY_INFO.RELIGION}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={clientFamilyInfo}
                        type="religion"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Region of Origin `}</div>
                    <DropdownComponent
                        elementArr={data.CLIENT_FAMILY_INFO.REGION_OF_ORIGIN}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={clientFamilyInfo}
                        type="regionOfOrigin"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Type Of Family `}</div>
                    <DropdownComponent
                        elementArr={data.CLIENT_FAMILY_INFO.TYPE_OF_FAMILY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={clientFamilyInfo}
                        type="typeOfFamily"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                >
                    <div
                        className={styles.filter_icon}

                    >{`Number of Family Members `}</div>
                    <DropdownComponent
                        elementArr={data.CLIENT_FAMILY_INFO.NUMBER_OF_FAMILY_MEMBER}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={clientFamilyInfo}
                        type="numberOfMembers"
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Family Consist of `}</div>
                    <DropdownComponent
                        elementArr={data.CLIENT_FAMILY_INFO.FAMILY_CONSIST_OF}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={clientFamilyInfo}
                        type="familyConsistOf"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Traditional & Spiritual`}</div>
                    <DropdownComponent
                        elementArr={data.CLIENT_FAMILY_INFO.TRADITIONAL_AND_SPIRITUAL}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={clientFamilyInfo}
                        type="traditionalAndSpritual"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Current Residence `}</div>
                    <InputBoxComponent selectedElement={clientFamilyInfo} type='currentResidence' handleChange={handleChange} placeholder={"Enter current residence"} />
                </div>

                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Believe in Vatsu`}</div>
                    <DropdownComponent
                        elementArr={data.CLIENT_FAMILY_INFO.BELIEVE_IN_VASTU}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={clientFamilyInfo}
                        type="believeInVatsu"
                        handleChange={handleChange}
                    />
                </div>

            </div>
        </>
    )
}

export default ClientFamilyInfo