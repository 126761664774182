import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addLeadModalOpen: false,
    newLeadObj:{},
    fileImportedData:[]
}
export const newLeadSlice = createSlice({
    name: 'newLead',
    initialState,
    reducers:{
        setAddLeadModalOpen:(state, action)=>{
            state.addLeadModalOpen = action.payload;
        },
        newLeadObj:(state, action)=>{
            state.newLeadObj = action.payload;
        },
        setFileImportedData:(state, action)=>{
            state.fileImportedData = action.payload;
        }
    }
})

export const {setFileImportedData, setAddLeadModalOpen, newLeadObj} = newLeadSlice.actions;
export default newLeadSlice.reducer;
