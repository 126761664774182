import React, { useEffect, useState } from "react";
import styles from "./companyDetails.module.css";
import { TextField, useMediaQuery } from "@mui/material";
import { Carousel } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { getReq, postReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import idesign from "../../Assets/logo.png";
import { BASE_URL, apiLinks, base_url, emailValidation, numberArray } from "../../piservices/constants";
import { validationFunction } from "../../piservices/commonService";
import MuiPhoneNumber from "material-ui-phone-number";
import { getToken } from "../../piservices/authService";
import Map from "./Map";
import { MdMyLocation } from "react-icons/md";
import { useSelector } from "react-redux";

const CompanyDetails = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const navigateTo = useNavigate();
  const { mapLocation } = useSelector((state) => state.onboarding);

  const [profileData, setProfileData] = useState({});

  const getProfileData = async () => {
    const res = await getReq(`${apiLinks.crm}/user/profile`, {}, { Authorization: `Bearer ${getToken()}` });
    if (res && !res.error) {
      setProfileData({ ...res.data.data });
    } else {
      localStorage.clear();
      window.location.assign(`${BASE_URL}`);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  // console.log(profileData);
  useEffect(() => {
    if (profileData && mapLocation) {
      let x = profileData;
      x.CompanySettingDetails.companyLocation[0].location = mapLocation;
      setProfileData(x);
    }
  }, [profileData, mapLocation]);

  const [error, seterror] = useState({
    companyName: false,
    email: false,
    address: false,
    city: false,
    pincode: false,
    gstNo: false,
    number: false,
    currency: false,
  });

  const [input, setinput] = useState({
    companyName: "",
    email: "",
    address: "",
    country: "",
    city: "",
    pincode: "",
    gstNo: "",
    number: "",
    countryCode: "",
    currency: "",
    Location: "",
  });

  const [errors, setErrors] = useState({
    email: "",
  });
  const handleInput = (event) => {
    const { name, value } = event.target;
    // if (name === "email") {
    //   let valText = validationFunction(emailValidation[name], value);
    //   setErrors((prev) => {
    //     return {
    //       ...prev,
    //       email: valText,
    //     };
    //   });
    // }

    // if (value) {
    //   seterror((prev) => {
    //     return {
    //       ...prev,
    //       [name]: false,
    //     };
    //   });
    // } else {
    //   seterror((prev) => {
    //     return {
    //       ...prev,
    //       [name]: true,
    //     };
    //   });
    // }

    if (name === "pincode") {
      if (value.length < 7) {
        setinput((prev) => {
          return {
            ...prev,
            pincode: value,
          };
        });
      }
    }

    if (name === "gstNo") {
      if (value.length < 16) {
        setinput((prev) => {
          return {
            ...prev,
            gstNo: value,
          };
        });
      }
    }

    if (name === "companyName") {
      if (value.length < 30) {
        setinput((prev) => {
          return {
            ...prev,
            companyName: value,
          };
        });
      }
    }
    if (name === "address") {
      if (value.length < 50) {
        setinput((prev) => {
          return {
            ...prev,
            address: value,
          };
        });
      }
    }

    if (name === "city") {
      let check = numberArray.some((elem) => {
        return value.includes(elem);
      });
      if (!check) {
        if (value.length < 20) {
          setinput((prev) => {
            return {
              ...prev,
              city: value,
            };
          });
        }
      }
    }

    if (name === "email") {
      setinput((prev) => {
        return {
          ...prev,
          email: value,
        };
      });
    }

    if (name === "number") {
      if (value?.length < 11) {
        setinput((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    }
  };

  // company details
  const companyDetails = async () => {
    let payload = JSON.parse(JSON.stringify(profileData.CompanySettingDetails));
    // let companyLocationObj = {
    //   ...payload.companyLocation[0],

    //   companyAddress: payload.address,
    //   country: payload.country,
    //   currency: payload.currency,
    //   pincode: payload.pincode,
    //   city: payload.city,
    //   gstNo: payload.gstNo,
    //   location: payload.companyLocation[0]?.location,
    // };

    let Obj = {
      createdBy: payload.createdBy,
      fileManagerV2: payload.fileManagerV2,
      timeLineV2: payload.timeLineV2,
      companyLogo: payload.companyLogo,
      companyName: payload.companyName,
      about: payload.about,
      address: payload.address,
      email: payload.email,
      city: payload.city,
      location: payload.location,
      pincode: payload.pincode,
      gstNo: payload.gstNo,
      contact: payload.contact,
      companyLocation: [
        {
          locationName: payload.companyLocation[0].locationName,
          location: payload.companyLocation[0].location,
          companyAddress: payload.companyLocation[0].companyAddress,
          country: payload.companyLocation[0].country,
          countryCode: payload.companyLocation[0].countryCode,
          currency: payload.companyLocation[0].currency,
          pincode: payload.companyLocation[0].pincode,
          city: payload.companyLocation[0].city,
          zipCode: payload.companyLocation[0].zipCode,
          taxNo: payload.companyLocation[0].taxNo,
          gstNo: payload.companyLocation[0].gstNo,
          billingAddress: payload.companyLocation[0].billingAddress,
          billingCity: payload.companyLocation[0].billingCity,
          billingPincode: payload.companyLocation[0].billingPincode,
          createdBy: payload.companyLocation[0].createdBy,
          createdDate: payload.companyLocation[0].createdDate,
        },
      ],
      attendanceRadius: payload.attendanceRadius,
      workTiming: payload.workTiming,
    };

    // payload["companyLocation"] = [companyLocationObj];
    // delete payload.holidays;

    const res = await postReq(`${apiLinks.crm}/api/enterprise/save-company-settings`, Obj);
    if (res && !res.error) {
      setinput({
        companyName: "",
        email: "",
        address: "",
        city: "",
        pincode: "",
        gst: "",
        number: "",
      });
      navigateTo("/team-onboard");
    } else {
      console.error(res.error);
    }
  };

  const [openMap, setopenMap] = useState(false);
  return (
    <>
      <Map open={openMap} close={() => setopenMap(false)} placeLocation={profileData?.CompanySettingDetails?.location} />
      <div className={styles.container}>
        <div className={styles.first_contain}>
          {/* <img src={idesign} alt="" style={{ height: "2rem", position: "absolute", top: "2rem", left: "4rem" }} /> */}
          <div className={styles.logoImgContainer}><img src={idesign} alt="" className={styles.logoImg} /></div>

          <Carousel
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "85%",
            }}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
          >
            {/* <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}> */}
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207461%20%281%29.png" alt="First slide" className={styles.imgg} />
              </div>
              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                {/* <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group.svg" alt="" style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.5rem", marginBottom: "1rem" }} /> */}
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group.svg" alt="" className={styles.miniImg} />
                {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}> */}
                <div className={styles.insideCaraouselItem}>
                  <div className={styles.heading}>FILE MANAGER</div>
                  <div className={styles.para}>Easily organize and manage all of your important documents with our file manager feature.</div>
                </div>
              </div>
              </div>
            </Carousel.Item>

            {/* <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}> */}
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207467%20%282%29.png" alt="Second slide" className={styles.imgg} />
              </div>

              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                {/* <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group1.svg" alt="" style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.5rem", marginBottom: "1rem" }} /> */}
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group1.svg" alt="" className={styles.miniImg} />
                {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}> */}
                <div className={styles.insideCaraouselItem}>
                  <div className={styles.heading}>TASK MANAGEMENT</div>
                  <div className={styles.para}>Optimize resource usage and ensures that projects are completed efficiently and on time.</div>
                </div>
              </div>
              </div>
            </Carousel.Item>
            {/* <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}> */}
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207462%20%283%29.png" alt="Third slide" className={styles.imgg} />
              </div>

              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem" }}>
                {/* <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/CurrencyDollarSimple.svg"
                  alt=""
                  style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.2rem", marginBottom: "1rem" }}
                /> */}
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/CurrencyDollarSimple.svg"
                  alt="" className={styles.miniImg}
                />
                {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}> */}
                <div className={styles.insideCaraouselItem}>
                  <div className={styles.heading}>Quotation</div>
                  <div className={styles.para}>Save time and improves the credibility of the interior designer by ensuring that the quotes are error-free and tailored to the client's needs.</div>
                </div>
              </div>
              </div>
            </Carousel.Item>

            {/* <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}> */}
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207464%20%283%29.svg" alt="Third slide" className={styles.imgg} />
              </div>

              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                {/* <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/LineSegments.svg" alt="" style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.5rem", marginBottom: "1rem" }} /> */}
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/LineSegments.svg" alt="" className={styles.miniImg} />
                {/* <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}> */}
                <div className={styles.insideCaraouselItem}>
                  <div className={styles.heading}>Timeline</div>
                  <div className={styles.para}>Create project schedules and timelines & helps to keep projects on track and ensures that deadlines are met.</div>
                </div>
              </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* second div */}
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            > */}
            <div className={styles.companyDetailsDiv} >
              <div className={styles.personal_head}>Company Details</div>
              <button className={styles.backBtn} onClick={() => navigateTo("/personal-details")}>
                <AiOutlineArrowLeft className={styles.backArrow} />
                Go back
              </button>
              <div className={styles.skipShowHide}>
              <a href={`${base_url}/admin/`} className={styles.skip} style={{ textDecoration: "none" }}>
              Skip
            </a></div>
            </div>

            {/* all inputs div */}
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <div style={{ display: "flex", gap: "1rem" }}>
                <TextField
                  id="outlined-basic"
                  error={error.companyName}
                  label="Company Name"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="companyName"
                  value={profileData?.CompanySettingDetails?.companyName}
                  onChange={handleInput}
                  fullWidth
                  disabled
                  InputProps={{ style: {borderRadius: "12px"}}}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
              </div>

              <div style={{ display: "flex", gap: "1rem", position: "relative" }}>
                <TextField
                  id="outlined-basic"
                  error={errors.email}
                  label="Email"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="email"
                  value={profileData?.CompanySettingDetails?.email}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "50%" }}
                  InputProps={{ style: { borderRadius: "12px" }, inputMode: "text" }}
                  // inputProps={{  }}
                  disabled
                />

                <TextField
                  id="outlined-basic"
                  // error={error.pincode}
                  label="Country"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="country"
                  value={profileData?.CompanySettingDetails?.country}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "50%" }}
                  InputProps={{ style: { borderRadius: "12px" }, inputMode: "text" }}
                  disabled
                  InputProps={{ style: { borderRadius: "12px" } }}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
              </div>

              <div>
                <TextField
                  id="outlined-basic"
                  label="Permanent Address"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="address"
                  value={profileData?.CompanySettingDetails?.address}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                  disabled
                />
              </div>

              {/* <div style={{ display: "flex", gap: "1rem", position: "relative" }}> */}
              <div className={styles.cityContainer}>

                <TextField
                  id="outlined-basic"
                  label="City"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="city"
                  value={profileData?.CompanySettingDetails?.city}
                  onChange={handleInput}
                  // style={{ borderRadius: "12px", width: "50%" }}
                  sx={{ width: "50%",  '@media (max-width: 600px)': { width: '100%', }}}
                  InputProps={{ style: { borderRadius: "12px" }, inputMode: "text" }}
                  disabled
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
                <TextField
                  id="outlined-basic"
                  label="Location"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="Location"
                  value={profileData?.CompanySettingDetails?.companyLocation[0]?.location}
                  onChange={handleInput}
                  
                  // style={{ borderRadius: "12px", width: "50%", borderRight: "none" }}
                  sx={{ borderRadius: "12px",width: "50%",borderRight: "none" ,  '@media (max-width: 600px)': { width: '100%', }}}
                  InputProps={{ style: { borderRadius: "12px", paddingRight: "1rem" }, inputMode: "text" }}
                />

                <div style={{ position: "absolute", right: "0.5rem", top: "0.8rem" }}>
                  <MdMyLocation onClick={() => setopenMap(true)} style={{ color: "#0084EF", cursor: "pointer" }} />
                </div>
              </div>

              <div style={{ display: "flex", gap: "1rem" }}>
                <TextField
                  id="outlined-basic"
                  // error={error.pincode}
                  label="pincode"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="pincode"
                  value={profileData?.CompanySettingDetails?.pincode}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "50%" }}
                  InputProps={{ style: { borderRadius: "12px" }, inputMode: "text" }}
                  // style={{ width: "50%" }}
                  sx={{ width: "50%",  '@media (max-width: 600px)': { width: '100%', }}}
         
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                  disabled
                />

                <MuiPhoneNumber
                  style={{ width: "50%" }}
                  variant="outlined"
                  label="Contact"
                  value={profileData?.CompanySettingDetails?.contact}
                  onChange={(newVal) => handleInput({ target: { name: "number", value: newVal } })}
                  defaultCountry={"in"}
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }}
                  disabled
                />
              </div>

              <div style={{ display: "flex", gap: "1rem" }}>
                <TextField
                  id="outlined-basic"
                  error={error.gst}
                  label="GST NO."
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="gst"
                  value={profileData?.CompanySettingDetails?.gstNo}
                  disabled
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  //   InputLabelProps={{ shrink: true }} // Removes mm/dd/yy placeholder
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />

                <TextField
                  id="outlined-basic"
                  error={error.gst}
                  label="Currency"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="currency"
                  value={profileData?.CompanySettingDetails?.currency}
                  disabled
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  //   InputLabelProps={{ shrink: true }} // Removes mm/dd/yy placeholder
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
              </div>
            </div>

            <div>
              <button
                className={styles.nextBtn}
                onClick={() => {
                  // Object.keys(input).forEach((elem) => {
                  //   if (input[elem]) {

                  //   } else {
                  //     seterror((prev) => {
                  //       return {
                  //         ...prev,
                  //         [elem]: true,
                  //       };
                  //     });
                  //   }
                  // });
                  // if (input.companyName !== "" && input.gst !== "") {

                  // }
                  companyDetails();
                }}
              >
                Next
              </button>
              <div style={{marginBottom:"10px", display:isMobile?"block":"none"}}></div>
            </div>
            <div className={styles.skipBtnInLast}>
            <a href={`${base_url}/admin/`} className={styles.skip} style={{ textDecoration: "none" }}>
              Skip Complete Profile
            </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
