import React, { useState, useContext, useEffect } from "react";
import ProjectHeader from "../../Common/NewLayout/ProjectHeader";
import TopHeaderCommon from "../../Common/NewLayout/TopHeaderCommon";
import SideProjectPanel from "../../Common/NewLayout/SideProjectPanel";
import desquoContext from "../../../picontext/DashBoard/desquoContext";
import {
  formateDate,
  valueToLeadStatus,
  valueToLeadStatusFormatted,
} from "../../../piservices/compCommon";
import {
  Dropdown,
  Space,
  Form,
  Input,
  Button,
  Select,
  Tooltip,
  notification,
} from "antd";
import { MdInfoOutline } from "react-icons/md";
import LockBlack from "../../../Assets/LockBlack.svg";
import { useLocation, useParams } from "react-router-dom";
import { postReq, getReq } from "../../../piservices/apis";
import expf, { apiLinks, base_url } from "../../../piservices/constants";
import styles from "./LeadManagement.module.css";
import { FaEllipsisV } from "react-icons/fa";
import { PiPhoneCallLight } from "react-icons/pi";
import { CiMail, CiLocationOn } from "react-icons/ci";
import { useSelector, useDispatch } from "react-redux";
import { BsCheck2 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import UnAssignedSidebardebar from "./UnAssignedSidebar";
import {
  createProjectModal,
  currentLead,
  selectNextSlide,
  slideIn,
  slideOut,
  setEditLead,
  setUserData,
  setProfileData,
} from "../../../Redux/Slices/lmsSlice";
import Interaction from "./Interaction";
import LeadInfoSidePanel from "./LeadInfoSidePanel";
import {
  updateClosureMonth,
  selectedLeadData,
} from "../../../Redux/Slices/specificleadSlice";
import Logs from "./Logs";
import AddLeadModal from "../AddLeadModal";
import EditModal from "../../Common/NewLayout/Modals/EditModal";
import { BiChevronDown } from "react-icons/bi";
import SendMail from "../LeadUpdate/SendMail";
import CreateNewProjectNew from "../CreateNewProject/CreateNewProjectNew";
import LmsMail from "../LmsMail.js/LmsMail";
import axios from "axios";
import { Modal } from "react-bootstrap";
import {
  projectDataCurrObj,
  setIsChecked,
} from "../../../Redux/Slices/convertToProjectSlice";
import EditLeadModal from "../EditLeadModal";
import AdditionalDetails from "./components/AdditionalDetails";
import {
  setBalcony,
  setclientAndPropertyDetails,
  setclientDetails,
  setclientFamilyInfo,
  setdining,
  setfoyer,
  setgbr,
  setinformation,
  setkbr,
  setkitchen,
  setliving,
  setmbr,
  setmeetingDetails,
  setotherDetails,
  setpbr,
  setprojectInfo,
  setutility,
} from "../../../Redux/Slices/additionalDetailsSlice";
import { getToken } from "../../../piservices/authService";
import { ThreeDots } from 'react-loader-spinner'
// import { Dropdown, Space, Modal, Form, Input, Button, Select,notification } from 'antd';

function LeadsDetail() {
  const [shouldAnimatee, setShouldAnimatee] = useState(false);
  const [isdeleteModal, setIsDeleteModal] = useState(false);
  const [openedTab, setOpenedTab] = useState("interaction");
  // const [teamLeadArr, setTeamLeadArr] = useState([]);
  // const [leadSources, setLeadSources] = useState([]);
  // const [sourceNames, setSourceNames] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actualLeadStatus, setActualLeadStatus] = useState(1);
  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const [updatePanel, setUpdatePanel] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedLeadSourceId, setSelectedLeadSourceId] = useState();
  const [filteredSourceNames, setFilteredSourceNames] = useState([]);
  const [detailRenderer, setDetailRenderer] = useState(false);
  // const [userData, setUserData] = useState({});
  // const [workType, setWorkType] = useState([])
  const leadSources = useSelector((state) => state.lmsSlice.leadSources);
  const sourceNames = useSelector((state) => state.lmsSlice.sourceNames);
  const teamLeadArr = useSelector((state) => state.lmsSlice.teamLeadArr);
  const workType = useSelector((state) => state.lmsSlice.workType);
  const userData = useSelector((state) => state.lmsSlice.userData);
  const { sidebarToggle } = useSelector((state) => state.lmsSlice);
  const profileData = useSelector((state) => state.lmsSlice.profileData);
  const [loadinguserDataApi, setLoadinguserDataApi] = useState(true)
  const [loadingApiFetch, setLoadingFetchApi] = useState(true)
 
  const dispatch = useDispatch();
  const [headerSelectedTab, setSelectedHeaderTab] =
    useState("Leads Management");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const context = useContext(desquoContext);
  const { id } = useParams();
  let { state } = useLocation();
  const tab = state?.tab;
  const {
    activeLead,
    setActiveLeadState,
    mailStatus,
    setMailStatus,
    dateTime,
    leads,
    getActive,
    setActiveLead,
    leadStatusItem,
  } = context;
  const selectedLeadsData = useSelector(
    (state) => state.selectedLeadSlice.selectedLeadData
  );
  const addLeadModalOpen = useSelector(
    (state) => state.newLeadSlice.addLeadModalOpen
  );
  useEffect(() => {
    setData(selectedLeadsData);
    setDetailRenderer(!detailRenderer);
  }, [selectedLeadsData?.leadUpdates?.length]);
  const [data, setData] = useState(selectedLeadsData);

  const showDeleteModal = () => {
    setIsDeleteModal(true);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    deleteLead();
  };

  const handleDeletModal = () => {
    setIsDeleteModal(false);
  };
  const handleLeadSourceChange = (value) => {
    setSelectedLeadSourceId(value);
    const filtered = sourceNames.filter((item) => {
      return item.id === value;
    });
    setFilteredSourceNames(filtered);
    const selectedOptionObject = leadSources.find(
      (option) => option.id === value
    );
    setSelectedOption(
      selectedOptionObject ? selectedOptionObject.leadSource : null
    );
  };
  const [addedByName, setAddedByName] = useState('')
  const getUserNamebyId = async (id, loggedInuser) => {
    const res = await getReq(`${apiLinks.crm}/user/getUserById?userId=${id}`);
    if (res && !res.error) {
      // dispatch(setUserData(res?.data?.data));
      if (loggedInuser) {
        dispatch(setUserData(res?.data?.data));
      }
      setAddedByName(res?.data?.data?.fullName)
    } else {
      console.log(res.error);
    }
  };

  const deleteLead = async (formData) => {
    const res = await postReq(
      `${expf.proapi}/user/edit-lead?id=${activeLead?._id
      }&userId=${localStorage.getItem("userId")}`,
      {
        isDeleted: true,
        deletedBy: localStorage.getItem("userId"),
      }
    );
    if (res && !res.error) {
      notification.success({
        message: res?.message || "Delete Lead Successfully",
        duration: 1,
      });
      // form.resetFields();
      navigate("/leads");
      handleDeletModal();
      // after delete we again fetch data
      // apifetch();
    } else {
      console.log(res.error);
      notification.error({
        message: res?.message || "Oops Something Went Wrong",
        duration: 1,
      });
    }
  };

  const items = [
    {
      label: (
        <div className={styles.lockBox}><a rel="noopener noreferrer" onClick={() => showModal()}>
          Edit Lead
        </a>
          {!userData?.rolesAssigned?.crm?.edit && <Tooltip title="Access Disabled by the Admin">
            <img src={LockBlack} alt="LockImg"></img>
          </Tooltip>}
        </div>
      ),
      key: "0",
      disabled: !userData?.rolesAssigned?.crm?.edit,
    },
    {
      label: (<div className={styles.lockBox}>
        <a
          rel="noopener noreferrer"
          onClick={() => {
            dispatch(setIsChecked(true));
            setCreateProjectModalOpen(true);
            projectDataObj.firstName = data.firstName ? data.firstName : data.name
            projectDataObj.lastName = data.lastName
            projectDataObj.address = data.address
            projectDataObj.phnNum = data.phoneNumber
            projectDataObj.email = data.email
            projectDataObj.code = data.pinCode
            projectDataObj.city = data.city
            dispatch(projectDataCurrObj(projectDataObj))
          }
          }
        >
          Convert to Project
        </a>
        {!userData?.rolesAssigned?.pmt?.canCreateProject && <Tooltip title="Access Disabled by the Admin">
          <img src={LockBlack} alt="LockImg"></img>
        </Tooltip>}
      </div>
      ),
      key: "4",
      disabled: !userData?.rolesAssigned?.pmt?.canCreateProject,
    },
    {
      label: (
        <div className={styles.lockBox}>
          <a rel="noopener noreferrer" onClick={() => showDeleteModal()}>
            Delete Lead
          </a>
          {!userData?.rolesAssigned?.crm?.edit && <Tooltip title="Access Disabled by the Admin">
            <img src={LockBlack} alt="LockImg"></img>
          </Tooltip>}</div>
      ),
      key: "1",
      disabled: !userData?.rolesAssigned?.crm?.edit,
    },
  ];

  const {
    isBCCheckListSend,
    address,
    budget,
    config,
    email,
    assignedTo,
    possessionStatus,
    leadUpdates,
    leadStatus,
    leadstatus,
    showAlert,
    sourceName,
    setMyLeadStatus,
    phoneNumber,
    setLeads,
    leadSource,
    name,
    clientDetails,
    balcony,
    projectId,
    clientAndPropertyDetails,
    clientFamilyInfo,
    dining,
    foyer,
    GBR,
    information,
    KBR,
    kitchen,
    living,
    MBR,
    meetingDetails,
    otherDetails,
    PBR,
    leadStage,
    projectInfo,
    utility,
    renovationTimeline,
    requirements,
    updatedAt,
    message,
    clouserMonth,
    userId,
    createdAt,
  } = data;

  let projectDataObj = {
    firstName: "",
    lastName: "",
    projectName: "",
    address: "",
    city: "",
    code: "",
    phnNum: "",
    email: "",
    document: "",
    documentLink: "",
  };
  const getUserbyId = async (id) => {
    const res = await getReq(`${apiLinks.crm}/user/getUserById?userId=${id}`);
    if (res && !res.error) {
      setUserData(res?.data?.data);
    } else {
      console.log(res.error);
    }
  };
  useEffect(() => {
    getUserbyId();
    setData(selectedLeadsData);
    setDetailRenderer(!detailRenderer);
  }, [selectedLeadsData?.leadUpdates?.length]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleTrue = () => {
    setShouldAnimatee(true);
  };

  const updateLeadStatus = async (e) => {
    try {
      const res = await postReq(expf?.dev + "/user/newleadActions", {
        leadId: activeLead?._id,
        leadStatus: e,
      });

      if (res && !res?.error) {
        let y = {
          ...selectedLeadsData,
          leadStatus: res?.data?.data?.leadStatus,
        };
        setData(y);
        dispatch(selectedLeadData(y));
        showAlert("You have successfully updated your lead status ", "Okay");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [quotationCount, setQuotationCount] = useState(0);
  const getQuatationDetails = async () => {
    const res = await getReq(
      `${apiLinks.boqApi}/api/main-quotation/get-all-boq-projectId?projectId=${data?.projectId
      }&type=1&filter=1&userId=${localStorage.getItem("userId")}`
    );
    if (res && !res.error) {
      const isQuoatationSendToClient = res.data?.filter(
        (item) => item.clientId
      );
      console.log("isQuoatationSendToClient", isQuoatationSendToClient?.length);
      setQuotationCount(isQuoatationSendToClient?.length);
      // const quoLength = isQuoatationSendToClient?.length;
      // return quoLength
      // dispatch(setUserData(res?.data?.data));
    } else {
      console.log(res.error);
    }
  };
  const individualScore = {
    refferals: 20,
    budget: 20,
    "Possession unclear": 0,
    "Possession ready": 20,
    "Possession in a month": 15,
    "Possession in 3-6 month": 10,
    "Possession in 6-12 month": 5,
    isFloorPlanReceived: 10,
    isWhatsappGroupCreated: 10,
    isSiteVisitReceived: 10,
    isStudioVisit: 10,
  };
  let score = 0;

  const calculateLeadScore = () => {
    if (clientAndPropertyDetails?.leadSource?.includes("Referral")) {
      score += 20;
    }

    if (clientDetails?.budget) {
      score += 20;
    }
    if (clientDetails?.possessionStatus) {
      score += individualScore[clientDetails?.possessionStatus];
    }
    if (otherDetails?.isFloorPlanReceived == "Yes") {
      score += 10;
    }
    if (otherDetails?.isWhatsappGroupCreated == "Yes") {
      score += 10;
    }
    if (otherDetails?.isSiteVisitReceived == "Yes") {
      score += 10;
    }

    if (otherDetails?.isStudioVisit === "Yes") {
      score += 10;
    }
    return score;
  };
  const calculateLeadStatus = () => {
    if (tab == "9") {
      return "Converted";
    } else {
      if (quotationCount > 0) {
        return "Prospected Lead";
      } else if (isBCCheckListSend) {
        return "Qualified";
      } else if (leadStatus && leadStatus > 1) {
        return "Contacted";
      } else {
        return "Open";
      }
    }
  };
  const monthChange = async (val) => {
    const res = await postReq(
      `${expf.proapi}/user/edit-lead?id=${selectedLeadsData?._id
      }&userId=${localStorage.getItem("userId")}`,
      {
        // leadId: activeLead?._id,
        clouserMonth: val,
      }
    );
    if (res && !res.error) {
      // dispatch(selectedLeadData({...selectedLeadsData, clouserMonth:val}))
      setData({ ...data, clouserMonth: val });
      dispatch(updateClosureMonth({ ...selectedLeadsData, clouserMonth: val }));
      notification.success({
        message: res?.message || "Closure Updated Successfully",
        duration: 1,
      });
      // apifetch();
    } else {
      console.log(res.error);
      notification.error({
        message: res?.message || "Oops Something Went Wrong",
        duration: 1,
      });
    }
  };
  const apifetch = async () => {
    let res;
    if (tab == "9") {
      res = await getReq(expf.dev + "/user/newLeadsData?pageNo=0&type=9");
      console.log("responseleadetails11", res);
    } else {
      res = await getReq(expf.dev + "/user/newLeadsData?pageNo=0");
      console.log("responseleadetails22", res);
    }
    if (res && !res.error) {
      setLoadingFetchApi(false)
      const leadData = res?.data?.data?.data.find((d) => d._id === id);
      leadData?.projectId &&
        localStorage.setItem("projectId", leadData?.projectId);
      dispatch(selectedLeadData(leadData));
      setData(leadData);
      setActiveLeadState(leadData);
      // window.location.reload()
      // setLeads(res?.data?.data);
    }
  };
  const getProfileData = async () => {

    const res = await getReq(
      `${apiLinks.crm}/user/profile`,
      {},
      { Authorization: `Bearer ${getToken()}` }
    );
    if (res && !res.error) {
      dispatch(setProfileData(res.data.data))
      setLoadinguserDataApi(false)
    } else {
      // console.log(res.error);
      // localStorage.clear();
      // window.location.assign(`${BASE_URL}`);
    }
  };

  useEffect(() => {
    getUserNamebyId(localStorage.getItem('userId'), true)
    getUserNamebyId(data?.userId, false)

    getProfileData()
    // localStorage.removeItem("projectId");
    // localStorage.removeItem('visited')
    // localStorage.setItem("selectedLeadId", data?._id);
    apifetch();
    getQuatationDetails();

    console.log("data.projectId", data.projectId);
    if (data.projectId) {
      getQuatationDetails();
      localStorage.setItem("projectId", data.projectId);
      localStorage.setItem("selectedLeadId", data?._id);
    }
  }, []);
  const normalizeValue = (value) => {
    // Just replace the following regex to what you wnat
    const filteredValue = value.replace(/[a-zA-Z]/g, "");
    return filteredValue;
  };
  const normalizeBudgetValue = (value) => {
    // Just replace the following regex to what you wnat
    const filteredValue = value.replace(
      /[a-zA-Z\!\@\#\$\%\^\&\*\(\_\+\=\)\-]/g,
      ""
    );
    return filteredValue;
  };

  console.log('selectedLeaddata', selectedLeadsData);
  const tempclientDetails= {
    purpose: "",
    name:"",
    propertyName: "",
    propertyCategory: "",
    propertySubcategory: "",
    possessionStatus: "",
    budget: "",
    briefCall: "",
  }

  const tempclientAndPropertyDetails = {
    clientName: "",
    contactNumber: "",
    alternateContactNumber: "",
    emailId: "",
    businessType: "",
    propertyName: "",
    geographicalLocation: "",
    possessionStatus: "",
    flatNumber: "",
    city: "",
    clientProfession: "",
    propertyAddress: "",
    typeOfProperty: "",
    leadSource: "",
    propertyOrientation: "",
    dateOfBC: "",
  }
  
  useEffect(() => {
    balcony && dispatch(setBalcony(balcony));
    clientAndPropertyDetails ?
      dispatch(setclientAndPropertyDetails({...clientAndPropertyDetails, contactNumber:selectedLeadsData.phoneNumber,emailId:selectedLeadsData.email})):dispatch(setclientAndPropertyDetails({...tempclientAndPropertyDetails, contactNumber:selectedLeadsData.phoneNumber,emailId:selectedLeadsData.email}));
    clientDetails ?dispatch(setclientDetails({...clientDetails, budget :selectedLeadsData.budget,possessionStatus:selectedLeadsData.possessionStatus })): dispatch(setclientDetails({...tempclientDetails, budget :selectedLeadsData.budget,possessionStatus:selectedLeadsData.possessionStatus }));
    clientFamilyInfo && dispatch(setclientFamilyInfo(clientFamilyInfo));
    dining && dispatch(setdining(dining));
    foyer && dispatch(setfoyer(foyer));
    GBR && dispatch(setgbr(GBR));
    information && dispatch(setinformation(information));
    KBR && dispatch(setkbr(KBR));
    kitchen && dispatch(setkitchen(kitchen));
    living && dispatch(setliving(living));
    MBR && dispatch(setmbr(MBR));
    meetingDetails && dispatch(setmeetingDetails(meetingDetails));
    otherDetails && dispatch(setotherDetails(otherDetails));
    PBR && dispatch(setpbr(PBR));
    projectInfo && dispatch(setprojectInfo(projectInfo));
    utility && dispatch(setutility(utility));
  }, [data]);

  const toolTipData = [
    { label: "Referrals", score: 20, isReceived: clientAndPropertyDetails && clientAndPropertyDetails?.leadSource?.includes('Refferal') },
    {
      label: "Budget Shared",
      score: 10,
      isReceived: clientDetails && clientDetails?.budget !== ''
    },
    {
      label: "Possession Status",
      score: 20,
      isReceived: clientDetails && clientDetails.possessionStatus !== ''
    },
    {
      label: "Floor Plan Received",
      score: 10,
      isReceived: otherDetails && otherDetails.isFloorPlanReceived !== ''
    },
    {
      label: "WhatsApp Group Created",
      score: 10,
      isReceived: otherDetails && otherDetails.isWhatsappGroupCreated !== ''
    },
    {
      label: "Studio Visit",
      score: 10,
      isReceived: otherDetails && otherDetails.isStudioVisit !== ''
    },
    {
      label: "Site Visit",
      score: 10,
      isReceived: otherDetails && otherDetails.isSiteVisitReceived !== ''
    },
  ];

  const tooltipContent = (
    <div className={styles.tooltipContainer}>
      {toolTipData?.map((item) => (
        <div className={styles.tooltipItemContainer}>
          <div style={{ textDecoration: Boolean(item.isReceived) ? "line-through" : 'none' }} className={styles.tooltipLabelCss}>{item.label}</div>
          <div style={{ textDecoration: Boolean(item.isReceived) ? "line-through" : 'none' }} className={styles.tooltipScoreCss}>{item.score}</div>
        </div>
      ))}
    </div>
  );
  return (
    <>
    {isModalOpen &&
      <EditLeadModal show={isModalOpen} onHide={handleCancel} />}
      <Modal
        title={false}
        show={isdeleteModal}
        centered
        width={300}
        bodyStyle={{ height: 60 }}
        closable={false}
      >
        <h6 style={{ padding: "3%" }}>Do you want to delete the lead ?</h6>
        <Modal.Footer>
          <div className={styles.discardCloseBtn}>
            <button className={styles.createModalSave} onClick={handleOk}>
              <span
                style={{
                  color: "#FFF",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                Yes{" "}
              </span>
            </button>
            <button
              className={styles.createModalClose}
              onClick={handleDeletModal}
            >
              <span
                style={{
                  color: "#C9C9C9",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                No{" "}
              </span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <div className={`${styles.main_cont}`}>
        <TopHeaderCommon />
        <ProjectHeader
          handleTrue={handleTrue}
          setUpdatePanel={setUpdatePanel}
          leadsDetail={true}
          selectedLeadData={selectedLeadData}
        />
      </div>
      <div
        className={`${styles.firstFrameContainer} d-flex`}
        style={{ marginTop: "6em" }}
      >
        <div
          style={{
            position: "fixed",
            top: "6rem",
            left: "0px",
            // zIndex:'-1'
            width: shouldAnimatee ? "0%" : "4rem"

          }}
        >
          <SideProjectPanel
            showSide="true"
            shouldAnimatee={shouldAnimatee}
            leadDetail={true}
          />
        </div>

        {/* Main container page */}
        {(loadinguserDataApi && loadingApiFetch) ?
          <div
            className=""
            style={{
              position: 'relative',
              marginTop: '25vh',
              marginLeft: '45vw',
              // transform: 'translate(-50%, -50%)'
            }}
          >

            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#0084EF"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div> :
          <div style={{ left: !sidebarToggle ? '0rem' : "3rem", position: "relative" }}>
            <div className={`${styles.firstContainer}`}>
              <div className={`${styles.topBar}`}>
                <div className={`${styles.lefttopBar}`}>
                  <div
                    style={{
                      color: "#454545",
                      fontFamily: "Inter",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    {name}
                  </div>
                  <div>
                    {!leadStatusItem[valueToLeadStatus[leadStatus]]?.label ? (
                      <button
                        type="button"
                        className="btn leadStatusDropdownbutton new-bg bg-base-blue box-shadow-none py-1 px-1"
                        style={{
                          minWidth: "60px",
                        }}
                      >
                        {"New Lead"}
                      </button>
                    ) : (
                      <button
                        type="button"
                        id="dropdownMenuButton"
                        data-mdb-toggle="dropdown"
                        className={`btn leadStatusDropdownbutton btn-primary dropdown-toggle ${leadStatus === 6
                          ? "bg-no-response"
                          : leadStatus === 2
                            ? "bg-follow-up"
                            : leadStatus === 1
                              ? "bg-base-blue"
                              : leadStatus === 3
                                ? "bg-interested"
                                : leadStatus === 7
                                  ? "bg-meeting-scheduled"
                                  : leadStatus === 5
                                    ? "bg-not-interested"
                                    : "bg-base-blue"
                          } box-shadow-none py-1 px-1`}
                      >
                        {leadStatusItem[valueToLeadStatus[leadStatus]]?.label ||
                          "New Lead"}
                        <BiChevronDown className="mx-0" />{!userData?.rolesAssigned?.crm?.edit && <Tooltip title="Access Disabled by the Admin">
                          <img src={LockBlack} alt="LockImg"></img>
                        </Tooltip>}
                      </button>
                    )}
                    {userData?.rolesAssigned?.crm?.edit && <ul className={`dropdown-menu ${styles.leadStatushovercontaineer}`}>
                      {leadStatus !== 2 && (
                        <li>
                          <a
                            className={`dropdown-item ${styles.leadStatushover}`}
                            style={{ color: "#000000" }}
                            // href="#"
                            onClick={() => {
                              // setActualLeadStatus(leadstatus[e]?.value);
                              // setMyLeadStatus(e);
                              updateLeadStatus(2);
                            }}
                          >
                            Inactive/Follow Up
                          </a>
                        </li>
                      )}
                      {leadStatus !== 3 && (
                        <li>
                          <a
                            className={`dropdown-item ${styles.leadStatushover}`}
                            style={{ color: "#000000" }}
                            onClick={() => updateLeadStatus(3)}
                          >
                            Interested
                          </a>
                        </li>
                      )}
                      {leadStatus !== 5 && (
                        <li>
                          <a
                            className={`dropdown-item ${styles.leadStatushover}`}
                            style={{ color: "#000000" }}
                            onClick={() => updateLeadStatus(5)}
                          >
                            Not Interested
                          </a>
                        </li>
                      )}
                      {leadStatus !== 6 && (
                        <li>
                          <a
                            className={`dropdown-item ${styles.leadStatushover}`}
                            style={{ color: "#000000" }}
                            onClick={() => updateLeadStatus(6)}
                          >
                            No Response
                          </a>
                        </li>
                      )}
                    </ul>}
                  </div>
                  <div>
                    <span className={styles.columnText}>Expected Closure:</span>
                    <Select
                      // placeholder="Select Month"
                      defaultValue={"Select Month"}
                      value={clouserMonth}
                      disabled={!userData?.rolesAssigned?.crm?.edit}
                      bordered={false}
                      style={{
                        width: "max-content",
                        fontSize: "12px",
                      }}
                      className="antd-selectt"
                      onClick={(e) => e.stopPropagation()}
                      onChange={(value) => {
                        monthChange(value);
                      }}
                      options={[
                        { value: "Jan", label: "Jan" },
                        { value: "Feb", label: "Feb" },
                        { value: "Mar", label: "March" },
                        { value: "April", label: "April" },
                        { value: "May", label: "May" },
                        { value: "June", label: "June" },
                        { value: "July", label: "July" },
                        { value: "Aug", label: "Aug" },
                        { value: "Sept", label: "Sept" },
                        { value: "Oct", label: "Oct" },
                        { value: "Nov", label: "Nov" },
                        { value: "Dec", label: "Dec" },
                      ]}
                    />
                  </div>
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items,
                    }}
                    onClick={() => dispatch(currentLead(activeLead))}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        <FaEllipsisV style={{ cursor: "pointer" }} color="#343330" />
                      </Space>
                    </a>
                  </Dropdown>
                </div>

                <div className={`${styles.leadScore}`}>
                  <div>{`Lead Score : ${calculateLeadScore()}/100`}</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip
                      title={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#00000040",
                            cursor: "pointer",
                          }}
                        >
                          {tooltipContent}
                        </div>
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#00000040",
                        cursor: "pointer",
                      }}
                    >
                      <MdInfoOutline />
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className={styles.addedByContainer}>
                Added By: {userId ? addedByName : "-"}
              </div>
              <div className={styles.midContainer}>
                <div style={{ padding: "0 1px" }}>
                  <div>
                    <PiPhoneCallLight />
                    <span>&nbsp;{phoneNumber}</span>
                  </div>
                  <div>
                    <CiMail />
                    <span>&nbsp;{email}</span>
                  </div>
                  <div>
                    <CiLocationOn />
                    <span>&nbsp;{address}</span>
                  </div>
                </div>
                <div style={{ padding: "0 1px" }}>
                  <span className={styles.columnText}>Budget:</span> {` ${profileData?.CompanySettingDetails?.currencySymbol}${budget ? budget : "-"
                    }`}
                  <div><span className={styles.columnText}>Work Type:</span> {requirements ? requirements : "-"}</div>
                  <div><span className={styles.columnText}>Source Name:</span> {sourceName ? sourceName : "-"}</div>
                  {/* <div>
                    <span className={styles.columnText}>Message:</span>{" "}
                    {message ? message : "-"}
                  </div> */}
                </div>
                <div style={{ padding: "0 1px" }}>
                  <div><span className={styles.columnText}>Assigned To:</span>{` ${assignedTo?.fullName ? assignedTo?.fullName : "-"
                    }`}</div>
                  <div><span className={styles.columnText}>Last Call:</span>{` ${leadUpdates &&
                    leadUpdates[leadUpdates?.length - 1]?.updateDate
                    ? leadUpdates[leadUpdates?.length - 1]?.updateDate
                    : "-"
                    }`}</div>
                  <div className="text-capitalize">
                    <span className={styles.columnText}>Call Outcome:</span>{" "}
                    {leadUpdates &&
                      valueToLeadStatusFormatted[
                      Number(leadUpdates[leadUpdates?.length - 1]?.callLeadStatus)
                      ]
                      ? valueToLeadStatusFormatted[
                      Number(
                        leadUpdates[leadUpdates?.length - 1]?.callLeadStatus
                      )
                      ]
                      : "-"}
                  </div>
                  <div>
                    <span className={styles.columnText}>Possession Status:</span>{" "}
                    {possessionStatus}
                    {/* {clientDetails && clientDetails.possessionStatus !=='' ? clientDetails.possessionStatus : "-"} */}
                  </div>
                </div>
              </div>
              <div style={{height:'12vh', overflow:'scroll'}}>
                <span className={styles.columnText}>Message:</span>{" "}
                {message ? message : "-"}
              </div>
            </div>
            <div className={`${styles.secondContainer}`}>
              <div className={`${styles.tabs__section}`}>
                <div className="d-flex">
                  <div
                    className={`${openedTab === "interaction"
                      ? styles.tab__selected
                      : styles.tab__not__selcted
                      }`}
                    onClick={() => {
                      setOpenedTab("interaction");
                    }}
                  >

                    Interaction
                  </div>
                  <div
                    className={`${openedTab === "logs"
                      ? styles.tab__selected
                      : styles.tab__not__selcted
                      } ${styles.marginLeft}`}
                    style={{ marginLeft: "1rem" }}
                    onClick={() => {
                      setOpenedTab("logs");
                    }}
                  >
                    Logs
                  </div>
                  {!(data?.leadStatus == 1) && <div
                    className={`${openedTab === "additionalDetails"
                      ? styles.tab__selected
                      : styles.tab__not__selcted
                      } ${styles.marginLeft}`}
                    style={{ marginLeft: "1rem" }}
                    onClick={() => {
                      setOpenedTab("additionalDetails");
                    }}
                  >
                    Breifing Call Checklist
                  </div>}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Status:{" "}
                  <span className={styles.statusCss}>
                    {/* {score < 50 ? "Open" : score < 60 ? "Contacted" : "Qualified"} */}
                    {leadStage}
                  </span>
                </div>
              </div>

              {openedTab === "interaction" ? (
                <Interaction />
              ) : openedTab === "logs" ? (
                <Logs />
              ) : (
                <AdditionalDetails score={score} data={data} apifetch={apifetch} />
              )}
            </div>
            {addLeadModalOpen &&
            <AddLeadModal />}
            <Modal
              // title="Update Lead"
              // width="max-content"
              // closable={true}
              show={updatePanel}
              // style={{ top: "33px" }}
              dialogClassName={styles.modalWidth}
              // footer={[]}
              onHide={() => setUpdatePanel(false)}
            // size="lg"
            >
              {/* <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title>
                <span style={{ fontSize: "20px" }}>Update Project</span>
              </Modal.Title>
            </Modal.Header> */}
              <Modal.Body>
                {data?.assignedTo ? <LeadInfoSidePanel /> : <UnAssignedSidebardebar />}
              </Modal.Body>
            </Modal>
            <LmsMail />
          </div>
        }
      </div>
      {createProjectModalOpen && (
        <CreateNewProjectNew currentData={activeLead} />
      )}
    </>
  );
}

export default LeadsDetail;
