import { Dropdown } from "react-bootstrap";
import styles from '../styles/additionalInfo.module.css'
import { BiChevronDown } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";

function DropdownComponent({
  elementArr,
  selectedElement,
  type,
  clearFilterOptions,
  handleChange,
}) {


  return (
    <Dropdown>
      <Dropdown.Toggle
        className={styles.dropdownButton}
        variant="outline-secondary"
        id="dropdown-basic"
      >
        <span className={styles.buttonText}>
          {selectedElement && selectedElement[type] ? selectedElement[type] : "Select"}
        </span>{" "}
        <BiChevronDown size={20} color="#74737F" />
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.filterDropdownContainer}>
        {elementArr?.map((element, index) => {
          return (
            <Dropdown.Item key={index} onClick={() => handleChange(type, element)} className="d-flex">
              {element}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownComponent;