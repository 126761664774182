import React, { useContext } from "react";
import styles from "../styles/additionalInfo.module.css";
import { useSelector } from "react-redux"
import desquoContext from "../../../../picontext/DashBoard/desquoContext";

function InputBoxComponent({ selectedElement, placeholder, type, handleChange }) {

    const context = useContext(desquoContext);
    const { setMailStatus, activeLead } = context;
    if (selectedElement) {
        console.log('InputBoxComponentname', selectedElement[type])
    }
    const clientDetails = useSelector(
        (state) => state.additionalInfoSlice.clientDetails
    );

    // const keyMapping = {
    //     'clientName':'name',
    //     'contactNumber':'phoneNumber',
    //     'emailId':'email'

    // }
    const somePrefilledtypes = ['contactNumber', 'emailId']
    return (
        <>
            <div>  <input
                onChange={(e) => handleChange(type, e.target.value)}
                placeholder={placeholder}
                type="text"
                className={styles.inputBoxStyle}
                value={selectedElement && selectedElement[type]}
            />

            </div>
        </>
    );
}

export default InputBoxComponent;
