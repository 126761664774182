import React, { useEffect, useState } from "react";
import styles from "./InteractionAndLogs.module.css";
import { getReq } from "../../../piservices/apis";
import { apiLinks } from "../../../piservices/constants";
import { useSelector } from "react-redux";
import { formateDate } from "../../../piservices/compCommon";
import { useParams } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner'


// Read More react component

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div className="text">
      {isReadMore ? text.slice(0, 350) : text}
      <span onClick={toggleReadMore} style={{color:'#0084EF', cursor:'pointer'}}>
        {isReadMore ? "...read more" : " show less"}
      </span>
    </div>
  );
};

// interactioon functional component
function InteractionComponent({ interactionDate, interactions }) {
  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.leftContainer}`}>{interactionDate && interactionDate}</div>
        <div className={`${styles.rightContainer}`}>
          {interactions[interactionDate].map((it, index) => <div className={styles.subRightContainer}>
            <div className={`${styles.meetingContainer}`}>
              <div style={{width:'20rem'}}>{`Meeting Mode : ${it.meetingMode}`}</div>
              <div style={{width:'20rem'}}>{`Outcome : ${it.outcome}`}</div>
              <div >{`Added BY : ${it.userId?.fullName}`}</div>
            </div>
            <div className={`${styles.meetingRemarksContainer}`}>
              <div className={`${styles.meetingRemarks}`}>Meeting Remarks</div>
              {it?.meetingRemarks?.length>250 ?<ReadMore>{it.meetingRemarks}</ReadMore>:it.meetingRemarks}
            </div>{index+1 !== interactions[interactionDate].length && <hr />}
          </div>)}
        </div>
      </div>
    </>
  )
}

// main interaction component
function Interaction() {
  const {id} = useParams()
  const selectedLeadData = useSelector(
    (state) => state.selectedLeadSlice.selectedLeadData
  );
  const [interactions, setInteractions] = useState([])
  const [loading, setLoading] = useState(false)


  const getInteractionData = async () => {
    setLoading(true);
    const res = await getReq(
      `${apiLinks.crm}/user/get-lead-interactions?leadId=${id}`
    );
    if (res && !res.error) {
      setLoading(false);
      const groupByCreatedDate = res?.data?.data.reduce(
        (group, product) => {
          const { createdAt } = product;
          const formatedDate = formateDate(createdAt)
          group[formatedDate] = group[formatedDate] ?? [];
          group[formatedDate].push(product);
          return group;
        },
        {}
      );
      setInteractions(groupByCreatedDate);
    }
    // else {
    //   console.log(res.error);
    //   localStorage.clear();
    //   window.location.assign(`${BASE_URL_ESS}`);
    // }
  };
  useEffect(() => {
    // setInteractions([])
    getInteractionData()
  }, [selectedLeadData])
  return (
    <>
      {loading ? <div
        className=""
        style={{
          position: 'relative',
          marginTop: '17vh',
          marginLeft: '40vw',
          // transform: 'translate(-50%, -50%)'
        }}
      >
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#0084EF"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div> : <div className={`${styles.mainContainer}`}>
        {
         interactions && Object.keys(interactions).length > 0 ? Object.keys(interactions).map((interactionDate, index) => <InteractionComponent interactions={interactions} key={index} interactionDate={interactionDate} />):<h4>There is no interactions</h4>
        }
      </div>}
    </>
  );
}

export default Interaction;
