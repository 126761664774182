import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { postReq, getReq } from '../../../../piservices/apis';
import expf, { apiLinks } from '../../../../piservices/constants';
import desquoContext from '../../../../picontext/DashBoard/desquoContext';
import { Dropdown, Space, Modal, Form, Input, Button, Select, notification } from 'antd';
import axios from 'axios';

function EditModal() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [teamLeadArr, setTeamLeadArr] = useState([]);
    const [userData, setUserData] = useState({});
    const [form] = Form.useForm()
    const context = useContext(desquoContext);
    const { activeLead, setActiveLeadState, leads, leadStatusItem, setLeads } = context;
    const selectedLeadsData = useSelector(
        (state) => state.selectedLeadSlice.selectedLeadData
      );

    const reqbudget = [
        { value: "Full Design services", label: "Full Design services" },
        { value: "Architecture Design Services", label: "Architecture Design Services" },
        { value: "Interior Design Services", label: "Interior Design Services" },
        { value: "Landscape Service", label: "Landscape Service" },
        { value: "FFE", label: "FFE" },
        { value: "Renovation of the house", label: "Renovation of the house" },
        { value: "Styling of house", label: "Styling of house" },
      ];

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const editLead = async (formData) => {
        const res = await postReq(
            `${expf.proapi}/user/edit-lead?id=${activeLead?._id}`,
            {
                // leadId: activeLead?._id,
                assignedTo: formData?.assignedTo,
                name: formData?.name ? formData?.name : activeLead?.name,
                email: formData?.email ? formData?.email : activeLead?.email,
                phoneNumber: formData?.phoneNumber,
                address: formData?.address ? formData?.address : activeLead?.address,
                requirements: formData?.requirements
                    ? formData?.requirements
                    : activeLead?.city,
                budget: formData?.budget,
            }
        );
        if (res && !res.error) {
            notification.success({
                message: res?.message || "Edit Lead Successfully",
                duration: 1,
            });
            form.resetFields();
            // apifetch();
            handleCancel();
        } else {
            console.log(res.error);
            notification.error({
                message: res?.message || "Oops Something Went Wrong",
                duration: 1,
            });
        }
    };

    return (
        <Modal
            title="Edit Lead"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={false}
            style={{ top: "33px" }}
        >
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 22,
                }}
                style={{
                    maxWidth: 600,
                }}
                onFinish={editLead}
                initialValues={{
                    remember: true,
                }}
                layout="vertical"
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item label="Client Name" name="name" className="mb-2">
                    <Input defaultValue={activeLead ? activeLead?.name : ""} />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    className="mb-2"
                    rules={[
                        {
                            //required: true,
                            pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                            message: "Please input  email!",
                        },
                    ]}
                >
                <Input defaultValue={activeLead ? activeLead?.email : ""} />
                </Form.Item>
                <Form.Item label="Location" name="address" className="mb-2">
                    <Input defaultValue={activeLead ? activeLead?.address : ""} />
                </Form.Item>
                <Form.Item
                    label="Contact Number"
                    name="phoneNumber"
                    className="mb-2"
                    rules={[
                        {
                            pattern: new RegExp(
                                /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/gm
                            ),
                            message: "Please input Contact!",
                        },
                    ]}
                >
                    <Input defaultValue={activeLead ? activeLead?.phoneNumber : ""} />
                </Form.Item>
                <Form.Item label="Budget" name="budget" className="mb-2">
                    <Input defaultValue={activeLead ? activeLead?.budget : ""} />
                </Form.Item>
                <Form.Item label="Work Type" name="requirements" className="mb-2">
                    <Select
                        defaultValue={activeLead ? activeLead?.requirements : ""}
                        placeholder="Select Work Type"
                        // onChange={handleChange}
                        options={(reqbudget || []).map((d) => ({
                            value: d.value,
                            label: d.label,
                        }))}
                    />
                </Form.Item>

                <Form.Item
                    label="Lead Source"
                    name="leadSource"
                    className="mb-2"
                // rules={[
                //   {
                //     pattern: new RegExp(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/gm),
                //     message: 'Please input Contact!',
                //   },
                // ]}
                >
                    <Input defaultValue={activeLead ? activeLead?.leadSource : ""} />
                </Form.Item>

                <Form.Item
                    label="Source Name"
                    name="sourceName"
                    className="mb-2"
                // rules={[
                //   {
                //     pattern: new RegExp(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/gm),
                //     message: 'Please input Contact!',
                //   },
                // ]}
                >
                    <Input defaultValue={activeLead ? activeLead?.sourceName : ""} />
                </Form.Item>

                {userData?.rolesAssigned?.crm?.canAssign && (
                    <Form.Item label="Assign To" name="assignedTo">
                        <Select
                            defaultValue={
                                activeLead ? activeLead?.assignedTo?.fullName : ""
                            }
                            placeholder="Select Assign To"
                            // onChange={handleChange}
                            options={(
                                [{ _id: null, fullName: "UnAssigned" }, ...teamLeadArr] || []
                            ).map((d) => ({
                                value: d._id,
                                label: d.fullName,
                            }))}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    className="ms-0"
                    wrapperCol={{
                        // offset: 1,
                        span: 10,
                    }}
                >
                    <Button
                        type=""
                        htmlType="submit"
                        className="ms-0"
                        style={{
                            background: "rgb(23,78,134)",
                            color: "white",
                            borderRadius: "0.25rem",
                            fontSize: "13px",
                        }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditModal