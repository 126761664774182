import React, { useState } from 'react'
import DropdownComponent from '../DropdownComponent'
import styles from '../../styles/additionalInfo.module.css'
import data from "./Data";
import InputBoxComponent from '../InputBoxComponent';
import { useDispatch, useSelector } from 'react-redux'
import { setpbr } from '../../../../../Redux/Slices/additionalDetailsSlice';

function PBR() {
    // const budgetArr = ['A', 'B', 'C', 'D', 'E', 'F']
    // const [PBR, setPBR] = useState({
    //     swingWardrobe: '',
    //     slidingWardrobe: '',
    //     floorToCeilingWardrobe: '',
    //     queenBed: '',
    //     kingBed: '',
    //     bedsideTable: '',
    //     dresser: '',
    //     studyTable: '',
    //     TVUnit: '',
    //     seating: '',
    //     falseCeiling: '',
    //     bayWindowSeating: '',
    //     naturalWoodenFlooring: '',
    //     laminatedWoodenFlooring: '',
    //     partition: '',
    //     additionalInfo: '',
    // })
    const dispatch = useDispatch();
    const PBR = useSelector(state => state.additionalInfoSlice.PBR);

    const handleChange = (type, value) => {
        dispatch(setpbr({ ...PBR, [type]: value }));
    };
    return (
        <>
              <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Swing Wardrobe `}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="swingWardrobe"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Sliding Wardrobe `}</div>
                 <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="slidingWardrobe"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Floor to Ceiling Wardrobe `}</div>
                    <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="floorToCeilingWardrobe"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                >
                    <div
                        className={styles.filter_icon}

                    >{`Queen Bed `}</div>
                      <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="queenBed"
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`King Bed `}</div>
                      <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="kingBed"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Bedside Table `}</div>
                      <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="bedsideTable"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Dresser `}</div>
                       <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="dresser"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Study Table `}</div>
                       <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="studyTable"
                        handleChange={handleChange}
                    />
                </div>

            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`TV Unit `}</div>
                      <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="TVUnit"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Seating `}</div>
                      <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="seating"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`False Ceiling `}</div>
                       <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="falseCeiling"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Bay Window Seating `}</div>
                       <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="bayWindowSeating"
                        handleChange={handleChange}
                    />
                </div>

            </div>
            <div className="d-flex align-items-center">
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}
                    >{`Natural Wooden Flooring `}</div>
                      <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="naturalWoodenFlooring"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`LaminatedWooden Flooring`}</div>
                      <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="laminatedWoodenFlooring"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Partition`}</div>
                       <DropdownComponent
                        elementArr={data.COMMON_ARRAY}
                        // clearFilterOptions={clearFilterOptions}
                        selectedElement={PBR}
                        type="partition"
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginRight: "5rem" }}
                >
                    <div
                        className={styles.filter_icon}

                    >{`Additional Info `}</div>
                       <InputBoxComponent  selectedElement={PBR} type='additionalInfo' handleChange={handleChange} placeholder={"Any additional info"} />
                </div>

            </div>
        </>
    )
}

export default PBR