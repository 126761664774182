import ReactDOM from "react-dom";
import React, { useContext, useRef, useState } from "react";
import styles from "../styles/additionalInfo.module.css";
import caretCircleDown from "../../../../Assets/CaretCircleDown.svg";
import DropdownComponent from "./DropdownComponent";
import ClientDetails from "./subcomponents/ClientDetails";
import OtherDetails from "./subcomponents/OtherDetails";
import MeetingDetails from "./subcomponents/MeetingDetails";
import ClientAndPropertyDetails from "./subcomponents/ClientAndPropertyDetails";
import ClientFamilyInfo from "./subcomponents/ClientFamilyInfo";
import ProjectInfo from "./subcomponents/ProjectInfo";
import Information from "./subcomponents/Information";
import Foyer from "./subcomponents/Foyer";
import Living from "./subcomponents/Living";
import Dining from "./subcomponents/Dining";
import Kitchen from "./subcomponents/Kitchen";
import Utility from "./subcomponents/Utility";
import MBR from "./subcomponents/MBR";
import PBR from "./subcomponents/PBR";
import KBR from "./subcomponents/KBR";
import Balcony from "./subcomponents/Balcony";

import desquoContext from "../../../../picontext/DashBoard/desquoContext";
import { v4 as uuidv4 } from "uuid";
import GBR from "./subcomponents/GBR";
import { useDispatch, useSelector } from "react-redux";
import { postReq, putReq } from "../../../../piservices/apis";
import expf, { apiLinks } from "../../../../piservices/constants";
// import html2pdf from "html2pdf.js";
// import { useReactToPrint } from "react-to-print";
// import html2canvas from "html2canvas";
// import { saveAs } from 'file-saver';
import axios from "axios";
import {
  PDFViewer,
  PDFDownloadLink,
  Text,
  View,
  pdf,
  Document,
  BlobProvider,
  Page,
} from "@react-pdf/renderer";
// import { PDFViewer } from '@react-pdf/renderer';
// import { PDFViewer } from '@react-pdf-viewer/core';

import { notification } from "antd";
import jsPDF from "jspdf";
import { useSearchParams, useParams } from "react-router-dom";
import PDFComponentPage from "./PDFComponentPage";
import LmsMail from "../../LmsMail.js/LmsMail";
import { setPDF } from "../../../../Redux/Slices/additionalDetailsSlice";
import { setShowEmailModal } from "../../../../Redux/Slices/lmsSlice";

function AdditionalDetails({ data,apifetch }) {
  const [accordianOpenClose, setAccordianOpenClose] = useState({
    clientDetails: true,
    otherDetails: true,
    meetingDetails: true,
    clientAndPropertyDetails: true,
    clientFamilyInfo: true,
    projectInfo: true,
    information: true,
    foyer: true,
    living: true,
    dining: true,
    kitchen: true,
    utility: true,
    mBR: true,
    pBR: true,
    kBR: true,
    gBR: true,
    balcony: true,
  });
  const dispatch = useDispatch()
  const context = useContext(desquoContext);
  const { setMailStatus, activeLead } = context;
  console.log('activeleaddd', activeLead)
  const clientDetails = useSelector(
    (state) => state.additionalInfoSlice.clientDetails
  );
  const balcony = useSelector((state) => state.additionalInfoSlice.balcony);
  const clientAndPropertyDetails = useSelector(
    (state) => state.additionalInfoSlice.clientAndPropertyDetails
  );
  const clientFamilyInfo = useSelector(
    (state) => state.additionalInfoSlice.clientFamilyInfo
  );
  const dining = useSelector((state) => state.additionalInfoSlice.dining);
  const foyer = useSelector((state) => state.additionalInfoSlice.foyer);
  const gBR = useSelector((state) => state.additionalInfoSlice.GBR);
  const information = useSelector(
    (state) => state.additionalInfoSlice.information
  );
  const kBR = useSelector((state) => state.additionalInfoSlice.KBR);
  const kitchen = useSelector((state) => state.additionalInfoSlice.kitchen);
  const living = useSelector((state) => state.additionalInfoSlice.living);
  const mBR = useSelector((state) => state.additionalInfoSlice.MBR);
  const meetingDetails = useSelector(
    (state) => state.additionalInfoSlice.meetingDetails
  );
  const otherDetails = useSelector(
    (state) => state.additionalInfoSlice.otherDetails
  );
  const pBR = useSelector((state) => state.additionalInfoSlice.PBR);
  const projectInfo = useSelector(
    (state) => state.additionalInfoSlice.projectInfo
  );
  const utility = useSelector((state) => state.additionalInfoSlice.utility);
  const { id } = useParams();
  const htmlRef = useRef(null);

  const pdfOptions = {
    margin: 10,
    filename: "output.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };

  const handleOpenClose = (key) => {
    setAccordianOpenClose({
      ...accordianOpenClose,
      [key]: !accordianOpenClose[key],
    });
  };
  const saveButtonHandler = async () => {
    console.log("pdfsaving");
    const res = await postReq(
      `${expf.proapi}/user/edit-lead?id=${id}&userId=${localStorage.getItem(
        "userId"
      )}`,
      {
        clientDetails,
        balcony,
        clientAndPropertyDetails,
        clientFamilyInfo,
        dining,
        foyer,
        GBR: gBR,
        information,
        KBR: kBR,
        kitchen,
        living,
        MBR: mBR,
        meetingDetails,
        otherDetails,
        PBR: pBR,
        projectInfo,
        utility,
        email:clientAndPropertyDetails.emailId,
        budget:clientDetails.budget,
        phoneNumber:(clientAndPropertyDetails.contactNumber!=='' &&clientAndPropertyDetails.contactNumber !== activeLead?.phoneNumber)?clientAndPropertyDetails.contactNumber:activeLead?.phoneNumber,
        possessionStatus:clientDetails.possessionStatus!==''?clientDetails.possessionStatus:activeLead.possessionStatus
      }
    );

    if (res) {
      console.log("success");
      notification.success({
        message: res?.message || "Edit Lead Successfully",
        duration: 1,
      });
      apifetch()
    }
  };
  const uploadFile = async (pdf) => {
    // const { files } = event.target;
    let filesToUpload = new FormData();
    filesToUpload.append("files", pdf);
    const res = await putReq(`${apiLinks.s3api}/api/upload`, filesToUpload);
    if (res && !res.error) {
      console.log("responseeee", res);
    } else {
      console.log("errorrr");
      console.log(res.error);
    }
  };
  const handleSendEmail = async (pdfUrl) => {
    try {
      const res0 = await postReq(expf?.dev + "/user/lead-email", {
        to: [data.email],
        // "replyTo": "testing4200@gmmail.com",
        subject: "Lead Detail Pdf",
        emailBody: "Lead Detail Pdf body",
        attachments: [{
          filename: 'pdf',
          path: pdfUrl?.locations[0]
        }]
      });

      if (res0 && !res0.error) {
        notification.success({
          message: res0?.message || "Mail sent Successfully",
          duration: 1,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const saveS3ImageURLToLeadLogs = async (s3Link) => {
    await axios
      .post(`${apiLinks.crm}/user/save-lead-logs`, {
        leadId: id,
        userId: localStorage.getItem("userId"),
        logName: "Additional Details has been Edited",
        pdfLink: s3Link?.locations[0],
      })
      .then((res) => {
        console.log("response", res);
      })
      .catch((err) => {
        console.error("response", err);
      });
  };

  const saveToS3 = async (pdfBlob) => {
    console.log("ins33");
    const formData = new FormData();
    formData.append("files", pdfBlob);
    try {
      const response = await axios.put(
        `${apiLinks.s3api}/api/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important: Set the Content-Type header
          },
        }
      );
      saveS3ImageURLToLeadLogs(response.data);
      console.log("PDF saved to S3:", response.data);
    } catch (error) {
      console.error("Error saving PDF to S3:", error);
    }
  };

  const sendMailHandlersaveToS3 = async (pdfBlob) => {
    console.log("ins33");
    const formData = new FormData();
    formData.append("files", pdfBlob);
    try {
      const response = await axios.put(
        `${apiLinks.s3api}/api/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important: Set the Content-Type header
          },
        }
      );
      dispatch(setPDF([{
        filename: 'BC_pdf',
        path: response.data.locations[0]
      }]))
      // handleSendEmail(response.data);
      console.log("PDF saved to S3:", response.data);
    } catch (error) {
      console.error("Error saving PDF to S3:", error);
    }
  };

  console.log('dattaa', data)
  const sendPDFButtonHanler = async () => {
    // PDF is not saving, saving with zero files
    // const pdfBlob = await (
    //     <BlobProvider document={<Document><Page><View><Text style={{fontSize:''}}>UAT</Text></View></Page></Document>} fileName="output.pdf">
    //         {({ blob }) => blob}
    //     </BlobProvider>
    // );

    //  PDF is not saving, saving with zero files

    //using react-pdf
    // const pdfBlob = await (
    //     <PDFViewer>
    //       <Document>
    //         <Page>
    //           <PdfComponent />
    //         </Page>
    //       </Document>
    //     </PDFViewer>
    //   ).toBlob();

    // working code starts here
    // const content = htmlRef.current
    // html2canvas(content)
    //     .then((canvas) => {
    //         var imgData = canvas.toDataURL("image/jpeg", 1.0);
    //         var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
    //         var pdfWidth = pdf.internal.pageSize.getWidth();
    //         var pdfHeight = pdf.internal.pageSize.getHeight();
    //         pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //         // pdf.save("mypdf.pdf");
    //         const pdfBlob = pdf.output('blob');
    //         saveToS3(pdfBlob);
    //     })
    // working code ends here
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.buttonContainer}>
          {/* <PDFComponentPage /> */}
          <BlobProvider
            document={
              <PDFComponentPage
                activeLead={activeLead}
                data={data}
                clientFamilyInfo={clientFamilyInfo}
                utility={utility}
                projectInfo={projectInfo}
                pBR={pBR}
                otherDetails={otherDetails}
                meetingDetails={meetingDetails}
                mBR={mBR}
                living={living}
                kitchen={kitchen}
                kBR={kBR}
                information={information}
                gBR={gBR}
                foyer={foyer}
                dining={dining}
                clientAndPropertyDetails={clientAndPropertyDetails}
                balcony={balcony}
                clientDetails={clientDetails}
              />
            }
          >
            {({ blob, url, loading, error }) => (
              <div
                onClick={() => (
                  saveButtonHandler(),
                  saveToS3(
                    new File([blob], `${uuidv4()}output.pdf`, {
                      type: "application/pdf",
                    })
                  )
                )}
                className={styles.buttonStyle}
              >
                <span> Save</span>
              </div>
            )}
          </BlobProvider>
          <BlobProvider
            document={
              <PDFComponentPage
                data={data}
                clientFamilyInfo={clientFamilyInfo}
                utility={utility}
                projectInfo={projectInfo}
                pBR={pBR}
                otherDetails={otherDetails}
                meetingDetails={meetingDetails}
                mBR={mBR}
                living={living}
                kitchen={kitchen}
                kBR={kBR}
                information={information}
                gBR={gBR}
                foyer={foyer}
                dining={dining}
                clientAndPropertyDetails={clientAndPropertyDetails}
                balcony={balcony}
                clientDetails={clientDetails}
              />
            }
          >
            {({ blob, url, loading, error }) => (
              <div
                onClick={() => (
                  sendMailHandlersaveToS3(
                    new File([blob], `${uuidv4()}output.pdf`, {
                      type: "application/pdf",
                    })
                  ),
                  setMailStatus(),
                  dispatch(setShowEmailModal(true))
                )
                }
                className={styles.buttonStyle}
              >
                <span>Send via Mail</span>
              </div>
            )}
          </BlobProvider>
        </div>
        <div ref={htmlRef} id="pdfWrapper">
          {/* Client details card */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("clientDetails")}
                className={
                  accordianOpenClose.clientDetails
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Client details</div>
              {accordianOpenClose.clientDetails && (
                <div className={styles.cardSubBody}>
                  <ClientDetails />
                </div>
              )}
            </div>
          </div>
          {/* Meeting Details */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("meetingDetails")}
                className={
                  accordianOpenClose.meetingDetails
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Meeting details</div>
              {accordianOpenClose.meetingDetails && (
                <div className={styles.cardSubBody}>
                  <MeetingDetails />
                </div>
              )}
            </div>
          </div>
          {/* Other Details */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("otherDetails")}
                className={
                  accordianOpenClose.otherDetails
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Other details</div>
              {accordianOpenClose.otherDetails && (
                <div className={styles.cardSubBody}>
                  <OtherDetails />
                </div>
              )}
            </div>
          </div>

          {/* Client & Property Details */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("clientAndPropertyDetails")}
                className={
                  accordianOpenClose.clientAndPropertyDetails
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>
                Client and Property details
              </div>
              {accordianOpenClose.clientAndPropertyDetails && (
                <div className={styles.cardSubBody}>
                  <ClientAndPropertyDetails />
                </div>
              )}
            </div>
          </div>
          {/* Client family info */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("clientFamilyInfo")}
                className={
                  accordianOpenClose.clientFamilyInfo
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Client family Info</div>
              {accordianOpenClose.clientFamilyInfo && (
                <div className={styles.cardSubBody}>
                  <ClientFamilyInfo />
                </div>
              )}
            </div>
          </div>
          {/* Project Info */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("projectInfo")}
                className={
                  accordianOpenClose.projectInfo
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Project Info</div>
              {accordianOpenClose.projectInfo && (
                <div className={styles.cardSubBody}>
                  <ProjectInfo />
                </div>
              )}
            </div>
          </div>
          {/* Information */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("information")}
                className={
                  accordianOpenClose.information
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Designer's Checklist</div>
              {accordianOpenClose.information && (
                <div className={styles.cardSubBody}>
                  <Information />
                </div>
              )}
            </div>
          </div>
          {/* Foyer */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("foyer")}
                className={
                  accordianOpenClose.foyer
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Foyer</div>
              {accordianOpenClose.foyer && (
                <div className={styles.cardSubBody}>
                  <Foyer />
                </div>
              )}
            </div>
          </div>
          {/* Living */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("living")}
                className={
                  accordianOpenClose.living
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Living</div>
              {accordianOpenClose.living && (
                <div className={styles.cardSubBody}>
                  <Living />
                </div>
              )}
            </div>
          </div>
          {/* Dining */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("dining")}
                className={
                  accordianOpenClose.dining
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Dining</div>
              {accordianOpenClose.dining && (
                <div className={styles.cardSubBody}>
                  <Dining />
                </div>
              )}
            </div>
          </div>
          {/* Kitchen */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("kitchen")}
                className={
                  accordianOpenClose.kitchen
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Kitchen</div>
              {accordianOpenClose.kitchen && (
                <div className={styles.cardSubBody}>
                  <Kitchen />
                </div>
              )}
            </div>
          </div>
          {/* Utility */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("utility")}
                className={
                  accordianOpenClose.utility
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Utility</div>
              {accordianOpenClose.utility && (
                <div className={styles.cardSubBody}>
                  <Utility />
                </div>
              )}
            </div>
          </div>
          {/* MBR */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("mBR")}
                className={
                  accordianOpenClose.mBR
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Master Bed Room</div>
              {accordianOpenClose.mBR && (
                <div className={styles.cardSubBody}>
                  <MBR />
                </div>
              )}
            </div>
          </div>
          {/* PBR */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("pBR")}
                className={
                  accordianOpenClose.pBR
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Parent's Bed Room</div>
              {accordianOpenClose.pBR && (
                <div className={styles.cardSubBody}>
                  <PBR />
                </div>
              )}
            </div>
          </div>
          {/* KBR */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("kBR")}
                className={
                  accordianOpenClose.kBR
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Kid's Bed Room</div>
              {accordianOpenClose.kBR && (
                <div className={styles.cardSubBody}>
                  <KBR />
                </div>
              )}
            </div>
          </div>
          {/* GBR */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("gBR")}
                className={
                  accordianOpenClose.gBR
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Guest's Bed Room</div>
              {accordianOpenClose.gBR && (
                <div className={styles.cardSubBody}>
                  <GBR />
                </div>
              )}
            </div>
          </div>
          {/* Balcony */}
          <div className={styles.card}>
            <div className={styles.imageDiv}>
              {" "}
              <img
                src={caretCircleDown}
                onClick={() => handleOpenClose("balcony")}
                className={
                  accordianOpenClose.balcony
                    ? styles.imageRotateCssToDown
                    : styles.imageRotateCssToRight
                }
              />
            </div>

            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>Balcony</div>
              {accordianOpenClose.balcony && (
                <div className={styles.cardSubBody}>
                  <Balcony />
                </div>
              )}
            </div>
          </div>
        </div>
        <LmsMail />
      </div>
    </>
  );
}

export default AdditionalDetails;
