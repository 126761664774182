import React, { useContext, useEffect, useState } from 'react'
import './index.css'
import { useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { BiChevronDown, BiSort } from 'react-icons/bi'
import styles from '../Common/NewLayout/NewLayout.module.css'
import desquoContext from '../../picontext/DashBoard/desquoContext';
import { getReq } from '../../piservices/apis';
import expf from '../../piservices/constants';
import useApiFetchCustomHook from '../../piservices/ApiFetchCustomHook';

function DropdownComponent({ elementArr, selectedElement, type, handleChange }) {
    return (<Dropdown >
        <Dropdown.Toggle className={styles.headerDropdownButton} variant="outline-secondary" id="dropdown-basic">
            <BiChevronDown size={25} color='#74737F' />
        </Dropdown.Toggle>
        <Dropdown.Menu >
            <div className='d-flex'>
                <Dropdown.ItemText eventKey="4" style={{ float: 'left' }}>All Selected</Dropdown.ItemText>< br />
                <Dropdown.ItemText eventKey="4" style={{ textDecoration: 'underline', float: 'right', fontStyle: 'underline', cursor: 'pointer', color: 'red' }}>Reset</Dropdown.ItemText>
            </div>
            <Dropdown.Divider />
            <div className={styles.headerfilterDropdownContainer}>
                {elementArr?.map((t) => {
                    return (
                        <Dropdown.ItemText className='d-flex' >
                            <input
                                type="checkbox"
                                checked={t == 'All' ? selectedElement?.length+1 == elementArr?.length : selectedElement.includes(t)}
                                onChange={(e) => {
                                    handleChange(e, t, type)
                                    //  setActive(e.target.checked);
                                }}
                            />
                            <div className={styles.filterDropdownText} >
                                {t}
                            </div></Dropdown.ItemText>
                    )
                })} </div>
        </Dropdown.Menu>
    </Dropdown>)
}

// function BudgetDropdownComponent({ elementArr, selectedElement, type, handleChange }) {
//     return (<Dropdown key='down-centered' >
//         <Dropdown.Toggle className={styles.headerDropdownButton} variant="outline-secondary" id="dropdown-basic">
//             <BiChevronDown size={25} color='#74737F' />
//         </Dropdown.Toggle>
//         <Dropdown.Menu >
//             <div className='d-flex'>
//                 <Dropdown.ItemText eventKey="4" style={{ float: 'left' }}>All Selected</Dropdown.ItemText>< br />
//                 <Dropdown.ItemText eventKey="4" style={{ textDecoration: 'underline', float: 'right', fontStyle: 'underline', cursor: 'pointer', color: 'red' }}>Reset</Dropdown.ItemText>
//             </div>
//             <Dropdown.Divider />
//             <div className={styles.headerfilterDropdownContainer}>
//                 {elementArr?.map((t) => {
//                     return (
//                         <Dropdown.ItemText className='d-flex' >
//                             <input
//                                 type="checkbox"
//                                 checked={t == 'All' ? selectedElement?.length + 1 == elementArr?.length : selectedElement.includes(t)}
//                                 onChange={(e) => {
//                                     handleChange(e, t, 'budget')
//                                     //  setActive(e.target.checked);
//                                 }}
//                             />
//                             <div className={styles.filterDropdownText} >
//                                 {t}
//                             </div></Dropdown.ItemText>
//                     )
//                 })} </div>
//         </Dropdown.Menu>
//     </Dropdown>)
// }

const LmsTableTh = () => {
    const [selectedBudget, setSelectedBudget] = useState([]);
    const [selectedScope, setSelectedScope] = useState([]);
    const [selectedLeadSource, setSelectedLeadSource] = useState([]);
    const context = useContext(desquoContext);
    const { leads, leads2, setLeads, activeLead } = context;
    const [checkboxData, setCheckBoxData] = useState([]);
    const [finalBudget, setFinalBudget] = useState([]);
    const {apiFetch} =  useApiFetchCustomHook()

    const budgets = ['Under 1 Lakh', '1 Lakh - 5 Lakh', '5 Lakh - 10 Lakh', 'Over 10 Lakh']
    // const apifetch = async () => {
    //     const res = await getReq(
    //         expf.dev + "/user/newLeadsData?pageNo=0" + (tab > 0 ? "&type=" + tab : "")
    //     );
    //     if (res && !res.error) {
    //         setLeads({ ...res?.data?.data });
    //     }
    // };
    useEffect(() => {
        setCheckBoxData(leads2?.data)
    })

    //finding all the budgets
    const budgetArr = checkboxData
        ?.map((v) => v.budget)
        ?.filter((item, index, arr) => arr?.indexOf(item) === index)
    
    //finding all the scopes
    const scopeArr = checkboxData
        ?.map((v) => v.requirements)
        ?.filter((item, index, arr) => arr?.indexOf(item) === index)

    const sourceArr = checkboxData?.map((v) => v.leadSource)?.filter((item, index, arr) => arr?.indexOf(item) === index)

    function handleChange(e, t, type) {
        // if (type == 'area')
        //   {if (e.target.checked) {
        //     setSelectedArea([...selectedArea, t])
        //   } else {
        //     setSelectedArea(selectedArea.filter((e, i) => selectedArea.indexOf(t) !== i))
        //   }}
        //   if (type == 'status')
        //   {if (e.target.checked) {
        //     setSelectedStatus([...selectedStatus, t])
        //   } else {
        //     setSelectedStatus(selectedStatus.filter((e, i) => selectedStatus.indexOf(t) !== i))
        //   }}
        //   if (type == 'expectedClosure')
        //   {if (e.target.checked) {
        //     setSelectedExpectedClosure([...selectedExpectedClosure, t])
        //   } else {
        //     setSelectedExpectedClosure(selectedExpectedClosure.filter((e, i) => selectedExpectedClosure.indexOf(t) !== i))
        //   }}

        if (type == 'leadSource') {
            if (t == 'All') {
                if (e.target.checked) {
                    setSelectedLeadSource([...sourceArr])
                } else {
                    setSelectedLeadSource([])
                }

            } else {
                if (e.target.checked) {
                    setSelectedLeadSource([...selectedLeadSource, t])
                } else {
                    setSelectedLeadSource(selectedLeadSource.filter((e, i) => selectedLeadSource.indexOf(t) !== i))
                }
            }

        }

        if (type == 'requirements') {
            if (t == 'All') {
                if (e.target.checked) {
                    setSelectedScope([...scopeArr])
                } else {
                    setSelectedScope([])
                }

            } else {
                if (e.target.checked) {
                    setSelectedScope([...selectedScope, t])
                } else {
                    setSelectedScope(selectedScope.filter((e, i) => selectedScope.indexOf(t) !== i))
                }
            }

        }
        if (type == 'budget') {
            if (t == 'All') {
                if (e.target.checked) {
                    setSelectedBudget([...budgets])
                } else {
                    setSelectedBudget([])
                }

            } else {
                if (e.target.checked) {
                    setSelectedBudget([...selectedBudget, t])

                } else {
                    setSelectedBudget(selectedBudget.filter((e, i) => selectedBudget.indexOf(t) !== i))
                }
            }
        }
        //   if (type == 'assignedTo')
        //   {if (e.target.checked) {
        //     setSelectedAssignedTo([...selectedAssignedTo, t])
        //   } else {
        //     setSelectedAssignedTo(selectedAssignedTo.filter((e, i) => selectedAssignedTo.indexOf(t) !== i))
        //   }}
    }
    let { tab } = useParams();

    function convertBudgetToInt(bud) {
        const newBudget = bud?.replaceAll(',', '')
        return Number(newBudget)
    }
    const [finalBudget1, setFinalBudget1] = useState([])
    useEffect(() => {
        let abc =[]
        budgetArr.map((item, index, arr) => {
            if (selectedBudget?.includes('Under 1 Lakh') && 0 < convertBudgetToInt(item) < 100000) {
                // setFinalBudget1([...finalBudget1, item])
                abc.push(item)
            }
            if (selectedBudget?.includes('1 Lakh - 5 Lakh' && 100000 < convertBudgetToInt(item) < 500000)) {
                // setFinalBudget1([...finalBudget1, item])
                abc.push(item)
            }
            if (selectedBudget?.includes('5 Lakh - 10 Lakh') && 500000 < convertBudgetToInt(item) < 1000000) {
                // setFinalBudget1([...finalBudget1, item])
                abc.push(item)
            }
            if (selectedBudget?.includes('Over 10 Lakh') && 1000000 < convertBudgetToInt(item)) {
                // setFinalBudget1([...finalBudget1, item])
                abc.push(item)
            }
        })
            setFinalBudget1([...abc])
        // setFinalBudget([...finalBudget1])

    }, [selectedBudget])

    useEffect(() => {
        if (selectedScope?.length > 0 || selectedLeadSource?.length > 0 ) {
            const filterData = checkboxData.filter((item) => {
                return (
                    //   selectedArea?.includes(item.address)||
                    //   selectedStatus?.includes(item.leadStatus) ||
                    //   selectedExpectedClosure?.includes(item.clouserMonth)||
                    // selectedBudget?.includes(item.budget)
                    // ||
                    //   selectedAssignedTo?.includes(item.assignedTo?.fullName)
                    selectedScope?.includes(item?.requirements)||
                    selectedLeadSource?.includes(item?.leadSource)
                );
            });
            let x = JSON.parse(JSON.stringify(leads));
            delete x["data"];
            x["data"] = filterData;
            setLeads(x);
        } else {
            apiFetch(tab);
        }

    }, [selectedScope, selectedLeadSource])
    

    return (
        <tr className={`bg-grey1 tableHeader`} style={{ outline: "thick solid white", borderSpacing: "0", display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <th scope="col" className="  cl-grey1 ws-nowrap "
            style={{width:"15%"}}
            >
                Client Details
            </th>
            <th scope="col" className="  cl-grey1 ws-nowrap align-items-center ps-0 d-flex"  style={{width:"8%"}}>
                Budget
            </th>
            <th scope="col" className="  cl-grey1 ws-nowrap ps-0 align-items-center d-flex"  style={{width:"12.5%"}} >
                Scope</th>
            <th scope="col" className="  cl-grey1 ws-nowrap ps-0"  style={{width:"15.5%"}}>
                Lead Status
            </th>
            <th scope="col" className="  cl-grey1 ws-nowrap ps-0" style={{width:"14%"}}>
                Expected Closure
            </th>
            <th scope="col" className="  cl-grey1 ws-nowrap ps-0" style={{width:"12.5%"}}>
                Last Call
            </th>
            <th scope="col" className="  cl-grey1 ws-nowrap ps-0"style={{width:"12.5%"}} >
                Follow Up
            </th>
            <th scope="col" className="  cl-grey1 ws-nowrap ps-0 align-items-center d-flex" style={{zIndex:'10',width:"12.5%"}} >
               Source
            </th>
        </tr>
    )
}

export default LmsTableTh
