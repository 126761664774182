import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getReq } from "../../../piservices/apis";
import { apiLinks } from "../../../piservices/constants";
import { formateDate } from "../../../piservices/compCommon";
import styles from "./InteractionAndLogs.module.css";
import { BsEye } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner'
// import { Modal } from 'react-bootstrap';
// import PDFViewer from './components/PDFViewer';
// import { saveAs } from 'file-saver';
// import { axios } from 'axios';

function LogComponent({ logDate, logs }) {
  const handleDownloadPdf = async (pdfLink) => {
    console.log("downloading PDF", pdfLink);
    try {
      const response = await fetch(pdfLink);
      const blob = await response.blob();
      // Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "downloaded.pdf";
      link.click();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.leftContainer}`}>{logDate && logDate}</div>
        <div className={`${styles.rightContainer}`}>
          {logs[logDate].map((it, index) => (
            <>
              <div
                className={styles.subRightContainer}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderLeft: "0.75px solid #999",
                  paddingLeft: "20px",
                }}
              >
                <div>{it && it.logName}</div>
                <div>{it.assignTo && `Assign to :${it.assignTo} `}</div>
                {/* <div>Status: {it.status}</div> */}
                {it.pdfLink && (
                  <div
                    onClick={() => handleDownloadPdf(it.pdfLink)}
                    style={{ color: "#0084EF", cursor: "pointer" }}
                  >
                    <BsEye /> <span>{`View PDF`}</span>
                  </div>
                )}
              </div>
              <hr />
            </>
          ))}
        </div>
      </div>
    </>
  );
}

function Logs({data}) {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const selectedLeadData = useSelector(
    (state) => state.selectedLeadSlice.selectedLeadData
  );
  const [logs, setlogs] = useState([]);

  const getlogData = async () => {
    setLoading(true);
    const res = await getReq(`${apiLinks.crm}/user/get-lead-logs?leadId=${id}`);
    if (res && !res.error) {
      console.log("logresponse", res);
      const groupByCreatedDate = res?.data?.data?.reduce((group, product) => {
        const { createdAt } = product;
        const formatedDate = formateDate(createdAt);
        group[formatedDate] = group[formatedDate] ?? [];
        group[formatedDate].push(product);
        return group;
      }, {});
      setlogs(groupByCreatedDate);
    }
    else {
      console.log(res.error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getlogData();
  }, [selectedLeadData]);
  return (
    <>
    <div className={`${styles.mainContainer}`}>
        {logs && Object.keys(logs).length > 0 ? (
          Object.keys(logs).map((logDate, index) => (
            <LogComponent logs={logs} key={index} logDate={logDate} />
          ))
        ) : (
          <h4>There is no logs</h4>
        )}
      </div>
    </>
  );
}

export default Logs;